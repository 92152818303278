import { gql } from '@apollo/client';

export const GetWallets = gql`
  query GetWalletsForWalletList {
    localWallets {
      id
      imageUrl
      calculatedValue
      value
      balance
      calculatedBalance
      computedTransactionCount
      computedCryptoTransactionCount
      currency {
        symbol
        type
        cmcId
        price
      }
      address
      name
      isBalanceDifferenceSignificant
      isPortfolioValueDifferenceSignificant
      allowsHardResync
      syncInfo {
        lastSynced
        isSyncing
        willBeDeleted
      }
      isMining
      hasDefiActivity
      errors
      assetsCount
      walletNetwork
    }
    exchanges {
      id
      value
      calculatedValue
      imageUrl
      name
      defaultName
      type
      allowsTradeImports
      allowsTransferImports
      allowsTransactionImports
      allowsTransactionHistoryImports
      syncInfo {
        lastSynced
        isSyncing
        willBeDeleted
      }
      isPortfolioValueDifferenceSignificant
      allowsHardResync
      errors
      warnings
      showDeprecationWarning
      shouldBeRemoved
      assetsCount
    }
    customWallets {
      id
      name
      value
      computedTransactionCount
      syncInfo {
        isSyncing
        willBeDeleted
        lastSynced @client
      }
      assetsCount
    }
    otherTransactions {
      id
      value
      assetsCount
    }
  }
`;
