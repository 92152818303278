export const encodeState = (state: string) => btoa(state);
export const decodeState = (state: string) => atob(state);

export const getCrypto = () => {
  //ie 11.x uses msCrypto
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Crypto>(window.crypto || (<any>window).msCrypto);
};

export const createRandomString = () => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
  let random = '';
  const randomValues = Array.from(
    getCrypto().getRandomValues(new Uint8Array(43)),
  );
  randomValues.forEach((v) => (random += charset[v % charset.length]));
  return random;
};
