import { GetWalletsForWalletListQuery } from 'src/types/graphql-types';

export function getWalletInstructions(slug: string, supportsHdWallet: boolean) {
  if (slug === 'ethereum') {
    return {
      description: 'Public address or ENS domain',
      placeholder: 'Paste a public address or ENS domain...',
    };
  } else if (slug === 'cardano') {
    return {
      description: 'Public address or stake key',
      placeholder: 'Paste a public address or stake key...',
    };
  } else if (supportsHdWallet) {
    return {
      description: 'Public address or xPub/yPub/zPub key',
      placeholder: 'Paste a public address or xPub/yPub/zPub key...',
    };
  } else {
    return {
      description: 'Public address',
      placeholder: 'Paste a public address...',
    };
  }
}

export const emptyCacheQuery: GetWalletsForWalletListQuery = {
  localWallets: [],
  exchanges: [],
  customWallets: [],
  otherTransactions: undefined,
};

export const formatAPISecretLabel = (integrationSlug: string) => {
  if (integrationSlug === 'kraken') {
    return 'Private Key';
  } else {
    return 'API Secret';
  }
};
