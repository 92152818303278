const FIELD_REQUIRED_HINT = 'This field is required';

export type ValidationErrors = {
  publicKeys?: string;
};

export const validatePublicKeys = (publicKeys: string) => {
  if (!publicKeys || !publicKeys.trim()) {
    return FIELD_REQUIRED_HINT;
  }
  return undefined;
};
