import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import Banner from 'src/deprecated-components/Banner/Banner';
import { ModalError } from 'src/deprecated-components/Modal/styles';
import { ErrorMessage } from '../PublicAddressForm/ErrorMessage';
import {
  FormContent,
  InstructionContainer,
  Spacer,
  SubmitButton,
} from '../styles';

import { useApolloClient } from '@apollo/client';
import { isEmpty } from 'lodash';
import { isChromaticEnv } from 'src/app/utils';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import { FormGroup, Label } from 'src/deprecated-components/Input/styles';
import * as Tabs from 'src/deprecated-components/Tabs';
import Textarea from 'src/deprecated-components/Textarea';
import {
  Status,
  ToastContext,
} from 'src/deprecated-components/ToastNotification';
import { GetWallets } from 'src/pages/wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetMultiAddressJobStatusQuery,
  GetWalletsForWalletListQuery,
  Integration,
} from 'src/types/graphql-types';
import { AddWalletContext } from '../../context';
import { ViewPermissions } from '../FormBaseComponents/ViewPermissions';
import { Instructions } from '../Instructions';
import { WalletConnect, initWalletConnect } from '../WalletConnectForm';
import { emptyCacheQuery } from '../util';
import useCreateMultiAddressWallet from './hooks/useCreateMultiAddressWallet';
import { ValidationErrors, validatePublicKeys } from './validate';

interface MultiAddressWalletFormProps {
  integration: Integration;
  onCloseModal?: () => void;
  defaultTab?: TABS;
}

export enum TABS {
  WALLET_CONNECT = 'WalletConnect',
  PUBLIC_KEYS = 'PublicKeys',
}

export const MultiAddressWalletForm = (props: MultiAddressWalletFormProps) => {
  const integration = props.integration;
  const {
    metadata: { publicKeys: defaultPublicKeys },

    onSuccess,
  } = useContext(AddWalletContext);

  const [publicKeys, setPublicKeys] = useState<string>(defaultPublicKeys);
  let { defaultTab } = props;
  if (!defaultTab) {
    defaultTab = isEmpty(defaultPublicKeys)
      ? TABS.WALLET_CONNECT
      : TABS.PUBLIC_KEYS;
  }

  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    AddWalletErrors | string
  >();
  const [errors, setErrors] = useState<ValidationErrors>();
  const description = integration.supportsHdWallet
    ? 'Public addresses & xPubs'
    : 'Public addresses';
  const placeholder = integration.supportsHdWallet
    ? 'Paste your public addresses and xPubs here...'
    : 'Paste your public addresses here...';
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showNotification } = useContext(ToastContext);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);

  const onPublicKeysChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setPublicKeys(e.target.value);
      setErrors({ ...errors, publicKeys: validatePublicKeys(e.target.value) });
    },
    [errors],
  );

  const client = useApolloClient();
  const { createMultiAddressWallet } = useCreateMultiAddressWallet({
    onCompleted: (data: GetMultiAddressJobStatusQuery) => {
      setIsSubmitting(false);
      const { successMessage, warningMessage, errorMessage } =
        data.multiAddressImportJobStatus;

      if (successMessage) {
        showNotification({
          status: Status.Success,
          message: successMessage,
        });
      }
      if (warningMessage) {
        showNotification({
          status: Status.Info,
          message: warningMessage,
        });
      }
      if (errorMessage) {
        showNotification({
          status: Status.Error,
          message: errorMessage,
        });
      }

      if (data.multiAddressImportJobStatus.createdWallets) {
        const walletsData: GetWalletsForWalletListQuery =
          client.cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
        const newLocalWallets = [
          ...walletsData.localWallets,
          data.multiAddressImportJobStatus.createdWallets,
        ];

        client.cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, localWallets: newLocalWallets },
        });
      }

      onSuccess(data.multiAddressImportJobStatus.createdWallets?.[0]);
      props.onCloseModal?.();
    },
    onError: (error: string) => {
      setIsSubmitting(false);
      setSubmitErrorMessage(error);
    },
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const publicKeyErrors = validatePublicKeys(publicKeys);
    if (publicKeyErrors) {
      setErrors({ ...errors, publicKeys: publicKeyErrors });
      return;
    }

    try {
      await createMultiAddressWallet({
        publicKeys,
        accountTypeSlug: integration.slug,
      });
      startFasterPollingForSyncCompletion();
    } catch (error) {
      setIsSubmitting(false);
      setSubmitErrorMessage(error);
    }
  };

  const isFormInvalid = errors && errors.publicKeys !== undefined;

  return (
    <Tabs.Root defaultValue={defaultTab}>
      <Tabs.List>
        <Tabs.Trigger asChild value={TABS.WALLET_CONNECT}>
          <span>Single Address</span>
        </Tabs.Trigger>
        <Tabs.Trigger asChild value={TABS.PUBLIC_KEYS}>
          <span>Multiple Addresses</span>
        </Tabs.Trigger>
      </Tabs.List>
      <Spacer $height={10} />
      <Tabs.Content value={TABS.WALLET_CONNECT}>
        {!isChromaticEnv() && (
          <WalletConnect
            integration={integration}
            onCloseModal={props.onCloseModal}
            wagmiConfig={initWalletConnect()}
          />
        )}
      </Tabs.Content>
      <Tabs.Content value={TABS.PUBLIC_KEYS}>
        <>
          <FormContent>
            <form onSubmit={onSubmit}>
              {submitErrorMessage && (
                <ModalError>
                  <Banner
                    type="error"
                    onBannerClose={() => setSubmitErrorMessage(null)}
                  >
                    <ErrorMessage error={submitErrorMessage} />
                  </Banner>
                </ModalError>
              )}

              <FormGroup>
                <Label>{description}</Label>
                <Textarea
                  name="public_keys"
                  placeholder={placeholder}
                  value={publicKeys}
                  onChange={onPublicKeysChange}
                  hasError={errors?.publicKeys?.length > 0}
                  hint={
                    errors?.publicKeys?.length > 0
                      ? errors.publicKeys
                      : undefined
                  }
                  autoFocus={true}
                />
              </FormGroup>

              <SubmitButton
                disabled={isFormInvalid || isSubmitting}
                type="submit"
              >
                {isSubmitting ? 'Please wait...' : `Add ${integration.name}`}
              </SubmitButton>
            </form>
          </FormContent>
          <ViewPermissions />
          <InstructionContainer $marginTop={8}>
            <Instructions integration={integration} />
          </InstructionContainer>
        </>
      </Tabs.Content>
    </Tabs.Root>
  );
};
