const breakpointSizes = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1440,
};

export default {
  outerMargin: 16,
  gutter: '16px',
  gridSize: 12,
  breakpointSizes,
  breakpoints: {
    xs: `${breakpointSizes.xs}px`,
    sm: `${breakpointSizes.sm}px`,
    md: `${breakpointSizes.md}px`,
    lg: `${breakpointSizes.lg}px`,
    xl: `${breakpointSizes.xl}px`,
  },
};
