import { Flex, Heading, Text } from '@radix-ui/themes';
import { Info } from 'lucide-react';
import {
  IntegrationCategories,
  IntegrationSyncMethod,
} from 'src/common/Integration';
import { Integration } from 'src/types/graphql-types';
import { DefaultGenericInstructions } from './DefaultGenericInstructions';
import { GenericCSVInstructions } from './GenericCSVInstructions';
import { InstructionSidebar } from './styles';
import { parseInstructions } from './util';

interface InstructionProps {
  integration: Integration;
  genericInstructions?: JSX.Element;
  showCSVInstructions?: boolean;
  isOnEditScreen?: boolean;
}

const getInstructionsHeader = (
  integration: Integration,
  isOnEditScreen = false,
) => {
  let integrationName = integration.name;
  if (integrationName.includes('Wallet')) {
    integrationName = integrationName.replace(' Wallet', '');
  }
  if (isOnEditScreen) {
    return `How to edit your ${integrationName} ${
      integration.category === IntegrationCategories.exchange
        ? 'account'
        : 'wallet'
    }`;
  }
  return `How to add your ${integrationName} ${
    integration.category === IntegrationCategories.exchange
      ? 'account'
      : 'wallet'
  }`;
};

export const Instructions = ({
  integration,
  genericInstructions,
  showCSVInstructions,
  isOnEditScreen,
}: InstructionProps) => {
  const { apiInstructions, fileInstructions } = parseInstructions(
    integration.instructions,
    integration.syncMethod as IntegrationSyncMethod,
  );

  if (showCSVInstructions) {
    if (fileInstructions) {
      return (
        <InstructionSidebar>
          <Flex direction="row" gap="4" align="center">
            <Info size="16px" color="var(--accent-11)" />
            <Heading size="3">
              Steps to add your {integration.name} transactions:
            </Heading>
          </Flex>

          <Text
            as="div"
            color="gray"
            size="2"
            dangerouslySetInnerHTML={{ __html: fileInstructions }}
          />
        </InstructionSidebar>
      );
    }
    return (
      <InstructionSidebar>
        <GenericCSVInstructions integration={integration} />
      </InstructionSidebar>
    );
  }
  return (
    <InstructionSidebar>
      {integration.instructions ? (
        <>
          <Flex direction="row" gap="4" align="center">
            <Info size="16px" color="var(--accent-11)" />
            <Heading size="3">
              {getInstructionsHeader(integration, isOnEditScreen)}
            </Heading>
          </Flex>
          <Text
            as="div"
            color="gray"
            size="2"
            dangerouslySetInnerHTML={{ __html: apiInstructions }}
          />
        </>
      ) : (
        genericInstructions || (
          <DefaultGenericInstructions integration={integration} />
        )
      )}
    </InstructionSidebar>
  );
};
