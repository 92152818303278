import { Theme } from '@radix-ui/themes';
import { ReactNode, useContext, useMemo } from 'react';
import { darkTheme, lightTheme } from 'src/app/theme';
import { UserContext } from 'src/app/user/context';
import { useThemeDetector } from 'src/hooks/useThemeDetector';
import { UiTheme } from 'src/types/graphql-types';
import { ThemeProvider } from 'styled-components';
import { parseCookie } from '../utils';
import { GlobalStyles } from './styles';

const uiThemeCookie = 'ui_theme';
/**
 * To allow us to know as quickly as possible the users' theme preference
 * we use a cookie
 *
 * More discussion here on why we added this:
 * https://github.com/coin-tracker/coin-tracker-server/pull/11815
 */
export const getUserThemePreferenceFromCookie = () => {
  const cookie = parseCookie(document.cookie);
  return cookie?.[uiThemeCookie];
};

export default function AppThemeProvider({
  children,
}: {
  children: ReactNode;
}) {
  const userPrefersDarkTheme = useThemeDetector();
  const user = useContext(UserContext);
  const userThemePreference = getUserThemePreferenceFromCookie();

  const theme = useMemo(() => {
    let selectedTheme = UiTheme.Light.valueOf();
    if (!user.hasFetched) {
      selectedTheme = userThemePreference;
    } else {
      selectedTheme = user.selectedTheme;
    }

    if (selectedTheme === UiTheme.Light) {
      return lightTheme;
    }
    if (selectedTheme === UiTheme.Dark) {
      return darkTheme;
    }
    if (selectedTheme === UiTheme.System) {
      if (userPrefersDarkTheme) {
        return darkTheme;
      }
      return lightTheme;
    }
    return lightTheme;
  }, [
    user.selectedTheme,
    user.hasFetched,
    userPrefersDarkTheme,
    userThemePreference,
  ]);

  return (
    <>
      <GlobalStyles theme={theme} />
      <Theme appearance="dark">
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </Theme>
    </>
  );
}
