import { InputLabel } from 'src/deprecated-components/typography/input-texts';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Label = styled(InputLabel)`
  margin-bottom: 8px;
  display: inline-flex;
`;

export const AutocompleteSuffix = styled(InputLabel).attrs<{
  $ignoreForAutocompleteLabel?: boolean;
}>({
  $ignoreForAutocompleteLabel: true,
})<{
  $ignoreForAutcompleteLabel?: boolean;
}>`
  color: ${({ theme }) => theme.text.tertiary};
`;

export const AutocompletePrefix = styled.div`
  padding-left: 10px;
  display: inline-block;
`;
export const AutocompleteInput = styled.input<{ $withPrefix?: boolean }>`
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  width: 100%;
  color: ${({ theme }) => theme.text.primary};

  padding-top: 15px;
  padding-left: ${({ $withPrefix }) => ($withPrefix ? '0' : '20px')};
  padding-right: 20px;
  padding-bottom: 15px;

  &::placeholder {
    color: ${({ theme }) => theme.text.tertiary};
  }
`;

export const AutocompleteIcon = styled.div`
  padding: 14px 20px 14px 10px;
  line-height: 1;

  svg {
    transition: transform 0.5s;
    > path {
      fill: ${({ theme }) => theme.grey.dark};
    }
  }

  &[aria-expanded='true'] {
    svg {
      transform: rotate(180deg);
      & > path {
        fill: ${({ theme }) => theme.primary.solid};
      }
    }
  }
`;

export const InputContainer = styled.div<{
  disabled?: boolean;
  $hasError?: boolean;
}>`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.grey.mid};
  border-radius: 5px;
  background: ${({ theme }) => theme.grey.white};
  position: relative;
  z-index: 99;
  &:hover,
  &:active,
  &:focus-within {
    border: 1px solid ${({ theme }) => theme.primary.solid};

    ${AutocompleteIcon} svg > path {
      fill: ${({ theme }) => theme.primary.solid};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.grey.light};

      &:hover,
      &:active,
      &:focus-within {
        border: 1px solid ${({ theme }) => theme.grey.mid};
      }

      ${AutocompleteInput},
      ${AutocompleteIcon} {
        color: ${({ theme }) => theme.text.tertiary};
        cursor: not-allowed;
      }

      ${AutocompleteIcon} svg > path {
        fill: ${({ theme }) => theme.text.tertiary} !important;
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border: 1px solid ${({ theme }) => theme.red.solid};
      &,
      &:hover,
      &:active,
      &:focus-within {
        border: 1px solid ${({ theme }) => theme.red.solid};
      }
    `}
`;

export const DropdownList = styled.ul<{ $inputWidth?: number }>`
  list-style: none;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.grey.white};
  display: none;
  z-index: 999;
  min-width: ${({ $inputWidth }) =>
    $inputWidth ? `${$inputWidth}px` : '100%'};
  position: fixed;

  max-height: 300px;
  overflow-y: auto;

  &[data-open='true'] {
    display: block;
    box-shadow: ${({ theme }) => theme.shadow.level2};
  }
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const DropdownItem = styled.li`
  padding: 16px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.text.primary};

  &:not(:last-child):after {
    content: '';
    background: ${({ theme }) => theme.grey.mid};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100% - 32px);
    margin: 0 auto;
    height: 1px;
  }

  &:hover {
    color: ${({ theme }) => theme.primary.solid};
    background-color: ${({ theme }) => theme.grey.light};
  }

  ${InputLabel} {
    white-space: nowrap;
  }
`;
