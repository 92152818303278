import { isEmpty } from 'lodash';

export type ValidationErrors = {
  apiKeyMissing?: boolean;
};

interface validateProps {
  apiKey: string;
}
export const validate = ({ apiKey }: validateProps) => {
  const errors: ValidationErrors = {};
  if (!apiKey.trim()) {
    errors.apiKeyMissing = true;
  }
  if (isEmpty(errors)) {
    return undefined;
  }
  return errors;
};
