import * as RadixTabs from '@radix-ui/react-tabs';
import styled from 'styled-components';

export const StyledList = styled(RadixTabs.List)`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.grey.mid};
`;

export const StyledListItem = styled(RadixTabs.Trigger)`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-right: 24px;
  color: ${({ theme }) => theme.text.secondary};
  position: relative;
  outline: none;
  background: transparent;
  padding: 0 0 16px 0;
  border: none;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.primary.solid};
  }
  &[data-state='active'] {
    color: ${({ theme }) => theme.text.primary};
  }
  &[data-state='active']:before {
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.primary.solid};
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    content: '';
  }
`;
