import { isEmpty } from 'lodash';
import { Integration } from 'src/types/graphql-types';
const FIELD_REQUIRED_HINT = 'This field is required';
const XPUB_LENGTH_HINT = 'Field must be between 30 and 120 characters long.';
const SAME_RECEIVE_CHANGE_ADDRESS_HINT =
  'Receive and Change address cannot be the same.';
export type ValidationErrors = {
  receiveAddressXpub?: string;
  changeAddressXpub?: string;
  cryptoSelect?: string;
};

export const validateXpub = (receiveXpub: string, changeXpub: string) => {
  if (!receiveXpub || !receiveXpub.trim()) {
    return FIELD_REQUIRED_HINT;
  }

  const minLength = 30;
  const maxLength = 120;
  if (receiveXpub.length < minLength || receiveXpub.length > maxLength) {
    return XPUB_LENGTH_HINT;
  }

  if (changeXpub === receiveXpub) {
    return SAME_RECEIVE_CHANGE_ADDRESS_HINT;
  }

  return undefined;
};

export const validateCryptoSelector = (cryptoSelect: Integration) => {
  if (!cryptoSelect) {
    return FIELD_REQUIRED_HINT;
  }
  return undefined;
};

export const validate = ({
  receiveAddressXpub,
  changeAddressXpub,
  selectedCrypto,
}: {
  receiveAddressXpub: string;
  changeAddressXpub: string;
  selectedCrypto: Integration;
}) => {
  const errors: ValidationErrors = {};
  const receiveXpubError = validateXpub(receiveAddressXpub, changeAddressXpub);
  if (receiveXpubError) {
    errors.receiveAddressXpub = receiveXpubError;
  }
  const changeXpubError = validateXpub(receiveAddressXpub, changeAddressXpub);
  if (changeXpubError) {
    errors.changeAddressXpub = changeXpubError;
  }
  const cryptoSelectError = validateCryptoSelector(selectedCrypto);
  if (cryptoSelectError) {
    errors.cryptoSelect = cryptoSelectError;
  }
  if (isEmpty(errors)) {
    return undefined;
  }
  return errors;
};
