import { Integration } from 'src/types/graphql-types';
import { create } from 'zustand';

interface SuggestedWalletsModalStore {
  address: string;
  integration: Integration;
  isModalOpen: boolean;
  setAddress: (address: string, integration: Integration) => void;
  resetAddress: () => void;
  openModal: (address: string, integration: Integration) => void;
  closeModal: () => void;
}

export const useSuggestedWalletsModalStore = create<SuggestedWalletsModalStore>(
  (set) => ({
    address: '',
    integration: null,
    isModalOpen: false,
    setAddress: (address: string, integration: Integration) =>
      set(() => ({ address, integration })),
    resetAddress: () => set({ address: '', integration: null }),
    openModal: (address: string, integration: Integration) =>
      set({ isModalOpen: true, address, integration }),
    closeModal: () =>
      set({ isModalOpen: false, address: '', integration: null }),
  }),
);
