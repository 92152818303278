export enum NavigationType {
  EXCHANGE = '1',
  WALLET = '2',
  CUSTOM_WALLET = '3',
  OTHER_TRANSACTION = '4',
}

export enum WalletsListSortBy {
  marketValueHiLo = 'marketvalue-hi-lo',
  marketValueLoHi = 'marketvalue-lo-hi',
}
