import { autoUpdate, ReferenceType, useFloating } from '@floating-ui/react-dom';
import { useCombobox } from 'downshift';
import {
  isValidElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Avatar from 'src/deprecated-components/Avatar';
import { ArrowHeadDownIcon } from 'src/deprecated-components/icons/small-icons';
import { IStyledComponent } from 'styled-components';

import {
  AutocompleteIcon,
  AutocompleteInput,
  AutocompletePrefix,
  AutocompleteSuffix,
  Container,
  DropdownItem,
  DropdownList,
  InputContainer,
  Label,
} from './styles';

export type AutocompleteItem = {
  label: string | ReactNode;
  value: string;
  avatar?: {
    icon: string;
    name: string;
    fallbackText?: string;
  };
};

const isStyledComponent = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: any,
): target is { type: IStyledComponent<'web', typeof AutocompleteSuffix> } => {
  return (
    (target?.type as IStyledComponent<'web', typeof AutocompleteSuffix>)
      ?.styledComponentId !== undefined
  );
};

export function getNodeText(
  node:
    | ReactNode
    | ReactNode[]
    | IStyledComponent<'web', typeof AutocompleteSuffix>
    | number
    | string,
): string {
  if (
    isStyledComponent(node) &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    node?.type?.attrs[0]?.$ignoreForAutocompleteLabel
  ) {
    return '';
  }
  if (['string', 'number'].includes(typeof node)) return node.toString().trim();
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && isValidElement(node))
    return getNodeText(node.props.children);
}

function getItemFilter(inputValue: string) {
  const lowerCasedInputValue = inputValue.toLowerCase();

  return function (item: AutocompleteItem) {
    return (
      !inputValue ||
      item.value.toLowerCase().includes(lowerCasedInputValue) ||
      getNodeText(item.label).toLowerCase().includes(lowerCasedInputValue)
    );
  };
}

interface AutocompleteProps {
  items: AutocompleteItem[];
  defaultOpen?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onSelect?: (item: AutocompleteItem) => void;
  filterFunction?: (
    items: AutocompleteItem[],
    inputValue: string,
  ) => AutocompleteItem[];
  defaultValue?: string;
  loading?: boolean;
  label?: string;
  renderItem?: (item: AutocompleteItem) => ReactNode;
  prefix?: ReactNode;
  placeholder?: string;
  hasError?: boolean;
}

export function getFilteredItems(items: AutocompleteItem[], inputValue = '') {
  return items.filter(getItemFilter(inputValue));
}

// Replace with Radix equivalent when it's ready since downshift has it's issues
// https://github.com/radix-ui/primitives/issues/1342
export default function Autocomplete({
  items: defaultItems,
  defaultOpen,
  onChange = () => {},
  onSelect = () => {},
  disabled,
  defaultValue,
  filterFunction = getFilteredItems,
  loading = false,
  label = '',
  renderItem,
  prefix,
  placeholder = 'Search text',
  hasError = false,
}: AutocompleteProps) {
  const [items, setItems] = useState(
    filterFunction(defaultItems, defaultValue),
  );

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getLabelProps,
    getInputProps,
    getItemProps,
    inputValue,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      onChange(inputValue);
      setItems(filterFunction(defaultItems, inputValue));
      if (!inputValue) {
        onSelect(null);
      }
    },
    items,
    itemToString(item: AutocompleteItem) {
      return item ? getNodeText(item.label) : '';
    },
    initialIsOpen: defaultOpen,
    onStateChange: (item) => {
      if (item?.selectedItem) {
        onSelect(item?.selectedItem);
      }
    },
    initialInputValue: defaultValue,
  });

  const { refs, elements, floatingStyles } = useFloating({
    open: isOpen,
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  });
  useEffect(() => {
    setItems(filterFunction(defaultItems, inputValue));
  }, [defaultItems, filterFunction, setItems, inputValue]);

  const onFocus = useCallback(
    (e) => {
      e.target.select();
      getInputProps().onFocus(e);
    },
    [getInputProps],
  );

  return (
    <Container>
      {label && (
        <Label as="label" {...getLabelProps()}>
          {label}
        </Label>
      )}
      <InputContainer
        disabled={disabled}
        $hasError={hasError}
        ref={refs.setReference}
      >
        {prefix ? <AutocompletePrefix>{prefix}</AutocompletePrefix> : null}
        <AutocompleteInput
          placeholder={placeholder}
          {...getInputProps()}
          disabled={disabled}
          $withPrefix={!!prefix}
          onFocus={onFocus}
        />
        <AutocompleteIcon {...getToggleButtonProps()}>
          <ArrowHeadDownIcon />
        </AutocompleteIcon>
      </InputContainer>
      <DropdownList
        data-open={isOpen}
        {...getMenuProps()}
        $inputWidth={getInputWidth(elements?.reference)}
        ref={(element) => {
          if (!element) {
            return;
          }
          getMenuProps().ref(element);
          refs.setFloating(element);
        }}
        style={floatingStyles}
      >
        {isOpen && loading && <DropdownItem>Loading...</DropdownItem>}
        {isOpen &&
          !loading &&
          items.map((item, index) => (
            <DropdownItem key={index} {...getItemProps({ item, index })}>
              {renderItem && renderItem(item)}
              {!renderItem && (
                <>
                  {item.avatar && (
                    <Avatar
                      src={item.avatar.icon}
                      alt={`Icon of ${item.avatar.name}`}
                      fallbackText={item.avatar.fallbackText ?? item.value}
                      delayMs={1000}
                    />
                  )}
                  {item.label}
                </>
              )}
            </DropdownItem>
          ))}
      </DropdownList>
    </Container>
  );
}

function getInputWidth(element: ReferenceType): number {
  if (!element) {
    return;
  }
  const targetRect = element.getBoundingClientRect();
  return targetRect.width;
}
