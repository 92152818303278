import { isEmpty } from 'lodash';

export type ValidationErrors = {
  apiKeyMissing?: boolean;
  apiSecretMissing?: boolean;
};

interface validateProps {
  apiKey: string;
  apiSecret: string;
}

export const isStringValid = (value: string) => !!value.trim();

export const isKeyContentValid = (keyContent) => {
  const pemHeader = '-----BEGIN PRIVATE KEY-----';
  const pemFooter = '-----END PRIVATE KEY-----';

  if (typeof keyContent !== 'string') {
    return false;
  }

  const trimmedContent = keyContent.trim();

  if (
    !trimmedContent.startsWith(pemHeader) ||
    !trimmedContent.endsWith(pemFooter)
  ) {
    return false;
  }

  const base64Content = trimmedContent
    .substring(pemHeader.length, trimmedContent.length - pemFooter.length)
    .replace(/\s/g, '');

  const base64Pattern = /^[A-Za-z0-9+/=]+$/;
  return base64Pattern.test(base64Content);
};

export const validate = ({ apiKey, apiSecret }: validateProps) => {
  const errors: ValidationErrors = {};
  if (!isStringValid(apiKey)) {
    errors.apiKeyMissing = true;
  }

  if (!isKeyContentValid(apiSecret)) {
    errors.apiSecretMissing = true;
  }

  if (isEmpty(errors)) {
    return undefined;
  }
  return errors;
};
