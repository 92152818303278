import { NetworkStatus } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { isEmpty } from 'lodash';
import { UiTheme, useGetUserQuery } from 'src/types/graphql-types';
import { User } from './context';

interface Props {
  skip: boolean;
}
export const useGetUser = ({ skip }: Props = { skip: false }) => {
  const { loading, data, networkStatus } = useGetUserQuery({
    skip,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!isEmpty(data.userInfo)) {
        Sentry.setUser({ id: data.userInfo.publicId });
      }
    },
  });

  const { userInfo, userProfile, session, myProfile } = data || {};
  const user: User = {
    ...myProfile,
    ...userInfo,
    ...userProfile,
    isTaxProViewingClient: false,
    session,
    isAuthenticated: !isEmpty(userInfo),
    hasFetched: !isEmpty(data),
    isFetching: loading,
    selectedTheme: userInfo?.selectedTheme || UiTheme.Light,
  };

  // we only want the page to show global loaders on initial fetch. For the refetches we just use the "isFetching" prop on the user
  return { loading: networkStatus === NetworkStatus.loading, user };
};

export default useGetUser;
