import { Grid } from '@radix-ui/themes';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Integration } from 'src/types/graphql-types';
import { AddWalletCard } from '../AddWalletCard';
import AddWalletLoadingCard from './AddWalletLoadingCard';
import { BlankSearchResults } from './BlankSearchResults';

interface SearchResultsProps {
  integrations: Integration[];
  loading: boolean;
  setSelectedIntegration: React.Dispatch<React.SetStateAction<Integration>>;
  searchQuery: string;
}
export const SearchResults = ({
  integrations,
  loading,
  setSelectedIntegration,
  searchQuery,
}: SearchResultsProps) => {
  if (!loading && integrations.length === 0) {
    return (
      <BlankSearchResults setSelectedIntegration={setSelectedIntegration} />
    );
  }
  if (loading && integrations.length === 0) {
    return (
      <Grid columns="3" gap="3" width="auto">
        {...Array.from({ length: 50 }).map((_, index) => (
          <AddWalletLoadingCard key={index} />
        ))}
      </Grid>
    );
  }
  return (
    <Grid columns="3" gap="3" width="auto">
      {integrations.map((integration) => {
        return (
          <LazyLoadComponent
            key={`${integration.slug}_${integration.category}`}
            placeholder={<AddWalletLoadingCard />}
          >
            <AddWalletCard
              key={`${integration.slug}_${integration.category}_wallet_card`}
              integration={integration}
              setSelectedIntegration={setSelectedIntegration}
              searchQuery={searchQuery}
            />
          </LazyLoadComponent>
        );
      })}
    </Grid>
  );
};
