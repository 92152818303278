import { Card, Flex, Skeleton } from '@radix-ui/themes';

export default function AddWalletLoadingCard() {
  return (
    <Card>
      <Flex direction="row" gap="2" align="center">
        <Skeleton width="36px" height="36px" style={{ borderRadius: '50%' }} />
        <Skeleton width="100px" height="10px" />
      </Flex>
    </Card>
  );
}
