import { isEmptyString } from '@/lib/utils';
import { IconButton, TextField } from '@radix-ui/themes';
import { Search, X } from 'lucide-react';

type SearchBarProps = {
  onClear?: () => void;
} & TextField.RootProps;

export const SearchBar = ({ onClear, ...textFieldProps }: SearchBarProps) => {
  return (
    <TextField.Root {...textFieldProps}>
      <TextField.Slot>
        <Search size="16px" />
      </TextField.Slot>
      {isEmptyString(textFieldProps.value) ? null : (
        <TextField.Slot>
          <IconButton onClick={onClear} variant="ghost">
            <X size="16px" />
          </IconButton>
        </TextField.Slot>
      )}
    </TextField.Root>
  );
};
