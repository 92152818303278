import { InputLabel } from 'src/deprecated-components/typography/input-texts';
import { LinkSmall } from 'src/deprecated-components/typography/link-texts';
import styled, { css } from 'styled-components';

export const StyledTextarea = styled.textarea<{ $hasError?: boolean }>`
  border: 1px solid ${({ theme }) => theme.grey.mid};
  border-radius: 5px;
  background: ${({ theme }) => theme.grey.white};
  position: relative;
  z-index: 99;

  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  width: 100%;

  padding: 16px 20px;

  &:hover,
  &:active,
  &:focus-within {
    border: 1px solid ${({ theme }) => theme.primary.solid};
  }

  &::placeholder {
    color: ${({ theme }) => theme.text.tertiary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.grey.light};
      cursor: not-allowed;
      color: ${({ theme }) => theme.text.tertiary};

      &:hover,
      &:active,
      &:focus-within {
        border: 1px solid ${({ theme }) => theme.grey.mid};
      }
    `}
  ${({ $hasError = false }) =>
    $hasError &&
    css`
      &,
      &:hover,
      &:active,
      &:focus-within {
        border: 1px solid ${({ theme }) => theme.red.solid};
      }
    `}
`;

export const ErrorMessage = styled(LinkSmall)`
  margin-top: 8px;
  margin-bottom: 0;
  cursor: default;
  color: ${({ theme }) => theme.red.solid};
`;

export const Label = styled(InputLabel)`
  margin-bottom: 8px;
  display: block;
`;
