import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Flex, Heading, Spinner } from '@radix-ui/themes';
import { ComponentType, Suspense, lazy, useEffect, useState } from 'react';

const RouteLoading = () => {
  // Only show loader if content is taking more than 1s to load
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Flex
      direction="column"
      gap="4"
      data-testid="route-loading-container"
      align="center"
      justify="center"
      my="6"
    >
      {showLoading && (
        <>
          <Spinner size="3" />
          <Heading size="3">
            We are loading the page. Give us a second...
          </Heading>
        </>
      )}
    </Flex>
  );
};

interface RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  page: () => Promise<{ default: ComponentType<any> }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

export const PublicPage = (props: RouteProps) => {
  return <Lazy {...props} />;
};

export const Page = (props: RouteProps) => {
  const LazyComponent = withAuthenticationRequired(Lazy);

  return <LazyComponent {...props} />;
};

const Lazy = (props: RouteProps) => {
  const Component = lazy(props.page);
  return (
    <Suspense fallback={<RouteLoading />}>
      <Component {...props} />
    </Suspense>
  );
};
