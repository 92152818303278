import { Viewport } from '@radix-ui/react-toast';
import { styled } from 'styled-components';

export const VIEWPORT_PADDING = 25;

export const ToastViewport = styled(Viewport)`
  position: fixed;
  top: 48px;
  right: 0;
  min-height: auto;
  padding: ${VIEWPORT_PADDING}px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 100000;
  outline: none;
  background: transparent;
`;
