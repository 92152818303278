import { BaseCurrency, Formatter } from '@coin-tracker/number-formatter';
import { find, isEmpty, isNull, isUndefined } from 'lodash';
import {
  Currency,
  CurrencyType,
  GetWalletsForWalletListQuery,
  GetWalletsForWalletListQueryResult,
} from 'src/types/graphql-types';
import {
  isCustomWalletSyncing,
  isExchangeSyncing,
  isWalletSyncing,
} from './WalletsContainer/utils';
import { NavigationType } from './constants';

export const formatValueFromCurrency = (
  value: number,
  baseCurrency: BaseCurrency | Currency,
) => {
  if (isUndefined(value)) {
    return null;
  }

  // the formatter functions will handle overrides when the baseCurrency is set as undefined but not as null.
  // We should probably include a null value or an empty object as well in that function
  if (isNull(baseCurrency)) {
    baseCurrency = undefined;
  }

  if (baseCurrency?.type === CurrencyType.Crypto) {
    return Formatter.formatCrypto(value, baseCurrency as BaseCurrency, {
      isShortened: true,
      showSymbol: true,
    });
  }

  return Formatter.formatFiat(value, baseCurrency as BaseCurrency, {
    convertToBaseCurrency: false,
    isAbbreviated: false,
    isShortened: true,
  });
};

export const convertValueFromCurrency = (
  value: number,
  baseCurrency: BaseCurrency | Currency,
) => {
  if (isUndefined(value)) {
    return null;
  }

  let priceUsd;
  // the formatter functions will handle overrides when the baseCurrency is set as undefined but not as null.
  // We should probably include a null value or an empty object as well in that function
  if (isNull(baseCurrency)) {
    priceUsd = 1;
  } else {
    priceUsd = baseCurrency.priceUsd;
  }
  return value / priceUsd;
};

export const isAnyAssetSyncing = (
  data: GetWalletsForWalletListQuery,
  isCostBasisRunning: boolean,
) => {
  if (isCostBasisRunning) {
    return true;
  }
  const isAnyExchangeSyncing = data.exchanges?.some(isExchangeSyncing);
  if (isAnyExchangeSyncing) {
    return true;
  }

  const isAnyLocalWalletSyncing = data.localWallets?.some(isWalletSyncing);
  if (isAnyLocalWalletSyncing) {
    return true;
  }

  const isAnyCustomWalletSyncing = data.customWallets?.some(
    isCustomWalletSyncing,
  );
  if (isAnyCustomWalletSyncing) {
    return true;
  }

  return false;
};

export const getSelectedWalletOrExchangeByWalletId = (
  walletsQuery: GetWalletsForWalletListQueryResult,
  walletType: string,
  walletId: string,
) => {
  if (
    walletsQuery.error ||
    (walletsQuery.loading && isEmpty(walletsQuery.data)) ||
    isEmpty(walletsQuery.data)
  ) {
    return null;
  }
  if (walletType === NavigationType.EXCHANGE) {
    return find(walletsQuery.data.exchanges, ['id', walletId]);
  }
  if (walletType === NavigationType.WALLET) {
    return find(walletsQuery.data.localWallets, ['id', walletId]);
  }
  if (walletType === NavigationType.CUSTOM_WALLET) {
    return find(walletsQuery.data.customWallets, ['id', walletId]);
  }
  if (walletType === NavigationType.OTHER_TRANSACTION) {
    return walletsQuery.data.otherTransactions;
  }
};
