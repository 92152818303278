import {
  GetIntegrationsQuery,
  Integration,
  useGetIntegrationsLazyQuery,
} from 'src/types/graphql-types';
import { useGate } from 'statsig-react';
import { CSVIntegration } from '../AddWalletModal/SearchResults/BlankSearchResults';
import { createWalletConnectIntegration } from '../utils';

const getIntegrationsFromData = (
  data?: GetIntegrationsQuery,
  includeWalletConnect?: boolean,
): Integration[] => {
  const { integrations: rawIntegrations } = data || {};

  // Add wallet connect integration in the 5th position based on the ranks of other integrations
  const sortedIntegrations = rawIntegrations
    ? [...rawIntegrations].sort((a, b) => a.rank - b.rank)
    : [];
  const walletConnectRank =
    sortedIntegrations.length >= 1 ? sortedIntegrations[0].rank : -1000;
  const walletConnectIntegration = includeWalletConnect
    ? [createWalletConnectIntegration(walletConnectRank)]
    : [];

  const integrations = rawIntegrations
    ? [...walletConnectIntegration, ...rawIntegrations, CSVIntegration]
    : [];
  return integrations;
};

interface useGetIntegrationsProps {
  onCompleted: (data: GetIntegrationsQuery) => void;
}

export default function useGetIntegrations({
  onCompleted,
}: useGetIntegrationsProps) {
  const { value: showWalletConnect } = useGate('ppp_wallet_connect_add_wallet');
  const [fetchIntegrations, { loading, data }] = useGetIntegrationsLazyQuery({
    onCompleted: (data) => {
      if (onCompleted) {
        onCompleted({
          ...data,
          integrations: getIntegrationsFromData(data, showWalletConnect),
        });
      }
    },
  });

  return {
    loading,
    integrations: getIntegrationsFromData(data, showWalletConnect),
    fetchIntegrations,
  };
}
