import { Card, Flex } from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { Integration } from 'src/types/graphql-types';
import AddWalletLoadingCard from '../SearchResults/AddWalletLoadingCard';
import { WalletIcon } from '../WalletIcon';
import { HighlightedIntegrationName } from './HighlightedIntegrationName';
import { getIconURL, isOverrideIcon, setImgURL } from './util';

interface AddWalletCardProps {
  integration: Integration;
  setSelectedIntegration: (integration: Integration) => void;
  searchQuery?: string;
}

export const AddWalletCard = (props: AddWalletCardProps) => {
  const integration = props.integration;
  const [isLoading, setIsLoading] = useState(true);
  const isOverideIcon = isOverrideIcon(integration);
  const iconUrl = getIconURL(integration);

  useEffect(() => {
    const controller = new AbortController();
    setImgURL(iconUrl, () => {}, setIsLoading, controller, isOverideIcon);
    return () => {
      controller.abort();
    };
  }, []);

  const onClick = () => {
    props.setSelectedIntegration(integration);
  };

  if (isLoading) {
    return <AddWalletLoadingCard />;
  }

  return (
    <Card key={integration.slug + '_card'} asChild>
      <button onClick={onClick}>
        <Flex direction="row" gap="2" align="center">
          <WalletIcon integration={integration} />
          <HighlightedIntegrationName
            integrationName={integration.name}
            searchQuery={props.searchQuery ?? ''}
          />
        </Flex>
      </button>
    </Card>
  );
};
