import { JobResult, JobStatus } from '@/types/graphql-types';

export const isJobCompleted = (job?: JobResult) => {
  if (!job) return false;

  return job.status === JobStatus.Finished;
};

export const isJobFailed = (job?: JobResult) => {
  if (!job) return false;

  const jobReturnValue = JSON.parse(job.returnValue);

  return (
    job.status === JobStatus.Failed ||
    (job.status === JobStatus.Finished && jobReturnValue.success === false)
  );
};

export const isJobRunning = (job?: JobResult) => {
  if (!job) return false;

  return (
    job.status === JobStatus.Queued ||
    job.status === JobStatus.Scheduled ||
    job.status === JobStatus.Started
  );
};
