import { Flex, FlexProps } from '@radix-ui/themes';
import { keyframes, styled } from 'styled-components';
import { VIEWPORT_PADDING } from './ToastNotification.styles';

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' },
});

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
});

export const NotificationContainer = styled(Flex)<FlexProps>`
    border: 1px solid var(--gray-6);
    background: var(--gray-1);
    border-radius: var(--radius-4);

    &[data-state='open'] {
        animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    &[data-state='closed'] {
        animation: ${hide} 100ms ease-in;
    }

    &[data-swipe='move'] {
        transform: translateX(var(--radix-toast-swipe-move-x));
    }

    &[data-swipe='cancel'] {
        transform: translateX(0);
        transition: transform 200ms ease-out;
    }

    &[data-swipe='end'] {
        animation: ${swipeOut} 100ms ease-out;
    }
}`;
