import { Formatter } from '@coin-tracker/number-formatter';
import { Currency, CurrencyType } from '../types/graphql-types';

export function replaceWalletNameIfNeeded(originalName: string): string {
  const nameMapping: { [key: string]: string } = {
    // Add mappings here as needed, '[original name]': '[replacement value]'
    'ETH2 Wallet': 'Staked ETH',
    'Coinbase ETH2 Wallet': 'Coinbase Staked ETH',
    ETH2: 'Staked ETH',
  };

  return nameMapping[originalName] || originalName;
}

const voidBaseCurrency = { priceUsd: 0, symbol: '', type: '' };
const voidFiatParams = { showSymbol: false, convertToBaseCurrency: false };
const cryptoParams = { showSymbol: false, isShortened: true };

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const getSignedAmount = (
  type: string,
  amount: number,
  isEntryTypeCredit: boolean,
) => {
  const signedAmount = isEntryTypeCredit ? amount : -amount;
  return type === CurrencyType.Fiat
    ? Formatter.formatFiat(signedAmount, voidBaseCurrency, {
        showSymbol: false,
        convertToBaseCurrency: false,
        showSign: true,
      })
    : Formatter.formatCrypto(signedAmount, voidBaseCurrency, {
        showSymbol: false,
        isShortened: true,
        showSign: true,
      });
};

export const getAmount = (type: string, amount: number) => {
  return type === CurrencyType.Fiat
    ? Formatter.formatFiat(amount, voidBaseCurrency, voidFiatParams)
    : Formatter.formatCrypto(amount, voidBaseCurrency, cryptoParams);
};

export const getFiatValue = (fiatValue: number, baseCurrency: Currency) => {
  return Formatter.formatFiat(
    fiatValue,
    baseCurrency as Formatter.BaseCurrency,
    { convertToBaseCurrency: false },
  );
};
