import { Dispatch, SetStateAction } from 'react';
import { Integration } from 'src/types/graphql-types';

export function isOverrideIcon(integration: Integration): boolean {
  return (
    integration?.info?.circleIconUrl !== undefined &&
    integration?.info?.circleIconUrl !== null
  );
}

export function getIconURL(integration: Integration): string {
  const isOverideIcon = isOverrideIcon(integration);
  return isOverideIcon
    ? integration?.info?.circleIconUrl
    : `https://coin-tracker-public.s3.us-west-1.amazonaws.com/crypto-icons/favicon/${integration.slug}.ico`;
}

export const setImgURL = async (
  iconUrl: string,
  setIcon: Dispatch<SetStateAction<string>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  controller: AbortController,
  isOverrideIcon: boolean,
) => {
  if (process.env.VITEST) {
    setIsLoading(false);
    setIcon(null);
    return;
  }
  // Fetching the override icon path results in CORS error intermittently - since the override icon will always exist if its path is set in the integration.info.circle_icon_url, the icon can be immediately set
  if (isOverrideIcon) {
    setIcon(iconUrl);
    setIsLoading(false);
    return;
  }
  try {
    const icon = await fetch(iconUrl, {
      method: 'GET',
      signal: controller.signal,
    });
    if (icon.ok) {
      setIcon(iconUrl);
      setIsLoading(false);
      return;
    }
  } catch (error) {
    if (error.name !== 'AbortError') {
      setIcon(null);
      setIsLoading(false);
    }
  }
  setIcon(null);
  setIsLoading(false);
};
