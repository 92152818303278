import PortfolioIllustrationSVG from '@/assets/illustration-portfolio.svg?react';
import { Flex, Heading, Spinner, Text } from '@radix-ui/themes';
import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import AppThemeProvider from '../AppThemeProvider';
import { UserContext, useGetUser } from '../user';
import { LocalStorageUtils } from '../utils';

const TEN_SECONDS = 10000;
const chunkFailedMessages = [
  /Failed to fetch dynamically imported module/,
  /error loading dynamically imported module/,
  /Importing a module script failed/,
  /Loading chunk (\d+) failed/,
  /ChunkLoadError/,
];

const isMessageMatchingModuleError = (error: Error) =>
  chunkFailedMessages.some(
    (chunkFailedMessage) =>
      error?.message && chunkFailedMessage.test(error.message),
  );

export const ErrorPage = () => {
  const error = useRouteError() as Error;

  const hasChunkLoadError = isMessageMatchingModuleError(error);

  useEffect(() => {
    if (hasChunkLoadError) {
      // eslint-disable-next-line no-console
      console.warn(error); // Log the error to the console so we can see what's going on.

      // This is to prevent an infinite reload problem in case the asset actually doesn't exist.
      // If the error happened within 10s then the asset most likely actually doesn't exist.
      if (!LocalStorageUtils.getWithExpiration('page_chunk_failed')) {
        LocalStorageUtils.setWithExpiration(
          'page_chunk_failed',
          'true',
          TEN_SECONDS,
        );
        window.location.reload();
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
      captureException(error);
    }
  }, [error, hasChunkLoadError]);

  return (
    <Flex
      direction="column"
      height="100%"
      justify="center"
      align="center"
      gap="4"
    >
      {hasChunkLoadError && <Spinner data-testid="loading-spinner" />}
      {!hasChunkLoadError && (
        <>
          <PortfolioIllustrationSVG />
          <Heading as="h3">Oops! This is unexpected.</Heading>
          <Text weight="medium" color="gray">
            {`An error has occurred and we're working to fix the problem. Check
              back later.`}
          </Text>
        </>
      )}
    </Flex>
  );
};

export const GlobalErrorPage = () => {
  const { user } = useGetUser();
  return (
    <UserContext.Provider value={user}>
      <AppThemeProvider>
        <ErrorPage />
      </AppThemeProvider>
    </UserContext.Provider>
  );
};
