import { Integration, IntegrationFormType } from 'src/types/graphql-types';

export const createWalletConnectIntegration = (rank: number = -1000) => {
  return {
    slug: 'wallet-connect',
    name: 'WalletConnect',
    imageUrl:
      'https://coin-tracker-public.s3.us-west-1.amazonaws.com/crypto-icons/icons/wallet-connect-logo.svg',
    category: 'Wallet',
    isDisabled: false,
    syncMethod: 'Automatic sync',
    rank,
    supportsHdWallet: true,
    info: {
      circleIconUrl:
        'https://coin-tracker-public.s3.us-west-1.amazonaws.com/crypto-icons/icons/wallet-connect-logo.svg',
    },
    formId: IntegrationFormType.Address,
  } as Integration;
};
