import { Callout, Flex, Strong } from '@radix-ui/themes';
import { Info } from 'lucide-react';

export const ViewPermissions = () => {
  return (
    <Callout.Root variant="soft">
      <Flex direction="row" align="center" gap="4">
        <Callout.Icon>
          <Info size="18" />
        </Callout.Icon>
        <Callout.Text size="2" color="gray">
          We are only requesting <Strong>view permissions</Strong>. This does
          not give us access to your private keys nor the ability to move your
          funds.
        </Callout.Text>
      </Flex>
    </Callout.Root>
  );
};
