import { Avatar, Skeleton } from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { Integration } from 'src/types/graphql-types';
import { getIconURL, isOverrideIcon, setImgURL } from '../AddWalletCard/util';

export const getFirstLetterOfSlug = (slug = '') => {
  return slug?.substring(0, 1).toUpperCase();
};

interface WalletIconProps {
  integration: Integration;
}
export const WalletIcon = ({ integration }: WalletIconProps) => {
  const isOverideIcon = integration ? isOverrideIcon(integration) : false;
  const [icon, setIcon] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const iconUrl = integration ? getIconURL(integration) : '';
    if (isOverideIcon) {
      setIcon(iconUrl);
      setIsLoading(false);
    } else {
      setImgURL(iconUrl, setIcon, setIsLoading, controller, isOverideIcon);
    }

    return () => {
      controller.abort();
    };
  }, [isOverideIcon, integration]);

  if (isLoading) {
    return (
      <Skeleton width="36px" height="36px" style={{ borderRadius: '50%' }} />
    );
  }
  // If we have a known icon, we should use the full sized icon
  if (isOverideIcon || icon) {
    return (
      <Avatar
        src={icon}
        radius="full"
        fallback={getFirstLetterOfSlug(integration.slug)}
        size="2"
      />
    );
  }
  // If we don't have a preloaded image, we can just use the first letter of the integration in a cricle
  return (
    <Avatar
      radius="full"
      fallback={getFirstLetterOfSlug(integration.slug)}
      size="2"
    />
  );
};
