import { forEach } from 'lodash';
import { ThemeProps, isDarkTheme } from 'src/app/theme';
import { hexToRgb } from 'src/app/theme/utils';
import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{
  theme: ThemeProps;
  prefix?: string;
}>`
  :root {
    color-scheme: ${({ theme }) => (isDarkTheme(theme) ? 'dark' : 'light')};

    // will convert the theme.variables object into css variables and inject it in the dom ie: '--neutral-300: #D6D9E1;'
    // will also print {--color-value}-rgb so that we can introduce opacity when needed for each color
    ${({ theme, prefix }) => {
      if (!theme || !theme.variables) {
        return;
      }
      let variablesLiteral = '';

      const categories = theme.variables;
      forEach(categories, (category, categoryName) => {
        forEach(category, (variable, variableName) => {
          forEach(variable, (property, propertyName) => {
            variablesLiteral += `--${variableName}-${propertyName}: ${property};\n`;
            if (categoryName === 'colors') {
              const rgbColor = hexToRgb(property);
              variablesLiteral += `--${variableName}-${propertyName}-rgb: ${rgbColor.join(', ')};\n`;
            }
          });
        });
      });

      if (prefix) {
        return css`
          .${prefix} {
            ${variablesLiteral}
          }
        `;
      }
      return css`
        ${variablesLiteral}
      `;
    }}

  }
  `;
