const FIELD_REQUIRED_HINT = 'This field is required';
export type ValidationErrors = {
  walletAddress?: string;
};

export const validateWalletAddress = (
  walletAddress: string,
): ValidationErrors => {
  const errors: ValidationErrors = {};
  if (!walletAddress.trim()) {
    errors.walletAddress = FIELD_REQUIRED_HINT;
  }
  return errors;
};
