import { Integration } from '@/types/graphql-types';
import { Button } from '@radix-ui/themes';
import { redirectToIntegration } from './utils';

export interface PartnerRedirectFormProps {
  integration: Integration;
}

export const PartnerRedirectForm = (props: PartnerRedirectFormProps) => {
  const integration = props.integration;
  const { webOauthAuthorize = {} } = integration;

  if (!webOauthAuthorize?.url) {
    <Button disabled>Hmm...something went wrong.</Button>;
  }

  return (
    <Button onClick={() => redirectToIntegration(integration)} size="3">
      Continue with {integration.name}
    </Button>
  );
};
