import { InputLabel } from 'src/deprecated-components/typography/input-texts';
import { LinkSmall } from 'src/deprecated-components/typography/link-texts';
import styled, { css } from 'styled-components';

export const InputContainer = styled.div<{
  disabled?: boolean;
  $hasError?: boolean;
}>`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.grey.mid};
  border-radius: 5px;
  background: ${({ theme }) => theme.grey.white};
  position: relative;
  z-index: 99;
  &:hover,
  &:active,
  &:focus-within {
    border: 1px solid ${({ theme }) => theme.primary.solid};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.grey.light};

      &:hover,
      &:active,
      &:focus-within {
        border: 1px solid ${({ theme }) => theme.grey.mid};
      }

      ${StyledInput},
      ${SuffixIcon} {
        color: ${({ theme }) => theme.text.tertiary};
        cursor: not-allowed;
      }
    `}
  ${({ $hasError = false }) =>
    $hasError &&
    css`
      &,
      &:hover,
      &:active,
      &:focus-within {
        border: 1px solid ${({ theme }) => theme.red.solid};
      }
    `}
`;

export const StyledInput = styled.input`
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  width: 100%;
  color: ${({ theme }) => theme.text.primary};

  padding: 14px 10px 14px 10px;

  &::placeholder {
    color: ${({ theme }) => theme.text.tertiary};
  }
`;

export const PrefixIcon = styled.div`
  padding: 14px 0px 14px 15px;
  line-height: 1;
  color: ${({ theme }) => theme.text.primary};
`;

export const SuffixIcon = styled.div`
  padding: 14px 15px 14px 0px;
  line-height: 1;
`;

export const ErrorMessage = styled(LinkSmall)`
  margin-top: 8px;
  margin-bottom: 0;
  cursor: default;
  color: ${({ theme }) => theme.red.solid};
`;

export const Label = styled(InputLabel)`
  margin-bottom: 8px;
  display: block;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormGroup = styled.div`
  margin-bottom: 16px;
`;
