import { IntegrationSyncMethod } from 'src/common/Integration';

/*
parseInstructions takes the instructions string which stores html and extracts the api and file instructions. This is required as integration instructions are stored in the db as strings containing html. This should be removed once there is a different storage mechanism for instructions.
 */
export const parseInstructions = (
  instructions: string,
  sync_method: IntegrationSyncMethod,
) => {
  let fileInstructions = null;
  let apiInstructions = null;

  if (!instructions)
    return {
      apiInstructions,
      fileInstructions,
    };

  const parser = new DOMParser();
  const allInstructions = instructions.replace(/ d-none/g, '');
  const doc = parser.parseFromString(allInstructions, 'text/html');

  fileInstructions =
    doc?.querySelector('.instructions.file-importer')?.outerHTML ?? null;
  // an integration with a file importer could have custom instructions that do not include the class names .instructions.file-importer
  if (!fileInstructions && sync_method === IntegrationSyncMethod.CustomFile) {
    fileInstructions = allInstructions;
  }
  apiInstructions =
    doc?.querySelector('.instructions.api-importer')?.outerHTML ??
    allInstructions;

  return {
    apiInstructions: apiInstructions,
    fileInstructions: fileInstructions,
  };
};
