// CT database slug to hexidecimal chainId standard. See https://chainlist.org/
export enum chainIds {
  ethereum = '0x1',
  'binance-smart-chain' = '0x38',
  optimism = '0x7E82',
  'arbitrum-one' = '0xa4b1',
  polygon = '0x89',
  'ethereum-classic' = '0x3d',
  avalanche = '0xA86A',
  fantom = '0xFA',
  moonriver = '0x505',
  'huobi-token' = '0x80',
}
