import { Card, Flex } from '@radix-ui/themes';

export type InstructionContainerProps = {
  children?: React.ReactNode;
  hideBackground?: boolean;
};

export const InstructionContainer = ({
  children,
  hideBackground,
}: InstructionContainerProps) => {
  return (
    <>
      {children && (
        <Card variant={hideBackground ? 'ghost' : 'surface'}>
          <Flex direction="column" p="2">
            {children}
          </Flex>
        </Card>
      )}
    </>
  );
};
