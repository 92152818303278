import { Flex, Heading, Link, Text } from '@radix-ui/themes';
import { Integration } from 'src/types/graphql-types';

export function GenericCSVInstructions(props: { integration: Integration }) {
  return (
    <Flex direction="column" gap="2">
      <Heading size="3">
        Steps to add your {props.integration.name} transactions:
      </Heading>
      <Text asChild size="2">
        <ol>
          <li>
            Download the CSV export of your transactions{' '}
            {props.integration.websiteUrl ? (
              <span>
                from{' '}
                {
                  <a href={props.integration.websiteUrl}>
                    {props.integration.name}
                  </a>
                }
              </span>
            ) : (
              ''
            )}
          </li>
          <li>
            Re-format it into the CoinTracker CSV format{' '}
            <Link href="https://support.cointracker.io/hc/en-us/articles/4413071299729-Converting-transaction-history-CSVs-to-the-CoinTracker-CSV-format">
              like this
            </Link>
          </li>
          <li>Import this file here</li>
        </ol>
      </Text>
      <Text as="span" size="2">
        For more details, you can follow{' '}
        <Link href="https://support.cointracker.io/hc/en-us/articles/4413071299729-Converting-transaction-history-CSVs-to-the-CoinTracker-CSV-format">
          this guide
        </Link>
        .
      </Text>
    </Flex>
  );
}
