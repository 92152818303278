import styled from 'styled-components';
import { variantStyles } from './variants';

export const InputLabel = styled.span<{
  $variant?: string;
  $fontWeight?: string;
}>`
  font-style: normal;
  font-weight: ${({ $fontWeight }) => $fontWeight ?? '400'};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}
`;
