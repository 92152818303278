import { Box, Callout, Flex, IconButton, Text } from '@radix-ui/themes';
import { Info, X } from 'lucide-react';

export type BannerProps = {
  type?: 'success' | 'warning' | 'error';
  className?: string; // https://styled-components.com/docs/basics#styling-any-component
  children?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

export const Banner = ({ className, type, children, onClose }: BannerProps) => {
  let color: Callout.RootProps['color'] = 'green';
  if (type === 'warning') {
    color = 'yellow';
  } else if (type === 'error') {
    color = 'red';
  }

  return (
    <Callout.Root
      variant="soft"
      color={color}
      className={className}
      role={type !== 'success' && 'alert'}
      style={{ justifyContent: 'stretch' }}
    >
      <Flex direction="row" align="center" justify="between" gap="2">
        <Flex direction="row" align="center" gap="2">
          <Box width="18px" height="18px">
            <Info size="18px" />
          </Box>
          <Text size="2">{children}</Text>
        </Flex>

        <IconButton onClick={onClose} variant="ghost" size="2">
          <X size="18" />
        </IconButton>
      </Flex>
    </Callout.Root>
  );
};
