import { SideBar } from '@/components/SideBar';
import { Box, ScrollArea } from '@radix-ui/themes';
import { useEffect, useRef, useState } from 'react';
import { type ImperativePanelHandle } from 'react-resizable-panels';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'src/components/Resizable';
import { styled } from 'styled-components';

const StyledResizablePanel = styled(ResizablePanel)<{ $isCollapsed?: boolean }>`
  ${({ $isCollapsed }) =>
    $isCollapsed &&
    `
      min-width: 50px;
      transition: all 0.3s ease-in-out;
    `}
`;

interface Props {
  children: React.ReactNode;
  isAuthenticated?: boolean;
}
export function SideBarWithTopBarLayout({ children, isAuthenticated }: Props) {
  const sideBarPanel = useRef<ImperativePanelHandle>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  useEffect(() => {
    if (sideBarPanel.current) {
      setIsCollapsed(sideBarPanel.current.isCollapsed());
    }
  }, [sideBarPanel]);

  return (
    <Box height="100vh">
      <ResizablePanelGroup direction="horizontal" autoSaveId="persistence">
        <StyledResizablePanel
          collapsible={true}
          collapsedSize={3}
          minSize={12}
          maxSize={15}
          onCollapse={() => setIsCollapsed(true)}
          onExpand={() => setIsCollapsed(false)}
          ref={sideBarPanel}
          $isCollapsed={isCollapsed}
        >
          <SideBar
            isCollapsed={isCollapsed}
            isAuthenticated={isAuthenticated}
          />
        </StyledResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel minSize={30}>
          <ScrollArea style={{ height: '100%' }} scrollbars="both">
            {children}
          </ScrollArea>
        </ResizablePanel>
      </ResizablePanelGroup>
    </Box>
  );
}

export default SideBarWithTopBarLayout;
