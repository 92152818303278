import { Flex, FlexProps } from '@radix-ui/themes';

export const FormContent = ({
  children,
  direction = 'column',
  mb = '4',
  gap = '2',
  ...props
}: FlexProps) => {
  return (
    <Flex {...props} direction={direction} mb={mb} gap={gap}>
      {children}
    </Flex>
  );
};
