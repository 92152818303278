import * as Dialog from '@radix-ui/react-dialog';
import { InlineButton } from 'src/deprecated-components/buttons/regular';
import styled from 'styled-components';

export const DialogOverlay = styled(Dialog.Overlay)`
  background-color: ${({ theme }) => `${theme.grey.dark}80`};
  position: fixed;
  z-index: 99;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -18%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -20%) scale(1);
    }
  }
`;

export const DialogContent = styled(Dialog.Content)`
  background-color: ${({ theme }) => theme.grey.white};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadow.level1};
  position: fixed;
  top: 20%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -20%);
  width: 90vw;
  max-width: 488px;
  max-height: 85vh;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  flex-direction: column;

  &:focus {
    outline: none;
  }
`;

export const DialogHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.grey.mid};
`;

export const DialogTitle = styled(Dialog.Title)`
  margin: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.text.primary};
  font-size: 20px;
  line-height: 24px;
  flex: 1;
`;

export const DialogDescription = styled(Dialog.Description)`
  color: ${({ theme }) => theme.text.secondary};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const DialogBody = styled.div`
  padding: 24px;
  flex: 1;
  overflow: auto;
  overscroll-behavior: contain;
`;

export const ModalError = styled.div`
  margin-top: -10px;
  margin-bottom: 24px;
`;

export const DialogFooter = styled.footer`
  display: flex;
  align-items: center;
  margin-top: 24px;
  justify-content: flex-end;
  flex-wrap: wrap;

  button:not(:first-child) {
    margin-left: 20px;
  }
`;

export const DialogClose = styled(InlineButton)`
  &:hover svg > path {
    stroke: ${({ theme }) => theme.primary.solid};
  }
`;
