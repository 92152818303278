import { Link, Text } from '@radix-ui/themes';
import { AddWalletErrors } from 'src/types/graphql-types';

interface ErrorMessageProps {
  error: AddWalletErrors | string;
}

export const defaultErrorMessage = 'An error has occurred, please try again.';
export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  if (error === AddWalletErrors.HasZeroTransactions) {
    return (
      <Text>
        We could not add your wallet as it has zero transactions.{' '}
        <Link
          target="_blank"
          href="https://support.cointracker.io/hc/en-us/articles/4413071373329-How-do-I-track-my-local-cold-hardware-wallet-Why-is-it-showing-zero-balance-"
        >
          Learn more about this error.
        </Link>
      </Text>
    );
  } else if (error === AddWalletErrors.TooManyTransactions) {
    return (
      <Text>
        This wallet exceeds the number of transactions supported by the
        platform.
        <Link
          target="_blank"
          href="https://support.cointracker.io/hc/en-us/?generationErrorMsg"
        >
          Contact support
        </Link>{' '}
        to request an increase.
      </Text>
    );
  } else if (error === AddWalletErrors.WalletAlreadyLinked) {
    return (
      <Text>
        This wallet has already been added to CoinTracker. Please see below.
      </Text>
    );
  } else if (error === AddWalletErrors.WalletValidationError) {
    return (
      <Text>
        We could not add your wallet as this address is invalid. Please follow
        the instructions below and try again.
      </Text>
    );
  } else if (error === AddWalletErrors.InvalidCryptoCurrency) {
    return <Text>Wallets of this coin are not supported yet</Text>;
  } else if (
    error === AddWalletErrors.UnknownError ||
    error === AddWalletErrors.ValueError
  ) {
    return <Text>{defaultErrorMessage}</Text>;
  } else {
    return <Text>{error}</Text>;
  }
};
