import { Box, Flex, Heading, Text } from '@radix-ui/themes';
import Illustration404Svg from 'src/assets/illustration-404.svg?react';

export const PageNotFound = () => {
  return (
    <Flex direction="column" justify="center" align="center" gap="4">
      <Box>
        <Illustration404Svg />
      </Box>
      <Heading>Page Not Found</Heading>
      <Text color="gray">
        {"We're sorry, but we can't find the page you are looking for."}
      </Text>
    </Flex>
  );
};
export default PageNotFound;
