import {
  createWeb3Modal,
  defaultWagmiConfig,
  useWeb3ModalState,
  useWeb3ModalTheme,
} from '@web3modal/wagmi/react';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/app/user';
import { iconsImagesURL } from 'src/common/constants';
import { getNiceWalletName } from 'src/common/walletName';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import Banner from 'src/deprecated-components/Banner/Banner';
import { ModalError } from 'src/deprecated-components/Modal/styles';
import {
  Status,
  ToastContext,
} from 'src/deprecated-components/ToastNotification';
import { GetWallets } from 'src/pages/wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetWalletsForWalletListQuery,
  Integration,
  Maybe,
  useAddPublicAddressMutation,
} from 'src/types/graphql-types';
import { useTheme } from 'styled-components';
import {
  Config,
  PublicClient,
  WagmiConfig,
  WebSocketPublicClient,
  useAccount,
  useDisconnect,
  useNetwork,
} from 'wagmi';
import {
  arbitrum,
  avalanche,
  base,
  mainnet,
  optimism,
  polygon,
} from 'wagmi/chains';
import { AddWalletContext } from '../../context';
import { TextFormField } from '../FormBaseComponents/TextFormField';
import { ViewPermissions } from '../FormBaseComponents/ViewPermissions';
import { FormContent, SubmitButton } from '../styles';
import { emptyCacheQuery } from '../util';
import { ErrorMessage } from './ErrorMessage';
import { WalletConnectButtonContainer } from './styles';

let wagmiConfigCt: Maybe<Config<PublicClient, WebSocketPublicClient>>;

export const initWalletConnect = () => {
  // if we already initialized wagmiConfig, return it
  if (wagmiConfigCt) return wagmiConfigCt;

  // 1. Get projectId
  const projectId = process.env.WALLET_CONNECT_PROJECT_ID;

  // 2. Create wagmiConfig
  const metadata = {
    name: 'CoinTracker',
    description: 'Crypto Taxes and Portfolio Tracker',
    url: 'https://www.cointracker.io/',
    icons: [`${iconsImagesURL}/logo_white.svg`],
  };

  const chains = [mainnet, arbitrum, optimism, base, avalanche, polygon];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

  //  3. Create modal
  createWeb3Modal({ wagmiConfig, projectId, chains });

  wagmiConfigCt = wagmiConfig;
  return wagmiConfig;
};
interface WalletConnectProps {
  integration: Integration;
  onCloseModal?: () => void;
  wagmiConfig?: Config<PublicClient, WebSocketPublicClient>;
}

export const WalletConnect = (props: WalletConnectProps) => {
  return (
    <WagmiConfig config={props.wagmiConfig}>
      <WalletConnectContent {...props} />
    </WagmiConfig>
  );
};

export const WalletConnectContent = (props: WalletConnectProps) => {
  const { setIsHidden: setAddWalletModalIsHidden } =
    useContext(AddWalletContext);
  const { chain } = useNetwork();
  const user = useContext(UserContext);
  const theme = useTheme();
  const { open: isWalletConnectOpen } = useWeb3ModalState();
  const { disconnect } = useDisconnect();
  const [walletName, setWalletName] = useState<string>(undefined);
  const onWalletNameChange = useCallback((e) => {
    setWalletName(e.target.value);
  }, []);

  const { address: walletAddress } = useAccount();
  const { setThemeMode } = useWeb3ModalTheme();
  useEffect(() => {
    if (user?.isAuthenticated) {
      if (theme.name.includes('dark')) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode('light');
    }
  }, [user, setThemeMode, theme]);

  useEffect(() => {
    setAddWalletModalIsHidden(isWalletConnectOpen);
  }, [isWalletConnectOpen, setAddWalletModalIsHidden]);

  const integration = props.integration;
  const [submitErrorMessage, setSubmitErrorMessage] =
    useState<AddWalletErrors>();
  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);

  // On component unmount disconnect the wallet
  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [disconnect]);

  const [mutate, { loading }] = useAddPublicAddressMutation({
    onCompleted: (data) => {
      if (data?.addPublicAddress?.success) {
        if (data?.addPublicAddress?.warning) {
          showNotification({
            message: `If you have previously added ${integration.info.symbol} transactions manually or marked related transactions as transfers, you may now have duplicate transactions. Please review your ${integration.info.symbol} transactions and if necessary undo those changes.`,
            status: Status.Info,
          });
        }
        showNotification({
          message: data.addPublicAddress.success,
          status: Status.Success,
        });
        const createdWalletOrExchange =
          data.addPublicAddress.createdWallet ||
          data.addPublicAddress.createdExchange;
        onSuccess(createdWalletOrExchange);
        props.onCloseModal?.();
      } else {
        if (data?.addPublicAddress?.error) {
          setSubmitErrorMessage(data?.addPublicAddress?.error);
        } else {
          setSubmitErrorMessage(AddWalletErrors.UnknownError);
        }
      }
    },
    update: (cache, { data }) => {
      if (!data.addPublicAddress.success) {
        return;
      }

      const walletsData: GetWalletsForWalletListQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      if (data.addPublicAddress.createdWallet) {
        const newLocalWallets = [
          ...walletsData.localWallets,
          data.addPublicAddress.createdWallet,
        ];

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, localWallets: newLocalWallets },
        });
      } else if (data.addPublicAddress.createdExchange) {
        const newExchanges = [
          ...walletsData.exchanges,
          data.addPublicAddress.createdExchange,
        ];

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, exchanges: newExchanges },
        });
      }
    },
    onError: () => {
      setSubmitErrorMessage(AddWalletErrors.UnknownError);
    },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const kebabNetwork = chain.name.replace(/\W+/g, '-').toLowerCase();

      mutate({
        variables: {
          publicAddressInput: {
            coinSymbol: chain.nativeCurrency.symbol,
            cryptoNetwork: kebabNetwork,
            walletAddress,
            walletName,
          },
        },
      });
      startFasterPollingForSyncCompletion();
    },
    [
      mutate,
      chain,
      walletAddress,
      startFasterPollingForSyncCompletion,
      walletName,
    ],
  );

  const content = (
    <>
      <FormContent>
        <form onSubmit={onSubmit}>
          {submitErrorMessage && (
            <ModalError>
              <Banner
                type="error"
                onBannerClose={() => setSubmitErrorMessage(null)}
              >
                <ErrorMessage error={submitErrorMessage} />
              </Banner>
            </ModalError>
          )}
          <WalletConnectButtonContainer>
            <w3m-button data-testid="w3m-button" />
            {!isEmpty(walletAddress) && <w3m-network-button />}
          </WalletConnectButtonContainer>

          {!isEmpty(walletAddress) && (
            <TextFormField
              name="wallet_address"
              value={walletName}
              onChange={onWalletNameChange}
              description="Wallet name"
              placeholder={getNiceWalletName(walletAddress, chain?.name)}
              optional={true}
              autoFocus={true}
            />
          )}

          <SubmitButton disabled={loading || !walletAddress} type="submit">
            {loading ? 'Please wait...' : `Add Wallet`}
          </SubmitButton>
        </form>
      </FormContent>
      <ViewPermissions />
    </>
  );
  return <>{content}</>;
};
