var __defProp = Object.defineProperty;
var __export = function(target, all) {
    for(var name in all)__defProp(target, name, {
        get: all[name],
        enumerable: true
    });
};
// src/formatter.ts
var formatter_exports = {};
__export(formatter_exports, {
    CurrencySignFormat: function() {
        return CurrencySignFormat;
    },
    formatCrypto: function() {
        return formatCrypto;
    },
    formatFiat: function() {
        return formatFiat;
    },
    getCurrencySignFromBaseCurrency: function() {
        return getCurrencySignFromBaseCurrency;
    }
});
import * as CurrencyCode from "currency-codes";
import get from "lodash/get";
import isNaN from "lodash/isNaN";
import isNull from "lodash/isNull";
// src/constants.ts
var currencySymbolSettings = {
    BTC: {
        sign: "\u20BF",
        prefix: true
    },
    EUR: {
        sign: "\u20AC",
        prefix: true,
        locale: "de"
    },
    PLN: {
        sign: "z\u0142",
        locale: "pl"
    },
    RUB: {
        sign: "\u20BD",
        locale: "ru"
    },
    UAH: {
        sign: "\u20B4",
        prefix: true,
        locale: "uk"
    },
    USD: {
        sign: "$",
        prefix: true,
        locale: "en-us"
    },
    GBP: {
        sign: "\xA3",
        prefix: true,
        locale: "en-gb"
    },
    CAD: {
        sign: "CA$",
        prefix: true,
        locale: "en-ca"
    },
    INR: {
        sign: "\u20B9",
        prefix: true,
        locale: "en-in"
    },
    AUD: {
        sign: "A$",
        prefix: true,
        locale: "en-au"
    }
};
var defaultBaseCurrency = {
    symbol: "USD",
    type: "FIAT",
    priceUsd: 1
};
var dollarSignCountryCodes = [
    "AU",
    "CA",
    "US"
];
var homeCountrySettings = {
    AU: {
        locale: "en-au"
    },
    CA: {
        locale: "en-ca"
    },
    US: {
        locale: "en-us"
    }
};
// src/formatter.ts
var maxFiatSigDigs = 4;
var maxFiatDecimals = 8;
var defaultFiatPrecision = 2;
var fiatSigDigsAbbreviated = 3;
var maxCryptoSigDigsPast8Decimals = 4;
var maxCryptoSigDigs = 9;
var minCryptoDecimals = 2;
var cryptoSigDigsAbbreviated = 3;
var standardCryptoPrecision = 8;
var infinity = 1e20;
var maxToFixedDecimalPlaces = 20;
var fiatOneCent = 0.01;
var centerAlignedTildeSymbol = "\u2248";
var CurrencySignFormat = /* @__PURE__ */ function(CurrencySignFormat2) {
    CurrencySignFormat2["STANDARD"] = "standard";
    CurrencySignFormat2["ACCOUNTING"] = "accounting";
    return CurrencySignFormat2;
}(CurrencySignFormat || {});
var getLocaleFromBaseCurrency = function(baseCurrency) {
    return get(currencySymbolSettings, baseCurrency.symbol, currencySymbolSettings.USD).locale;
};
var getLocaleFromHomeCountryCode = function(homeCountryCode) {
    return get(homeCountrySettings, homeCountryCode, homeCountrySettings.US).locale;
};
var formatFiat = function(value, baseCurrency) {
    var _ref = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, _ref_showSymbol = _ref.showSymbol, showSymbol = _ref_showSymbol === void 0 ? true : _ref_showSymbol, _ref_isAbbreviated = _ref.isAbbreviated, isAbbreviated = _ref_isAbbreviated === void 0 ? false : _ref_isAbbreviated, _ref_showSign = _ref.showSign, showSign = _ref_showSign === void 0 ? false : _ref_showSign, _ref_convertToBaseCurrency = _ref.convertToBaseCurrency, convertToBaseCurrency = _ref_convertToBaseCurrency === void 0 ? true : _ref_convertToBaseCurrency, _ref_isEstimate = _ref.isEstimate, isEstimate = _ref_isEstimate === void 0 ? false : _ref_isEstimate, _ref_isShortened = _ref.isShortened, isShortened = _ref_isShortened === void 0 ? false : _ref_isShortened, _ref_isPrecise = _ref.isPrecise, isPrecise = _ref_isPrecise === void 0 ? false : _ref_isPrecise, _ref_roundToNearestInteger = _ref.roundToNearestInteger, roundToNearestInteger = _ref_roundToNearestInteger === void 0 ? false : _ref_roundToNearestInteger, _ref_fiatPrecision = _ref.fiatPrecision, fiatPrecision = _ref_fiatPrecision === void 0 ? defaultFiatPrecision : _ref_fiatPrecision, _ref_currencySign = _ref.currencySign, currencySign = _ref_currencySign === void 0 ? "standard" /* STANDARD */  : _ref_currencySign, homeCountryCode = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : "";
    if (isNull(value)) {
        return "";
    } else if (value >= infinity) {
        return "\u221E";
    } else if (value <= -infinity) {
        return "-\u221E";
    } else if (isNaN(value)) {
        value = 0;
    }
    if (baseCurrency === void 0) {
        baseCurrency = defaultBaseCurrency;
    }
    var locale = getLocaleFromBaseCurrency(baseCurrency);
    if (dollarSignCountryCodes.includes(homeCountryCode)) {
        locale = getLocaleFromHomeCountryCode(homeCountryCode);
    }
    var options = getFiatFormatOptions(value, baseCurrency, {
        isAbbreviated: isAbbreviated,
        showSymbol: showSymbol,
        showSign: showSign,
        isPrecise: isPrecise,
        roundToNearestInteger: roundToNearestInteger,
        isShortened: isShortened,
        fiatPrecision: fiatPrecision,
        currencySign: currencySign
    });
    if (convertToBaseCurrency && CurrencyCode.code(baseCurrency.symbol) && baseCurrency.priceUsd) {
        value = value / baseCurrency.priceUsd;
    }
    if (isShortened) {
        value = truncateSmallValue(value);
    }
    var resultString = new Intl.NumberFormat(locale, options).format(value);
    if (isEstimate) {
        return "".concat(centerAlignedTildeSymbol, " ").concat(resultString);
    }
    if (isShortened && value == fiatOneCent) {
        return "< ".concat(resultString);
    }
    return resultString;
};
var truncateSmallValue = function(value) {
    if (Math.abs(value) >= fiatOneCent) {
        return value;
    }
    if (value > 0) {
        return fiatOneCent;
    }
    return 0;
};
var getFiatFormatOptions = function(value, baseCurrency, param) {
    var isAbbreviated = param.isAbbreviated, showSymbol = param.showSymbol, showSign = param.showSign, isPrecise = param.isPrecise, roundToNearestInteger = param.roundToNearestInteger, isShortened = param.isShortened, fiatPrecision = param.fiatPrecision, currencySign = param.currencySign;
    var options = {};
    if (Math.abs(value) < 1e3) isAbbreviated = false;
    if (Math.abs(value) < 0.01 && value !== 0 && !isShortened) {
        options.maximumSignificantDigits = maxFiatSigDigs;
    } else if (isAbbreviated) {
        options.notation = "compact";
        options.maximumSignificantDigits = fiatSigDigsAbbreviated;
    }
    if (isPrecise) {
        options.maximumFractionDigits = maxFiatDecimals;
    } else {
        options.maximumFractionDigits = fiatPrecision;
        options.minimumFractionDigits = defaultFiatPrecision;
    }
    if (roundToNearestInteger) {
        options.maximumFractionDigits = 0;
        options.minimumFractionDigits = 0;
    }
    if (showSymbol) {
        options.style = "currency";
        if (CurrencyCode.code(baseCurrency.symbol) && baseCurrency.priceUsd) {
            options.currency = baseCurrency.symbol;
        } else {
            options.currency = "USD";
        }
    }
    if (showSign) {
        options.signDisplay = "exceptZero";
    }
    options.currencySign = currencySign;
    return options;
};
var getIntegerDigitsLength = function(value) {
    var wholeNumber = Math.trunc(value);
    return wholeNumber.toString().length;
};
var getNumLeadingZeroDecimals = function(value) {
    var decimals = value.toFixed(maxToFixedDecimalPlaces).toString().split(".")[1];
    var count = 0;
    if (!decimals) {
        return count;
    }
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = decimals[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var n = _step.value;
            if (n == "0") {
                count++;
            } else {
                break;
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return count;
};
var isVerySmallDecimal = function(value) {
    if (value > 1) return false;
    return getNumLeadingZeroDecimals(value) + maxCryptoSigDigsPast8Decimals >= standardCryptoPrecision;
};
var isVeryLargeAmount = function(value) {
    return getIntegerDigitsLength(value) + minCryptoDecimals >= maxCryptoSigDigs;
};
var getCryptoFormatOptions = function(value, baseCurrency, param) {
    var isAbbreviated = param.isAbbreviated, isShortened = param.isShortened, showSign = param.showSign;
    var options = {};
    if (Math.abs(value) < 1e3) isAbbreviated = false;
    if (isShortened) {
        if (isVerySmallDecimal(Math.abs(value)) && value != 0) {
            options.maximumSignificantDigits = maxCryptoSigDigsPast8Decimals;
        } else if (isVeryLargeAmount(Math.abs(value))) {
            options.maximumFractionDigits = minCryptoDecimals;
        } else if (Math.abs(value) > 1) {
            options.maximumSignificantDigits = maxCryptoSigDigs;
        } else {
            options.maximumFractionDigits = standardCryptoPrecision;
        }
    } else if (isAbbreviated) {
        options.notation = "compact";
        options.maximumSignificantDigits = cryptoSigDigsAbbreviated;
    } else {
        options.minimumSignificantDigits = minCryptoDecimals;
    }
    if (showSign) {
        options.signDisplay = "exceptZero";
    }
    return options;
};
var formatCrypto = function(value, baseCurrency) {
    var _ref = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, _ref_showSymbol = _ref.showSymbol, showSymbol = _ref_showSymbol === void 0 ? true : _ref_showSymbol, _ref_isAbbreviated = _ref.isAbbreviated, isAbbreviated = _ref_isAbbreviated === void 0 ? false : _ref_isAbbreviated, _ref_isShortened = _ref.isShortened, isShortened = _ref_isShortened === void 0 ? false : _ref_isShortened, _ref_showSign = _ref.showSign, showSign = _ref_showSign === void 0 ? false : _ref_showSign;
    if (isNull(value)) {
        return "";
    } else if (value >= infinity) {
        return "\u221E";
    } else if (value <= -infinity) {
        return "-\u221E";
    } else if (isNaN(value)) {
        value = 0;
    }
    var locale = currencySymbolSettings.USD.locale;
    var options = getCryptoFormatOptions(value, baseCurrency, {
        isAbbreviated: isAbbreviated,
        showSign: showSign,
        isShortened: isShortened
    });
    var formattedNumber = new Intl.NumberFormat(locale, options).format(value);
    if (showSymbol) {
        formattedNumber += " " + baseCurrency.symbol;
    }
    return formattedNumber;
};
var getCurrencySignFromBaseCurrency = function(baseCurrency) {
    var _parts_find;
    if (!CurrencyCode.code(baseCurrency.symbol)) {
        return baseCurrency.symbol;
    }
    var locale = getLocaleFromBaseCurrency(baseCurrency);
    var currencyFormatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: baseCurrency.symbol
    });
    var parts = currencyFormatter.formatToParts();
    var currencySign = (_parts_find = parts.find(function(part) {
        return part.type === "currency";
    })) === null || _parts_find === void 0 ? void 0 : _parts_find.value;
    if (currencySign === null) {
        return baseCurrency.symbol;
    }
    return currencySign;
};
export { formatter_exports as Formatter };
