import {
  Auth0Client,
  type Auth0ClientOptions,
  type LogoutOptions,
} from '@auth0/auth0-spa-js';
import { clearAppInfo } from '../root/appInfoContext';

export const authClientProperties: Auth0ClientOptions = {
  domain: import.meta.env.PUBLIC_AUTH0_DOMAIN,
  clientId: import.meta.env.PUBLIC_AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  authorizationParams: {
    redirect_uri: `${window.location.origin}/auth0/callback`,
    audience: 'https://enterprise.cointracker.com',
    scope: 'openid profile email offline_access',
    ct_client: 'business-web',
  },
};

export const authClient = new Auth0Client(authClientProperties);

export const logout = (options?: LogoutOptions) => {
  clearAppInfo();
  authClient.logout(options);
};
