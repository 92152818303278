import { useCallback, useState } from 'react';

import { FetchResult } from '@apollo/client';
import pako from 'pako';
import { PresignMutation, usePresignMutation } from 'src/types/graphql-types';

interface UploadFileParams {
  file: Blob;
  customPostData: { [key: string]: string };
  postUrl: string;
  onProgress?: (loaded: number, total: number) => void;
  onSuccess?: () => void;
  onFailure?: (error?: string) => void;
}

function uploadFile({
  file,
  customPostData,
  postUrl,
  onProgress,
  onSuccess,
  onFailure,
}: UploadFileParams) {
  const xhr = new XMLHttpRequest();
  xhr.open('POST', postUrl);
  const postData = new FormData();
  for (const key in customPostData) {
    postData.append(key, customPostData[key]);
  }
  postData.append('file', file);
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200 || xhr.status === 204) {
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (onFailure) {
          onFailure();
        }
      }
    }
  };
  xhr.upload.onprogress = function (evt) {
    if (onProgress) {
      onProgress(evt.loaded, evt.total);
    }
  };
  xhr.send(postData);
}

const readUploadedFileAsArrayBuffer = (
  inputFile: File,
): Promise<Uint8Array> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    fileReader.onload = () => {
      const arrayBuffer = fileReader.result;
      const compressedFile = pako.gzip(arrayBuffer);
      resolve(compressedFile);
    };
    fileReader.readAsArrayBuffer(inputFile);
  });
};

export function useFileUploader(filename?: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState<number>(0.0);
  const [error, setError] = useState<string | null>(null);

  const [presignFileUpload] = usePresignMutation({
    variables: {
      filename,
    },
  });

  const uploadContents = useCallback(
    async (contents: string | File, uploadedFileName?: string) => {
      setProgress(0.0);
      setError(null);
      // presign
      let presignResult: FetchResult<PresignMutation>;
      setIsLoading(true);
      try {
        presignResult = await presignFileUpload({
          variables: {
            filename: uploadedFileName ?? filename ?? 'cointracker.csv',
          },
        });
      } catch (e) {
        setError('Failed to presign');
        setIsLoading(false);
        return null;
      }
      const { result, success, error } = presignResult.data.presignFileUpload;
      if (!success || !result) {
        setError(error);
        setIsLoading(false);
        return null;
      }

      let file: Blob;
      if (typeof contents === 'string') {
        // compress
        const compressed = pako.gzip(contents);
        file = new Blob([compressed]);
      } else {
        const compressedFile = await readUploadedFileAsArrayBuffer(contents);
        file = new Blob([compressedFile]);
      }

      // build custom post data as required by aws
      const customPostData: { [key: string]: string } = {};
      result.uploadInfoFields.forEach((field) => {
        customPostData[field.key] = field.value;
      });

      // promisify xhr upload
      return new Promise<string | null>((resolve) => {
        uploadFile({
          file,
          customPostData,
          postUrl: result.postUrl,
          onProgress: (loaded, total) => {
            setProgress(loaded / total);
          },
          onSuccess: () => {
            setError(null);
            resolve(result.fileUrl);
            setIsLoading(false);
          },
          onFailure: () => {
            setError('Failed to upload');
            resolve(null);
            setIsLoading(false);
          },
        });
      });
    },
    [presignFileUpload],
  );

  return { upload: uploadContents, progress, error, loading: isLoading };
}
