import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authClientProperties } from './client';

export const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || '/home');
  };
  const skipRedirectCallback = location.pathname.includes(
    '/accounting-integration/callback',
  );

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      skipRedirectCallback={skipRedirectCallback}
      {...authClientProperties}
    >
      {children}
    </Auth0Provider>
  );
};
