import CheckSimple from '@/assets/checkSimple.svg?react';
import CircleNotch from '@/assets/circleNotch.svg?react';
import SignExclamation from '@/assets/sign-exclamation.svg?react';
import { CostBasisContext } from '@/components/CostBasisProvider';
import { Status, ToastContext } from '@/components/ToastNotification';
import {
  QueuePriority,
  useEnqueueCostBasisMutation,
} from '@/types/graphql-types';
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Popover,
  Text,
} from '@radix-ui/themes';
import { formatDistanceToNow, isBefore, subMinutes } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useCallback, useContext, useState } from 'react';
import { IconWrapper, JobStatus, StyledTriggerContainer } from './styles';

const JobStatusIcon = ({
  isRunning,
  hasError,
  isIdle,
}: {
  isRunning: boolean;
  hasError: boolean;
  isIdle: boolean;
}) => {
  return (
    <JobStatus
      $isRunning={isRunning}
      $hasError={hasError}
      className={isRunning ? 'spin' : ''}
    >
      {isRunning && <CircleNotch data-testid="in-progress-job-status" />}
      {hasError && (
        <IconWrapper data-testid="failed-job-status">
          <SignExclamation color="var(--red-9)" />
        </IconWrapper>
      )}
      {isIdle && <CheckSimple data-testid="idle-job-status" />}
    </JobStatus>
  );
};

interface GlobalJobStatusProps {
  isCollapsed?: boolean;
}
export function GlobalJobStatus({ isCollapsed }: GlobalJobStatusProps) {
  const { isCostBasisRunning, hasError, lastSyncedAt } =
    useContext(CostBasisContext);
  const { showNotification } = useContext(ToastContext);

  const lastUpdatedAt = lastSyncedAt
    ? formatDistanceToNow(new Date(lastSyncedAt), {
        addSuffix: true,
        locale: enUS,
      })
    : '';
  const [isOpen, setIsOpen] = useState(false);

  const fiveMinutesAgo = subMinutes(new Date(), 5);
  const hasMoreThan5MinutesPassedSinceLastSync =
    lastSyncedAt && isBefore(lastSyncedAt, fiveMinutesAgo);

  const shouldAllowUserToResync =
    isCostBasisRunning && hasMoreThan5MinutesPassedSinceLastSync;

  const [mutate, { loading }] = useEnqueueCostBasisMutation({
    onCompleted: (data) => {
      setIsOpen(false);
      if (data.enqueueCostBasis.success) {
        showNotification({
          status: Status.Success,
          message: 'Enqueued portfolio and tax calculations.',
          delay: 4000,
        });
      } else {
        showNotification({
          status: Status.Error,
          message:
            'There was a problem while enqueuing portfolio and tax calculations. Please try again',
        });
      }
    },
    onError: () => {
      setIsOpen(false);
      showNotification({
        status: Status.Error,
        message:
          'There was a problem while enqueuing portfolio and tax calculations. Please try again',
      });
    },
  });

  const resync = useCallback(() => {
    mutate({ variables: { priority: QueuePriority.High } });
  }, [mutate]);

  const onTogglePopover = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const isIdle = !isCostBasisRunning && !hasError;

  return (
    <Popover.Root open={isOpen} onOpenChange={onTogglePopover}>
      <Popover.Trigger>
        <Box px="3">
          <StyledTriggerContainer $isCollapsed={isCollapsed}>
            <JobStatusIcon
              isRunning={isCostBasisRunning}
              hasError={hasError}
              isIdle={isIdle}
            />
            {!isCollapsed && (
              <Text size="1" color="gray">
                {isCostBasisRunning ? 'Calculating' : 'All synced'}
              </Text>
            )}
          </StyledTriggerContainer>
        </Box>
      </Popover.Trigger>
      <Popover.Content onClick={onTogglePopover} side="right" sideOffset={-1}>
        <Flex direction="column" gap="1">
          <Card variant="ghost">
            <Flex direction="column" gap="2" width="400px">
              <Flex direction="row" justify="between" align="center">
                <Flex direction="row" gap="2" align="center">
                  <JobStatusIcon
                    isRunning={isCostBasisRunning}
                    hasError={hasError}
                    isIdle={isIdle}
                  />
                  <Heading size="3">Cost basis calculations</Heading>
                </Flex>
                {isIdle && (
                  <Text size="1" color="gray">
                    {lastSyncedAt ? lastUpdatedAt : 'now'}
                  </Text>
                )}
              </Flex>
              <Flex direction="row" mx="2">
                {hasError && (
                  <Text size="1" color="gray">
                    Your cost basis calculations went unexpectingly wrong.
                    Please contact support.
                  </Text>
                )}
                {isCostBasisRunning && (
                  <Text size="1" color="gray">
                    Gains and losses, and transaction errors will be available
                    soon.
                  </Text>
                )}
              </Flex>
              {shouldAllowUserToResync && (
                <Flex direction="row" gap="2" mx="2" my="2">
                  <Text size="1" color="gray">
                    If your calculations take too long, you can try rescheduling
                    it.
                  </Text>
                  <Button onClick={resync} disabled={loading} size="1">
                    Retry
                  </Button>
                </Flex>
              )}
            </Flex>
          </Card>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}
