import CSV_ICON from 'src/assets/csv-icon.svg?url';
import EXCEL_ICON from 'src/assets/xls-icon.svg?url';
import ZIP_ICON from 'src/assets/zip-icon.svg?url';
import { FileFormats } from 'src/types/graphql-types';

export { CSV_ICON };

export const uploadStatusString = (
  isUploading: boolean,
  uploadProgress: number,
  isSubmitting: boolean,
  errorText: string,
) => {
  if (isUploading) {
    return `${uploadProgress * 100}%`;
  }

  if (isSubmitting) {
    return `Processing...this may take a few minutes`;
  }

  if (errorText) {
    return 'Error uploading';
  }

  return 'Ready to import';
};

export const getFileExtensions = (fileFormats: FileFormats[]) => {
  if (!fileFormats || fileFormats.length === 0) {
    return '.csv, .xls, .xlsx';
  }

  let acceptableFileFormats = '';
  fileFormats.map((fileFormat) => {
    acceptableFileFormats += '.' + fileFormat.toString().toLowerCase() + ', ';
  });
  return acceptableFileFormats;
};

export const getPrimaryFileFormatIconURL = (fileFormats: FileFormats[]) => {
  if (!fileFormats || fileFormats.length === 0) {
    return CSV_ICON;
  }
  const primaryFileFormat = fileFormats[0];
  switch (primaryFileFormat) {
    case FileFormats.Csv:
      return CSV_ICON;
    case FileFormats.Xls || FileFormats.Xlsx:
      return EXCEL_ICON;
    case FileFormats.Zip:
      return ZIP_ICON;
    default:
      return CSV_ICON;
  }
};
