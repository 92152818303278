import { ReactNode } from 'react';
import { IntegrationSyncMethod } from 'src/common/Integration';
import { Integration } from 'src/types/graphql-types';
import FileAPISwitcher, { APITabs } from '../FileAPISwitcher';
import { FileImportForm } from '../FileImportForm';
import {
  FormContent,
  InstructionContainer,
  ViewPermissions,
} from '../FormBaseComponents';
import { Instructions } from '../Instructions';

import { Flex, Tabs } from '@radix-ui/themes';

interface ExchangeIntegrationFormWrapperProps {
  integration: Integration;
  children: ReactNode;
  onCloseModal?: () => void;
}

export default function ExchangeIntegrationFormWrapper({
  integration,
  children,
  onCloseModal,
}: ExchangeIntegrationFormWrapperProps) {
  return (
    <FormContent>
      <FileAPISwitcher
        hasCustomFileImporter={
          integration.syncMethod === IntegrationSyncMethod.AutoSyncAndFile
        }
      >
        <Tabs.Content value={APITabs.AutomaticSync}>
          <Flex direction="row" gap="4" pt="4">
            <Flex direction="column" width="50%" gap="4">
              <ViewPermissions />
              {children}
            </Flex>
            <Flex direction="column" width="50%">
              <InstructionContainer>
                <Instructions
                  integration={integration}
                  showCSVInstructions={false}
                />
              </InstructionContainer>
            </Flex>
          </Flex>
        </Tabs.Content>
        <Tabs.Content value={APITabs.CSVImport}>
          <FileImportForm
            integration={integration}
            onCloseModal={onCloseModal}
          />
        </Tabs.Content>
      </FileAPISwitcher>
    </FormContent>
  );
}
