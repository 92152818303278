import { Status, ToastContext } from '@/components/ToastNotification';
import {
  EnterpriseJob,
  JobResult,
  PeriodStatus,
  useChangePeriodStatusMutation,
  useGetJobsQuery,
  useSyncJournalEntriesMutation,
} from '@/types/graphql-types';
import { useApolloClient } from '@apollo/client';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getPeriods } from '../../pages/journal-entries/queries/getPeriods.graphql';
import { isJobRunning } from './utils';

export type EnterpriseJobContextType = {
  isChangingPeriodStatus: boolean;
  isSyncingJournalEntries: boolean;
  changePeriodStatus: ReturnType<typeof useChangePeriodStatusMutation>[0];
  syncJournalEntries: ReturnType<typeof useSyncJournalEntriesMutation>[0];
  syncingJournalEntriesJob: JobResult | undefined;
  changePeriodStatusJob: JobResult | undefined;
};

export const EnterpriseJobContext = createContext<EnterpriseJobContextType>({
  isChangingPeriodStatus: false,
  isSyncingJournalEntries: false,
  changePeriodStatus: () =>
    Promise.resolve({ data: undefined, errors: undefined }),
  syncJournalEntries: () =>
    Promise.resolve({ data: undefined, errors: undefined }),
  syncingJournalEntriesJob: undefined,
  changePeriodStatusJob: undefined,
});

export const EnterpriseJobProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { showNotification } = useContext(ToastContext);
  const [shouldPollJobs, setShouldPollJobs] = useState(false);
  const {
    data: jobsData,
    loading: jobsLoading,
    refetch: refetchJobs,
    startPolling,
    stopPolling,
  } = useGetJobsQuery({
    onCompleted: (data) => {
      if (data?.getJobs) {
        const isSomeJobRunning = data?.getJobs?.some((job) =>
          isJobRunning(job),
        );
        // Stop polling when there are no jobs running
        if (shouldPollJobs && !isSomeJobRunning) {
          setShouldPollJobs(false);
        }
        if (!shouldPollJobs && isSomeJobRunning) {
          setShouldPollJobs(true);
        }
      }
    },
  });
  const apolloClient = useApolloClient();

  useEffect(() => {
    const POLL_INTERVAL = 5000;
    if (shouldPollJobs) {
      refetchJobs();
      startPolling(POLL_INTERVAL);
    } else {
      stopPolling();
      apolloClient.refetchQueries({
        include: [getPeriods],
      });
    }
    return () => {
      stopPolling();
    };
  }, [apolloClient, refetchJobs, shouldPollJobs, startPolling, stopPolling]);

  const [syncJournalEntries, { loading: syncJournalEntriesLoading }] =
    useSyncJournalEntriesMutation({
      onCompleted: (data) => {
        if (data.syncJournalEntries?.success) {
          setShouldPollJobs(true);
          showNotification({
            message: 'Journal entries syncing started!',
            status: Status.Success,
          });
        } else {
          showNotification({
            message: `Error: ${data.syncJournalEntries?.error}, Message: ${data.syncJournalEntries?.message}`,
            title: 'Error starting journal entries sync!',
            status: Status.Error,
          });
        }
      },
    });

  const [changePeriodStatus, { loading: changePeriodStatusLoading }] =
    useChangePeriodStatusMutation({
      onCompleted: (data, clientOptions) => {
        if (data.changePeriodStatus?.success) {
          const variables = clientOptions?.variables;
          const attemptingToLockPeriod =
            variables?.input?.status === PeriodStatus.Closing;
          setShouldPollJobs(true);
          showNotification({
            message: `Enqueued period for ${attemptingToLockPeriod ? 'locking' : 'closing'}!`,
            status: Status.Success,
          });
        } else {
          showNotification({
            message: `Error: ${data.changePeriodStatus?.error}`,
            title: 'Failed to enqueue closing period!',
            status: Status.Success,
          });
        }
      },
    });

  const isChangingPeriodJobRunning = useMemo(() => {
    return jobsData?.getJobs.some(
      (job) =>
        job.type === EnterpriseJob.ChangePeriodStatus && isJobRunning(job),
    );
  }, [jobsData]);

  const isChangingPeriodStatus =
    isChangingPeriodJobRunning || changePeriodStatusLoading || jobsLoading;

  const isSyncingJournalEntriesJobRunning = useMemo(() => {
    return jobsData?.getJobs.some(
      (job) =>
        job.type === EnterpriseJob.SyncJournalEntries && isJobRunning(job),
    );
  }, [jobsData]);

  const isSyncingJournalEntries =
    isSyncingJournalEntriesJobRunning ||
    syncJournalEntriesLoading ||
    jobsLoading;

  const syncingJournalEntriesJob = jobsData?.getJobs.find(
    (job) => job.type === EnterpriseJob.SyncJournalEntries,
  );

  const changePeriodStatusJob = jobsData?.getJobs.find(
    (job) => job.type === EnterpriseJob.ChangePeriodStatus,
  );

  return (
    <EnterpriseJobContext.Provider
      value={{
        isChangingPeriodStatus,
        isSyncingJournalEntries,
        changePeriodStatus,
        syncJournalEntries,
        syncingJournalEntriesJob,
        changePeriodStatusJob,
      }}
    >
      {children}
    </EnterpriseJobContext.Provider>
  );
};
