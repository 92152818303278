import React from 'react';
import { AutocompleteSuffix } from 'src/deprecated-components/Autocomplete/styles';
import { SearchCurrencyResultItem } from 'src/deprecated-components/CurrencySelector/hooks/useSearchCurrencies';
import { InputLabel } from 'src/deprecated-components/typography/input-texts';
import { Integration } from 'src/types/graphql-types';

export function getSearchCurrencyResultItemFromIntegration(
  integration: Integration,
): SearchCurrencyResultItem {
  const rank = integration.rank ? integration.rank : 0;
  return {
    isCrypto: true,
    isFiat: false,
    isUserAsset: false,
    id: rank.toString(),
    uaid: '',
    label: (
      <InputLabel>
        {integration.name}
        <AutocompleteSuffix> {integration.info?.symbol}</AutocompleteSuffix>
      </InputLabel>
    ),
    value: integration.info?.symbol,
    avatar: {
      icon: integration.imageUrl,
      name: integration.name,
    },
  };
}
