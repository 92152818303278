import { Callout, Link } from '@radix-ui/themes';
import { Info } from 'lucide-react';
interface DuplicateTransactionsCalloutProps {
  integrationName: string;
}

export const DuplicateTransactionsCallout = ({
  integrationName,
}: DuplicateTransactionsCalloutProps) => {
  return (
    <Callout.Root size="1" mb="2">
      <Callout.Icon>
        <Info size="16px" />
      </Callout.Icon>
      <Callout.Text>
        Importing additional {integrationName} transactions could result in
        duplicates. Please review our guide on handling duplicate transactions{' '}
        <Link
          href="https://support.cointracker.io/hc/en-us/articles/23443618413969-Importing-transactions-into-a-synced-exchange-or-wallet"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </Link>
        .
      </Callout.Text>
    </Callout.Root>
    // <DuplicatesCalloutContainer>
    //   <Callout>
    //     <div>
    //       Importing additional {integrationName} transactions could result in
    //       duplicates. Please review our guide on handling duplicate transactions{' '}
    //       <a
    //         href="https://support.cointracker.io/hc/en-us/articles/23443618413969-Importing-transactions-into-a-synced-exchange-or-wallet"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         here
    //       </a>
    //       .
    //     </div>
    //   </Callout>
    // </DuplicatesCalloutContainer>
  );
};
