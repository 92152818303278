import { isEmpty } from 'lodash';
import { createContext, useState } from 'react';
import { v1 as uuid } from 'uuid';
import { NotificationProps, Toast } from './Notification';

export interface ToastContextType {
  showNotification: (notificationProps: NotificationProps) => void;
  updateNotification: (notificationProps: NotificationProps) => void;
  removeNotification: (notificationId: string) => void;
}

export const ToastContext = createContext<ToastContextType>({
  showNotification: () => {},
  updateNotification: () => {},
  removeNotification: () => {},
});

interface NotificationsProps {
  children?: React.ReactNode;
}
export const Notifications = ({ children }: NotificationsProps) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const context = {
    showNotification: (notification) => {
      const newNotification = { ...notification, id: uuid() };
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        newNotification,
      ]);
      return newNotification;
    },
    updateNotification: (updatedNotification) => {
      setNotifications((prevNotifications) => {
        if (!prevNotifications || isEmpty(prevNotifications)) {
          return [];
        }
        return prevNotifications.map((notification) => {
          if (notification.id === updatedNotification.id) {
            return updatedNotification;
          }
          return notification;
        });
      });
    },
    removeNotification: (notificationId) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.id !== notificationId,
        ),
      );
    },
  };
  return (
    <ToastContext.Provider value={context}>
      {children}
      {notifications.map((toastProps) => (
        <Toast key={toastProps.id} {...toastProps} />
      ))}
    </ToastContext.Provider>
  );
};
