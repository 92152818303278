import { Button, Flex, Heading, Text } from '@radix-ui/themes';
import React from 'react';
import IllustrationAddedWallet from 'src/assets/illustration-wallet-added.png?url';
import { IntegrationSyncMethod } from 'src/common/Integration';
import { CSV_ICON } from 'src/deprecated-components/FileInput/utils';
import { Integration } from 'src/types/graphql-types';

export const CSVIntegration: Integration = {
  slug: 'csv file import',
  name: 'CSV',
  rank: 0,
  info: {
    circleIconUrl: CSV_ICON,
  },
  category: null,
  syncMethod: IntegrationSyncMethod.CoinTrackerCSV,
};

interface BlankSearchResultsProps {
  setSelectedIntegration: React.Dispatch<React.SetStateAction<Integration>>;
}

export function BlankSearchResults(props: BlankSearchResultsProps) {
  return (
    <Flex direction="column" justify="center" align="center" gap="5">
      <img
        src={IllustrationAddedWallet}
        width="260px"
        height="216px"
        alt="No results found"
      />
      <Heading>No results found</Heading>
      <Text size="3" color="gray">
        We haven&apos;t found the integration you&apos;re looking for. You can
        upload a CSV file instead.
      </Text>
      <Button
        onClick={() => props.setSelectedIntegration(CSVIntegration)}
        size="3"
      >
        Upload CSV
      </Button>
    </Flex>
  );
}
