import { useGetAppInfo } from '@/app/root/appInfoContext';
import { useGetUser } from '@/app/user';
import { CostBasisProvider } from '@/components/CostBasisProvider';
import { EnterpriseJobProvider } from '@/components/EnterpriseJobProvider';
import usePrevious from '@/hooks/usePrevious';
import { AddWalletFlowProvider } from '@/pages/wallets/WalletsHeader/AddWalletButton/AddWalletModal/context';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SideBarWithTopBarLayout } from './SideBarWithTopBarLayout';

const Layout = () => {
  const { user } = useGetUser();
  const { appInfo, isLoading: appInfoLoading } = useGetAppInfo();
  const location = useLocation();
  const navigate = useNavigate();
  const previousLocation: Location = usePrevious(location);
  useEffect(() => {
    // in some nested route cases where we generate more than 1 route per page, we don't want the page to scroll to top
    // when navigating inside the nested route
    if (location.state?.keepScrollPosition) {
      return;
    }
    // When selecting a filter in the transactions page, the page jumps to 0,0 because the location object changed
    // This check will prevent the jumping of tags https://linear.app/cointracker/issue/PPP-354/page-jumps-down-when-a-tag-is-selected
    // We haven't included this check for every page to be in the safe side, but we might introduce this for every page soon.
    // Ping PPP if you want to introduce your path here.
    if (
      previousLocation &&
      location.pathname.endsWith('/transactions') &&
      previousLocation.pathname.endsWith('/transactions')
    ) {
      return;
    }
    window.scrollTo(0, 0);
  }, [location, previousLocation]);

  useEffect(() => {
    if (user.isFetching) return;

    if (!appInfoLoading && !appInfo?.email) {
      // Redirect to the error page if user does not have email
      return navigate('/error/auth');
    }

    if (!appInfoLoading && !appInfo?.orgId) {
      // Redirect to the error page if user does not have org
      return navigate('/error/no-org');
    }
  }, [appInfo, appInfoLoading, navigate, user]);

  return (
    <CostBasisProvider>
      <AddWalletFlowProvider>
        <SideBarWithTopBarLayout isAuthenticated={user.isAuthenticated}>
          <EnterpriseJobProvider>
            <Outlet />
          </EnterpriseJobProvider>
        </SideBarWithTopBarLayout>
      </AddWalletFlowProvider>
    </CostBasisProvider>
  );
};

export const DashboardAppLayout = withAuthenticationRequired(Layout);
export default DashboardAppLayout;
