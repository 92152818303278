import { useCallback, useState } from 'react';
import {
  GetJobStatusQuery,
  Integration,
  useCreateCustomWalletMutation,
  useGetJobStatusQuery,
} from 'src/types/graphql-types';

interface useCreateWalletProps {
  onCompleted: (data: GetJobStatusQuery) => void;
  onError: (error: string) => void;
}

interface createWalletProps {
  walletName: string;
  fileUrl: string;
  integration: Integration;
}

const IMPORT_ERROR = 'There was an error while trying to import the CSV';
const UPLOAD_ERROR =
  'There was an issue while trying to upload your csv. Please try again';

export default function useCreateWallet({
  onCompleted = () => {},
  onError = () => {},
}: useCreateWalletProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [createWallet] = useCreateCustomWalletMutation();
  const [createdWalletJobId, setCreatedWalletJobId] = useState<string>();

  const { stopPolling } = useGetJobStatusQuery({
    skip: !createdWalletJobId,
    pollInterval: 1000,
    variables: {
      jobId: createdWalletJobId,
    },
    // Force Apollo Client to fetch data from the server instead of using the cached data from the previous job
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data.csvImportJobStatus.isStatusFinal) {
        return;
      }
      stopPolling();
      setIsLoading(false);
      if (data.csvImportJobStatus.success) {
        onCompleted(data);
      } else {
        setError(data.csvImportJobStatus.errorMessage ?? IMPORT_ERROR);
        onError(data.csvImportJobStatus.errorMessage ?? IMPORT_ERROR);
      }
    },
    onError: () => {
      stopPolling();
    },
  });

  const createWalletFunction = useCallback(
    async ({ walletName, fileUrl, integration }: createWalletProps) => {
      try {
        const response = await createWallet({
          variables: {
            customWalletInput: {
              walletName,
              csvUrl: fileUrl,
              accountTypeSlug: integration.slug,
            },
          },
        });
        if (response.data.createCustomWallet.success) {
          setCreatedWalletJobId(response.data.createCustomWallet.jobId);
        } else {
          setError(UPLOAD_ERROR);
          onError(UPLOAD_ERROR);
        }
      } catch (e) {
        setError(UPLOAD_ERROR);
        onError(UPLOAD_ERROR);
      }
    },
    [createWallet, onError, setError],
  );

  return { createWallet: createWalletFunction, loading: isLoading, error };
}
