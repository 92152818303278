import { gql } from '@apollo/client';

export const getPeriods = gql`
  query GetPeriods($limit: Int, $offset: Int) {
    periods(limit: $limit, offset: $offset) {
      page {
        objects {
          id
          name
          status
          startDate
          endDate
        }
      }
      pageInfo {
        total
        page
        pages
        perPage
        hasNext
        hasPrev
      }
    }
  }
`;
