import config from 'src/deprecated-components/Grid/config';
import { RegularPrimary } from 'src/deprecated-components/buttons/regular';
import styled from 'styled-components';
import { ModalContent } from '../styles';

export const SelectedIntegrationModalContent = styled(ModalContent)`
  @media (min-width: ${config.breakpoints.sm}) {
    max-height: 540px;
    overflow-y: auto;
    height: unset;
  }
`;

export const InstructionContainer = styled.div<{
  $marginTop?: number;
  $hideBackground?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ $marginTop }) => $marginTop ?? '32'}px;
  border-radius: var(--border-radius-medium, 8px);
  background: ${({ $hideBackground }) =>
    !$hideBackground && 'var(--neutral-200)'};
  padding: 16px;
`;

export const Spacer = styled.div<{ $height?: number }>`
  ${({ $height }) => $height && `height: ${$height}px;`}
`;

export const FormContent = styled.div`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  margin-bottom: 10px;
`;

export const SubmitButton = styled(RegularPrimary)`
  width: 100%;
`;

export const FormError = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  gap: 10px;
`;

export const TabsContentContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const TabsContentWrapper = styled.div`
  width: 50%;
`;
