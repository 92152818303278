import styled from 'styled-components';

export const InstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-medium, 8px);
  background: var(--neutral-200);
  padding: 16px;
`;

export const FormContent = styled.div`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
`;

export const CalloutContainer = styled.div`
  margin: 16px 0px 16px 0px;
`;

export const CoverageInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  div > div.alert {
    padding: 16px;
    border-radius: 4px;
    color: ${({ theme }) => theme.red.clicked};
    background: ${({ theme }) => theme.tint.red};
  }
`;

export const FileImportFormContainer = styled.div`
  display: flex;
  gap: 16px;
`;
