import { Status, ToastContext } from '@/components/ToastNotification';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import {
  ExchangeMutationErrors,
  GetWalletsForWalletListQuery,
  Integration,
  useAddExchangeWithApiAndSecretKeyMutation,
  useUpdateExchangeWithApiAndSecretKeyMutation,
} from 'src/types/graphql-types';
import {
  Banner,
  FormContent,
  SubmitButton,
  TextFormField,
} from '../FormBaseComponents';

import { GetWallets } from 'src/pages/wallets/queries/getWallets.graphql';
import { AddWalletContext } from '../../context';
import { CoverageInfoContainer } from '../FileImportForm/styles';
import { emptyCacheQuery, formatAPISecretLabel } from '../util';
import { ValidationErrors, isStringValid, validate } from './validate';

interface ApiKeyAndSecretFormProps {
  integration: Integration;
  isEditMode?: boolean;
  exchangeId?: string;
}

export const GENERIC_ERROR_MESSAGE =
  'There was a problem while trying to add your exchange. Try again or contact support';

export const ApiKeyAndSecretForm = (props: ApiKeyAndSecretFormProps) => {
  const integration = props.integration;
  const syncIntegrationCoverageInfo =
    integration?.info?.syncIntegrationCoverageInfo;

  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [errorText, setErrorText] = useState('');
  const [errors, setErrors] = useState<ValidationErrors>({});

  const { showNotification } = useContext(ToastContext);
  const { onCloseAddWalletModal, onSuccess } = useContext(AddWalletContext);
  const [addExchange, { loading }] = useAddExchangeWithApiAndSecretKeyMutation({
    onCompleted: (data) => {
      if (data.addExchangeWithApiKeyAndSecret.success) {
        showNotification({
          status: Status.Success,
          message: `Added new exchange`,
        });
        onSuccess(data.addExchangeWithApiKeyAndSecret.createdExchange);
        onCloseAddWalletModal();
        return;
      }

      if (
        data.addExchangeWithApiKeyAndSecret.error ===
        ExchangeMutationErrors.AccountAlreadyLinked
      ) {
        setErrorText('Account is already linked');
      } else if (
        data.addExchangeWithApiKeyAndSecret.error ===
        ExchangeMutationErrors.InvalidAccount
      ) {
        setErrorText('Account is invalid');
      } else if (
        data.addExchangeWithApiKeyAndSecret.error ===
        ExchangeMutationErrors.InvalidApiKey
      ) {
        setErrorText('API key is invalid');
      } else {
        setErrorText(GENERIC_ERROR_MESSAGE);
      }
    },
    update: (cache, { data }) => {
      if (!data.addExchangeWithApiKeyAndSecret.success) {
        return;
      }
      const walletsData: GetWalletsForWalletListQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      const newExchanges = [
        ...walletsData.exchanges,
        data.addExchangeWithApiKeyAndSecret.createdExchange,
      ];

      cache.writeQuery({
        query: GetWallets,
        data: { ...walletsData, exchanges: newExchanges },
      });
    },
    onError: () => {
      setErrorText(GENERIC_ERROR_MESSAGE);
    },
  });

  const [updateExchange, { loading: updateLoading }] =
    useUpdateExchangeWithApiAndSecretKeyMutation({
      onCompleted: (data) => {
        if (data.updateExchangeWithApiKeyAndSecret.success) {
          showNotification({
            status: Status.Success,
            message: `Updated exchange details`,
          });
          onSuccess(data.updateExchangeWithApiKeyAndSecret.updatedExchange);
          onCloseAddWalletModal();
          return;
        }

        if (
          data.updateExchangeWithApiKeyAndSecret.error ===
          ExchangeMutationErrors.AccountAlreadyLinked
        ) {
          setErrorText('Account is already linked');
        } else if (
          data.updateExchangeWithApiKeyAndSecret.error ===
          ExchangeMutationErrors.InvalidAccount
        ) {
          setErrorText('Account is invalid');
        } else if (
          data.updateExchangeWithApiKeyAndSecret.error ===
          ExchangeMutationErrors.InvalidApiKey
        ) {
          setErrorText('API key is invalid');
        } else {
          setErrorText(GENERIC_ERROR_MESSAGE);
        }
      },
      update: (cache, { data }) => {
        if (!data.updateExchangeWithApiKeyAndSecret.success) {
          return;
        }
        const walletsData: GetWalletsForWalletListQuery =
          cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
        const newExchanges = walletsData.exchanges.map((exchange) => {
          if (
            exchange.id ===
            data.updateExchangeWithApiKeyAndSecret.updatedExchange.id
          ) {
            return data.updateExchangeWithApiKeyAndSecret.updatedExchange;
          }
          return exchange;
        });

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, exchanges: newExchanges },
        });
      },
      onError: () => {
        setErrorText(GENERIC_ERROR_MESSAGE);
      },
    });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const apiKey = formData.get('apiKey') as string;
      const apiSecret = formData.get('apiSecret') as string;

      const errors = validate({ apiSecret, apiKey });
      if (!isEmpty(errors)) {
        setErrors(errors);
        return;
      }

      if (props.isEditMode && props.exchangeId) {
        updateExchange({
          variables: {
            updateExchangeInput: {
              apiKey,
              apiSecret,
              publicId: props.exchangeId,
            },
          },
        });
      } else {
        addExchange({
          variables: {
            addExchangeInput: {
              apiKey,
              apiSecret,
              accountTypeSlug: integration.slug,
            },
          },
        });
      }
    },
    [
      integration,
      addExchange,
      updateExchange,
      props.isEditMode,
      props.exchangeId,
    ],
  );

  const hasApiKeyError = errors.apiKeyMissing;
  const hasApiKeySecretError = errors.apiSecretMissing;

  const isFormInvalid = !!errorText || hasApiKeyError || hasApiKeySecretError;

  const onApiKeyChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setApiKey(e.target.value);
      setErrors({ ...errors, apiKeyMissing: !isStringValid(e.target.value) });
    },
    [errors],
  );

  const onApiKeySecret = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setApiSecret(e.target.value);
      setErrors({
        ...errors,
        apiSecretMissing: !isStringValid(e.target.value),
      });
    },
    [errors],
  );

  const isLoading = loading || updateLoading;

  return (
    <FormContent gap="2" asChild>
      <form onSubmit={onSubmit}>
        <TextFormField
          name="apiKey"
          description="API Key"
          errors={hasApiKeyError ? ['Field is required'] : []}
          onChange={onApiKeyChange}
          value={apiKey}
        />
        <TextFormField
          name="apiSecret"
          description={formatAPISecretLabel(integration.slug)}
          errors={hasApiKeySecretError ? ['Field is required'] : []}
          onChange={onApiKeySecret}
          value={apiSecret}
        />
        <SubmitButton type="submit" disabled={isFormInvalid || isLoading}>
          {isLoading ? 'Please wait...' : `Add ${integration.name}`}
        </SubmitButton>
        {errorText && (
          <Banner type="error" onClose={() => setErrorText('')}>
            {errorText}
          </Banner>
        )}
        {syncIntegrationCoverageInfo && (
          <CoverageInfoContainer
            dangerouslySetInnerHTML={{ __html: syncIntegrationCoverageInfo }}
          />
        )}
      </form>
    </FormContent>
  );
};
