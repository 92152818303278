import { default as React, useCallback } from 'react';
import Autocomplete, {
  AutocompleteItem,
} from 'src/deprecated-components/Autocomplete';
import { SearchCurrencyResultItem } from 'src/deprecated-components/CurrencySelector/hooks/useSearchCurrencies';
import { ErrorMessage } from 'src/deprecated-components/Input/styles';

interface CurrencySelectorWithOptionsProps {
  onSelect?: (item: SearchCurrencyResultItem) => void;
  label?: string;
  defaultValue?: string;
  hasError?: boolean;
  hint?: string;
  placeholder?: string;
  currencyOptions?: SearchCurrencyResultItem[];
  prefix?: React.ReactNode;
}

/*
  CurrencySelectorWithOptions varies from the CurrencySelector in that it allows for a options data to be passed in, rather than fetching it itself. It should be used when the data is already available, such as in the case of the AddWalletModal.
*/
export default function CurrencySelectorWithOptions({
  onSelect = () => {},
  label = '',
  defaultValue,
  hasError,
  hint,
  placeholder,
  currencyOptions,
  prefix,
}: CurrencySelectorWithOptionsProps) {
  const getFilteredItems = useCallback(
    (items: AutocompleteItem[], inputValue = '') => {
      return items.filter((item) => {
        return (
          item.avatar.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.value.toLowerCase().includes(inputValue.toLowerCase())
        );
      });
    },
    [],
  );

  const onChange = useCallback(
    (val: string) => {
      // clear selection if resetting input field
      if (val === '') {
        onSelect({
          avatar: { name: '', icon: '' },
          value: '',
          label: '',
          id: '-1',
          uaid: '',
        });
      }
    },
    [onSelect],
  );

  return (
    <div>
      <Autocomplete
        label={label}
        items={currencyOptions || []}
        filterFunction={getFilteredItems}
        onChange={onChange}
        onSelect={onSelect}
        defaultValue={defaultValue}
        placeholder={placeholder || 'Select your blockchain'}
        hasError={hasError}
        prefix={prefix}
      />
      {hasError && hint && <ErrorMessage>{hint}</ErrorMessage>}
    </div>
  );
}
