import React, { TextareaHTMLAttributes } from 'react';
import { ErrorMessage, StyledTextarea } from './styles';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasError?: boolean;
  hint?: string;
}
export default function Textarea({
  hasError,
  hint,
  ...restProps
}: TextareaProps) {
  return (
    <>
      <StyledTextarea {...restProps} $hasError={hasError} />
      {hasError && hint && <ErrorMessage>{hint}</ErrorMessage>}
    </>
  );
}
