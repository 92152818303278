import { Helmet as ReactHelmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const META_DESCRIPTION =
  'CoinTracker Enterprise is the most trusted institution-grade accounting, audit and tax software for your digital assets.';

const capitalizeWords = (str) => {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export default function Helmet() {
  const location = useLocation();
  const firstPartOfPathname = location.pathname.split('/')[1];

  // Capitalize the first character of each word and concatenate together
  const capitalizedFirstPartOfPathname = firstPartOfPathname
    ? capitalizeWords(firstPartOfPathname)
    : 'Home';
  return (
    <ReactHelmet>
      <title>{`${capitalizedFirstPartOfPathname} | CoinTracker Enterprise`}</title>
      <meta name="description" content={META_DESCRIPTION} />
    </ReactHelmet>
  );
}
