import { Heading } from '@radix-ui/themes';
import { Integration } from 'src/types/graphql-types';
import { InstructionItem } from './styles';

interface DefaultGenericInstructionsProps {
  integration: Integration;
}
export const DefaultGenericInstructions = ({
  integration,
}: DefaultGenericInstructionsProps) => {
  return (
    <>
      <Heading size="3">How to add your {integration.name} wallet</Heading>
      <InstructionItem>Paste your public address</InstructionItem>
      <InstructionItem>{`Click "Add ${integration.name}"`}</InstructionItem>
    </>
  );
};
