import React, { forwardRef, InputHTMLAttributes, ReactNode, Ref } from 'react';
import {
  ContainerWrapper,
  ErrorMessage,
  InputContainer,
  PrefixIcon,
  StyledInput,
  SuffixIcon,
} from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  prefixIcon?: ReactNode;
  suffixIcon?: ReactNode;
  hasError?: boolean;
  hint?: string;
  className?: string;
}
export const Input = forwardRef(
  (
    {
      prefixIcon,
      suffixIcon,
      hasError,
      hint,
      disabled,
      className,
      ...restProps
    }: InputProps,
    forwardedRef: Ref<HTMLInputElement>,
  ) => {
    return (
      <ContainerWrapper>
        <InputContainer
          disabled={disabled}
          $hasError={hasError}
          className={className}
        >
          {prefixIcon && <PrefixIcon>{prefixIcon}</PrefixIcon>}
          <StyledInput {...restProps} ref={forwardedRef} />
          {suffixIcon && <SuffixIcon>{suffixIcon}</SuffixIcon>}
        </InputContainer>
        {hasError && hint && <ErrorMessage>{hint}</ErrorMessage>}
      </ContainerWrapper>
    );
  },
);

Input.displayName = 'Input';

export default Input;
