import * as ToastPrimitive from '@radix-ui/react-toast';
import { Flex, Heading, IconButton, Text } from '@radix-ui/themes';
import { CircleAlert, CircleCheck, X } from 'lucide-react';
import { NotificationContainer } from './Notification.styles';

export enum Status {
  Info = 1,
  Error,
  Success,
}

const StatusIcon = ({ status }: { status: Status }) => {
  switch (status) {
    case Status.Info:
      return (
        <IconButton size="1" variant="soft" radius="full">
          <CircleAlert height="16px" width="16px" />
        </IconButton>
      );
    case Status.Error:
      return (
        <IconButton size="1" color="red" variant="soft" radius="full">
          <CircleAlert height="16px" width="16px" />
        </IconButton>
      );
    case Status.Success:
    default:
      return (
        <IconButton size="1" color="green" variant="soft" radius="full">
          <CircleCheck height="16px" width="16px" />
        </IconButton>
      );
  }
};

export interface NotificationProps {
  id?: string;
  title?: string;
  message?: string;
  status: Status;
  delay?: number; // The time in ms to show the notification. Set to -1 to never automatically hide.
  duration?: number; // Same as delay, some older compoennts use the name delay
  isClosable?: boolean;
}

type ToastProps = NotificationProps & ToastPrimitive.ToastProps;
export const Toast = ({
  title,
  message,
  status,
  isClosable = true,
  delay,
  duration,
  ...toastRootProps
}: ToastProps) => {
  return (
    <ToastPrimitive.Root
      {...toastRootProps}
      duration={delay || duration}
      asChild
    >
      <NotificationContainer
        direction="row"
        gap="4"
        justify="between"
        align="center"
        p="4"
      >
        <Flex direction="row" align="center" justify="center" gap="2">
          <StatusIcon status={status} />
          <Flex direction="column">
            {title && (
              <ToastPrimitive.Title>
                <Heading size="3">{title}</Heading>
              </ToastPrimitive.Title>
            )}
            {message && (
              <ToastPrimitive.Description>
                <Text size="2">{message}</Text>
              </ToastPrimitive.Description>
            )}
          </Flex>
        </Flex>

        {isClosable && (
          <ToastPrimitive.Close aria-label="Close" asChild>
            <IconButton variant="ghost">
              <X size="16" />
            </IconButton>
          </ToastPrimitive.Close>
        )}
      </NotificationContainer>
    </ToastPrimitive.Root>
  );
};
