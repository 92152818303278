import styled from 'styled-components';
import { variantStyles } from './variants';

export const LinkLarge = styled.div<{ $variant?: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.4px;
  text-decoration: none;
  cursor: pointer;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}
`;

export const LinkBig = styled.div<{ $variant?: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-decoration: none;
  cursor: pointer;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}
`;

export const LinkMid = styled.div<{ $variant?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-decoration: none;
  cursor: pointer;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}
`;

export const LinkSmall = styled.div<{ $variant?: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-decoration: none;
  cursor: pointer;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}
`;
