import { Popover } from '@radix-ui/themes';
import { styled } from 'styled-components';

export const StyledTriggerContainer = styled.div<{ $isCollapsed?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: var(--space-2);
  align-items: center;
  padding-left: ${({ $isCollapsed }) =>
    $isCollapsed ? 'var(--space-1)' : 'var(--space-3)'};
  padding-right: ${({ $isCollapsed }) =>
    $isCollapsed ? 'var(--space-1)' : 'var(--space-3)'};
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
  border-radius: var(--radius-2);
  justify-content: ${({ $isCollapsed }) =>
    $isCollapsed ? 'center' : 'flex-start'};

  &:hover {
    background-color: var(--gray-4);
  }
`;

export const JobStatus = styled.div<{
  $isRunning?: boolean;
  $hasError?: boolean;
}>`
  width: 18px;
  height: 18px;
  background-color: ${({ $isRunning, $hasError }) => {
    if ($isRunning) {
      return 'var(--amber-6)';
    } else if ($hasError) {
      return 'var(--red-6)';
    }
    return 'var(--green-6)';
  }};

  color: ${({ $isRunning, $hasError }) => {
    if ($isRunning) {
      return 'var(--amber-10)';
    } else if ($hasError) {
      return 'var(--red-10)';
    }
    return 'var(--green-10)';
  }};

  padding: 2px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &.spin svg {
    animation: rotation 3s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const NotificationContent = styled(Popover.Content)`
  text-align: center;
`;

export const IconWrapper = styled.span`
  line-height: 1;
`;
