import { replaceWalletNameIfNeeded } from './utils';

export function formatWalletLabel(
  addressWallet: string,
  maxAddressLength = 10,
  keepFirstWord = false,
): string {
  if (!addressWallet) {
    return '';
  }
  addressWallet = replaceWalletNameIfNeeded(addressWallet);

  if (addressWallet.length <= maxAddressLength) {
    return addressWallet;
  }
  // converts names like Arbitrum 0x000000000 to Arbitrum...0000
  if (keepFirstWord) {
    const words = addressWallet.split(' ');
    if (words.length > 1) {
      const firstWord = words[0];
      const lastFiveDigits = addressWallet.slice(-5);

      return `${firstWord}...${lastFiveDigits}`;
    }
  }
  return addressWallet.slice(0, 5) + '...' + addressWallet.slice(-5);
}
