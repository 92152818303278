import { CostBasisContext } from '@/components/CostBasisProvider';
import { Status, ToastContext } from '@/components/ToastNotification';
import { ApolloError } from '@apollo/client';
import React, { useCallback, useContext, useState } from 'react';
import { useSuggestedWalletsModalStore } from 'src/pages/wallets/SuggestedWalletsModal/store';
import { GetWallets } from 'src/pages/wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetWalletsForWalletListQuery,
  Integration,
  useAddPublicAddressMutation,
} from 'src/types/graphql-types';
import { SUPPORTED_EVM_CHAIN_SLUGS } from '../../../../../SuggestedWalletsModal';
import { AddWalletContext } from '../../context';
import {
  Banner,
  FormContent,
  InstructionContainer,
  SubmitButton,
  TextFormField,
  ViewPermissions,
} from '../FormBaseComponents';
import { Instructions } from '../Instructions';
import { emptyCacheQuery, getWalletInstructions } from '../util';
import { ErrorMessage } from './ErrorMessage';
import { GenericInstructions } from './GenericInstructions';
import { ValidationErrors, validateWalletAddress } from './validate';

interface PublicAddressFormProps {
  integration: Integration;
  defaultWalletAddress?: string;
  onCloseModal?: () => void;
}

export const PublicAddressForm = (props: PublicAddressFormProps) => {
  const integration = props.integration;
  const { description, placeholder } = getWalletInstructions(
    integration.slug,
    integration.supportsHdWallet,
  );
  const [errors, setErrors] = useState<ValidationErrors | undefined>();
  const [walletAddress, setWalletAddress] = useState<string>(
    props.defaultWalletAddress || '',
  );
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    AddWalletErrors | string
  >();
  const [submitWarningMessage, setSubmitWarningMessage] = useState<string>();

  const hasFieldError = errors && errors.walletAddress !== undefined;
  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);
  const { openModal: openSuggestedWalletsModal } =
    useSuggestedWalletsModalStore();

  const onWalletAddressChange = useCallback((e) => {
    setWalletAddress(e.target.value);
    const errors = validateWalletAddress(e.target.value);
    setErrors(errors);
  }, []);

  const onValidateWalletAddress = useCallback(() => {
    const errors = validateWalletAddress(walletAddress);
    setErrors(errors);
  }, [walletAddress]);

  const [mutate, { loading }] = useAddPublicAddressMutation({
    onCompleted: (data) => {
      if (data?.addPublicAddress?.success) {
        if (data?.addPublicAddress?.warning) {
          showNotification({
            message: `If you have previously added ${integration.info.symbol} transactions manually or marked related transactions as transfers, you may now have duplicate transactions. Please review your ${integration.info.symbol} transactions and if necessary undo those changes.`,
            status: Status.Info,
          });
        }
        if (data?.addPublicAddress?.multiAddWarning) {
          showNotification({
            message: data.addPublicAddress.multiAddWarning,
            status: Status.Info,
          });
        }
        if (data?.addPublicAddress?.multiAddError) {
          showNotification({
            message: data.addPublicAddress.multiAddError,
            status: Status.Error,
          });
        }
        showNotification({
          message: data.addPublicAddress.success,
          status: Status.Success,
        });
        const createdWalletOrExchange =
          data.addPublicAddress.createdWallet ||
          data.addPublicAddress.createdExchange;
        onSuccess(createdWalletOrExchange);
        props.onCloseModal?.();
        if (
          integration?.info?.isEvmChain &&
          SUPPORTED_EVM_CHAIN_SLUGS.includes(integration.slug)
        ) {
          openSuggestedWalletsModal(walletAddress, integration);
        }
      } else {
        if (data?.addPublicAddress?.error) {
          setSubmitErrorMessage(data?.addPublicAddress?.error);
        } else if (
          data?.addPublicAddress?.multiAddError ||
          data?.addPublicAddress?.multiAddWarning
        ) {
          if (data?.addPublicAddress?.multiAddWarning) {
            setSubmitWarningMessage(data?.addPublicAddress?.multiAddWarning);
          }
          if (data?.addPublicAddress?.multiAddError) {
            setSubmitErrorMessage(data?.addPublicAddress?.multiAddError);
          }
        } else {
          setSubmitErrorMessage(AddWalletErrors.UnknownError);
        }
      }
    },
    update: (cache, { data }) => {
      if (!data.addPublicAddress.success) {
        return;
      }

      const walletsData: GetWalletsForWalletListQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      if (data.addPublicAddress.createdWallet) {
        const newLocalWallets = [
          ...walletsData.localWallets,
          data.addPublicAddress.createdWallet,
        ];

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, localWallets: newLocalWallets },
        });
      } else if (data.addPublicAddress.createdExchange) {
        const newExchanges = [
          ...walletsData.exchanges,
          data.addPublicAddress.createdExchange,
        ];

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, exchanges: newExchanges },
        });
      }
    },
    onError: (err: ApolloError) => {
      setSubmitErrorMessage(
        (err?.message as AddWalletErrors) ?? AddWalletErrors.UnknownError,
      );
    },
  });
  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      mutate({
        variables: {
          publicAddressInput: {
            coinSymbol: integration.info.symbol,
            cryptoNetwork: integration.info.cryptoNetwork,
            slug: integration.slug,
            walletAddress,
          },
        },
      });
      startFasterPollingForSyncCompletion();
    },
    [
      mutate,
      integration.info.symbol,
      integration.info.cryptoNetwork,
      integration.slug,
      walletAddress,
      startFasterPollingForSyncCompletion,
    ],
  );

  const genericInstructions = <GenericInstructions integration={integration} />;
  return (
    <>
      <FormContent asChild>
        <form onSubmit={onSubmit}>
          {submitErrorMessage && (
            <Banner type="error" onClose={() => setSubmitErrorMessage(null)}>
              <ErrorMessage error={submitErrorMessage} />
            </Banner>
          )}
          {submitWarningMessage && (
            <Banner
              type="warning"
              onClose={() => setSubmitWarningMessage(null)}
            >
              <ErrorMessage error={submitWarningMessage} />
            </Banner>
          )}
          <TextFormField
            name="wallet_address"
            value={walletAddress}
            onChange={onWalletAddressChange}
            description={description}
            placeholder={placeholder}
            errors={hasFieldError ? [errors.walletAddress] : null}
            onBlur={onValidateWalletAddress}
            autoFocus={true}
          />
          <SubmitButton
            disabled={hasFieldError || loading || !walletAddress}
            type="submit"
          >
            {loading ? 'Please wait...' : `Add ${integration.name}`}
          </SubmitButton>
        </form>
      </FormContent>
      <ViewPermissions />
      <InstructionContainer hideBackground>
        <Instructions
          genericInstructions={genericInstructions}
          integration={integration}
        />
      </InstructionContainer>
    </>
  );
};
