import {
  FieldMergeFunction,
  FieldPolicy,
  FieldReadFunction,
  Reference,
} from '@apollo/client';
type KeyArgs = FieldPolicy<unknown>['keyArgs'];

export const DOTTED_PAGE_NUMBER = -1;

/**
 * Generate numeric page items around current page.
 *   - Always include first and last page
 *   - Add ellipsis if needed
 *
 * Pagination algorithm taken from: https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
 */
export const generatePages = (
  current: number,
  total: number,
  delta = 2,
  gap = DOTTED_PAGE_NUMBER,
) => {
  if (total <= 1) return [1];

  const center = [current];

  for (let i = 1; i <= delta; i++) {
    center.unshift(current - i);
    center.push(current + i);
  }

  const filteredCenter = center.filter((page) => page > 1 && page < total);

  const includeLeftGap = current > 3 + delta;
  const includeLeftPages = current === 3 + delta;
  const includeRightGap = current < total - (2 + delta);
  const includeRightPages = current === total - (2 + delta);

  if (includeLeftPages) filteredCenter.unshift(2);
  if (includeRightPages) filteredCenter.push(total - 1);
  if (includeLeftGap) filteredCenter.unshift(gap);
  if (includeRightGap) filteredCenter.push(gap);

  return [1, ...filteredCenter, total];
};

export const mergeEdges: FieldMergeFunction = (
  existing,
  incoming,
  { args },
) => {
  const edges = existing?.edges;
  const incomingEdges = incoming?.edges;
  const mergedEdges = edges ? edges.slice(0) : [];

  if (incomingEdges) {
    if (args) {
      // Assume an offset of 0 if args.offset omitted.
      const { offset = 0 } = args;
      for (let i = 0; i < incomingEdges.length; ++i) {
        mergedEdges[offset + i] = incomingEdges[i];
      }
    } else {
      // It's unusual (probably a mistake) for a paginated field not
      // to receive any arguments, so you might prefer to throw an
      // exception here, instead of recovering by appending incoming
      // onto the existing array.
      mergedEdges.push(...incomingEdges);
    }
  }

  return {
    ...existing,
    ...incoming,
    edges: mergedEdges,
  };
};

export const readPageEdges: FieldReadFunction = (
  existing,
  {
    args: {
      // Default to returning the entire cached list,
      // if offset and limit are not provided.
      offset = 0,
      limit = existing?.edges?.length,
    } = {},
  },
) => {
  // A read function should always return undefined if existing is
  // undefined. Returning undefined signals that the field is
  // missing from the cache, which instructs Apollo Client to
  // fetch its value from your GraphQL server.
  if (!existing) {
    return undefined;
  }
  const edges = existing?.edges && existing.edges.slice(offset, offset + limit);
  return {
    ...existing,
    edges,
  };
};

export type TObject<TNode> = TNode | Reference;

export type TPageInfo = {
  total: number;
  page: number;
  pages: number;
  perPage: number;
  hasNext: boolean;
  hasPrev: boolean;
};

export type TExistingPage<TNode> = Readonly<{
  page: {
    objects: TObject<TNode>[];
  };
  pageInfo: TPageInfo;
}>;

export type TIncomingPage<TNode> = {
  page: {
    objects: TObject<TNode>[];
  };
  pageInfo: TPageInfo;
};

export type PageFieldPolicy<TNode> = FieldPolicy<
  TExistingPage<TNode> | null,
  TIncomingPage<TNode> | null,
  TIncomingPage<TNode> | null
>;

export function paginationPolicy<TNode extends Reference = Reference>(
  keyArgs: KeyArgs = false,
): PageFieldPolicy<TNode> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      let mergedObjects = existing?.page?.objects?.slice(0) ?? [];

      if (incoming?.page?.objects) {
        const objects = incoming.page.objects;
        if (args) {
          // Assume an offset of 0 if args.offset omitted.
          const { offset = 0 } = args;
          for (let i = 0; i < objects.length; ++i) {
            mergedObjects[offset + i] = objects[i];
          }
        } else {
          // It's unusual (probably a mistake) for a paginated field not
          // to receive any arguments, so you might prefer to throw an
          // exception here, instead of recovering by replacing existing
          // objects with incoming objects.
          mergedObjects = [...objects];
        }
      }

      return {
        page: { objects: mergedObjects },
        pageInfo: incoming?.pageInfo,
      };
    },
  };
}
