import { default as React, useCallback } from 'react';
import Avatar from 'src/deprecated-components/Avatar';
import { SearchCurrencyResultItem } from 'src/deprecated-components/CurrencySelector/hooks/useSearchCurrencies';
import { Integration } from 'src/types/graphql-types';
import CurrencySelectorWithOptions from './CurrencySelectorWithOptions';
import { getSearchCurrencyResultItemFromIntegration } from './util';

interface CryptoSelectProps {
  cryptoIntegrations: Integration[];
  selectedCrypto?: Integration;
  setSelectedCrypto?: React.Dispatch<React.SetStateAction<Integration>>;
  error?: string;
}

export const CryptoSelect = ({
  cryptoIntegrations,
  setSelectedCrypto,
  selectedCrypto,
  error,
}: CryptoSelectProps) => {
  const currencyOptions = cryptoIntegrations.map(
    getSearchCurrencyResultItemFromIntegration,
  );

  const onSelectNewCurrency = useCallback(
    (currency: SearchCurrencyResultItem) => {
      const selectedCrypto = cryptoIntegrations.find(
        (crypto) => crypto.name === currency.avatar.name,
      );
      setSelectedCrypto(selectedCrypto || null);
    },
    [setSelectedCrypto, cryptoIntegrations],
  );

  return (
    <CurrencySelectorWithOptions
      onSelect={onSelectNewCurrency}
      hasError={error && error.length > 0}
      hint={error ?? null}
      currencyOptions={currencyOptions}
      prefix={
        selectedCrypto && (
          <Avatar
            src={selectedCrypto.imageUrl}
            alt={`Icon of ${selectedCrypto.name}`}
            fallbackText={selectedCrypto.slug}
            delayMs={1000}
          />
        )
      }
      defaultValue={selectedCrypto?.name}
    />
  );
};
