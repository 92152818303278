import { Text } from '@radix-ui/themes';

interface HighlightedIntegrationNameProps {
  integrationName: string;
  searchQuery: string;
}

export const HighlightedIntegrationName = (
  props: HighlightedIntegrationNameProps,
) => {
  const integrationName = props.integrationName;
  const searchQuery = props.searchQuery.toUpperCase();
  const upperIntegration = integrationName.toUpperCase();
  if (upperIntegration.includes(searchQuery)) {
    const index = upperIntegration.indexOf(searchQuery);
    const nonHighlightStart = integrationName.substring(0, index);
    const highlight = integrationName.substring(
      index,
      index + searchQuery.length,
    );
    const nonHighlightEnd = integrationName.substring(
      index + searchQuery.length,
    );
    return (
      <Text weight="bold">
        {nonHighlightStart}
        <Text color="gray">{highlight}</Text>
        {nonHighlightEnd}
      </Text>
    );
  }
  return <Text weight="bold">{integrationName}</Text>;
};
