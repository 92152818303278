import { RegularPrimary } from 'src/deprecated-components/buttons/regular';
import styled from 'styled-components';

export const InstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const FormContent = styled.div`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
`;

export const SubmitButton = styled(RegularPrimary)`
  width: 100%;
`;

export const FileInputBox = styled.div<{ $isDraggedOver: boolean }>`
  background: ${({ $isDraggedOver }) => ($isDraggedOver ? '#CCC' : 'unset')};
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const FileBoxContainer = styled.div`
  outline: 1px dashed #ccc;
  margin-bottom: 16px;
`;

export const FileInput = styled.input`
  width: 0px;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    color: #0182ff;
    display: inline;
    margin: 0;
    cursor: pointer;
  }

  & + label:hover {
    text-decoration: underline;
  }
`;

export const FileInputLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.2px;
`;

export const FileInputInstructions = styled.div`
  font-size: 13px;
  text-align: center;
`;

export const CSVIcon = styled.img`
  width: 56px;
  height: 56px;
`;

export const DeleteIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 8px;
  margin-left: 0;
`;

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 12px;
`;

export const UploadDetails = styled.div`
  flex-grow: 1;
`;
