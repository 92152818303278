export const uploadStatusString = (
  isUploading: boolean,
  isValidating: boolean,
  isImporting: boolean,
  validationError: string | null,
  isValidationComplete: boolean,
) => {
  if (isUploading) {
    return `Uploading file...`;
  }

  if (isValidating) {
    return 'Validating file...';
  }

  if (isImporting) {
    return 'Importing file...';
  }

  if (isValidationComplete && !validationError) {
    return 'File has been validated';
  }

  return null;
};
