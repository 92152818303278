import { isEmpty } from 'lodash';
import React, { Ref, useCallback, useEffect, useState } from 'react';
import useResizeObserver from 'src/hooks/useResizeObserver';
import { v1 as uuid } from 'uuid';
import { CloseIcon, NotificationIcon } from '../icons/small-icons';
import {
  CloseButtonContainer,
  ToastContainer,
  ToastContent,
  ToastDiv,
  ToastIconContainer,
  ToastText,
} from './styles';

export enum Status {
  Info = 1,
  Error,
  Success,
}

export interface ToastNotificationProps {
  id?: string;
  message: string;
  status: Status;
  delay?: number; // The time in ms to show the notification. Set to -1 to never automatically hide.
  isClosable?: boolean;
}

export interface ToastContextType {
  showNotification: (notificationProps: ToastNotificationProps) => void;
  updateNotification: (notificationProps: ToastNotificationProps) => void;
  removeNotification: (notificationId: string) => void;
  containerHeight: number;
}

export const ToastContext = React.createContext<ToastContextType>({
  showNotification: () => {},
  updateNotification: () => {},
  removeNotification: () => {},
  containerHeight: 0,
});

interface ToastProviderProps {
  children: React.ReactNode;
  // we hide the notifications display when there's no notifications for test scenarios, because it would create
  // an empty div on components that would render nothing, and it was a bit missleading on why, since the ToastProvider
  // is always rendered on MockedApps
  hideDisplayWhenEmpty?: boolean;
}

export const ToastProvider = ({
  children,
  hideDisplayWhenEmpty,
}: ToastProviderProps) => {
  const [notifications, setNotifications] = useState<ToastNotificationProps[]>(
    [],
  );
  const [snackbarContext, setToastState] = useState<ToastContextType>({
    showNotification: (notification) => {
      const newNotification = { ...notification, id: uuid() };
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        newNotification,
      ]);
      return newNotification;
    },
    updateNotification: (updatedNotification) => {
      setNotifications((prevNotifications) => {
        if (!prevNotifications || isEmpty(prevNotifications)) {
          return [];
        }
        return prevNotifications.map((notification) => {
          if (notification.id === updatedNotification.id) {
            return updatedNotification;
          }
          return notification;
        });
      });
    },
    removeNotification: (notificationId) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.id !== notificationId,
        ),
      );
    },
    containerHeight: 0,
  });

  const onContainerResize = useCallback(
    (container: HTMLElement) => {
      setToastState((state) => ({
        ...state,
        containerHeight: container?.getBoundingClientRect().height,
      }));
    },
    [setToastState],
  );

  // using only the ref was not enough because when the notifications array changed, the DOM was not updated yet with
  // the expected height. This is a more complete solution
  const displayRef = useResizeObserver(onContainerResize);

  const shouldHideDisplay = hideDisplayWhenEmpty && isEmpty(notifications);
  return (
    <ToastContext.Provider value={snackbarContext}>
      {!shouldHideDisplay && (
        <ToastNotificationsDisplay
          notifications={notifications}
          ref={displayRef}
        />
      )}
      {children}
    </ToastContext.Provider>
  );
};

interface ToastNotificationsDisplayProps {
  notifications: ToastNotificationProps[];
  // In transactions page we render two separate ToastNotificationDisplay components
  // so we use the marginTop property to place them one below the other
  marginTop?: number;
}
export const ToastNotificationsDisplay = React.forwardRef(
  (
    { notifications, marginTop }: ToastNotificationsDisplayProps,
    ref: Ref<HTMLElement>,
  ) => {
    return (
      <ToastDiv ref={ref as Ref<HTMLDivElement>} $marginTop={marginTop}>
        {notifications.map((toastProps) => (
          <Toast key={toastProps.id} {...toastProps} />
        ))}
      </ToastDiv>
    );
  },
);
ToastNotificationsDisplay.displayName = 'ToastNotificationsDisplay';

const StatusIcon = ({ status }: { status: Status }) => {
  switch (status) {
    case Status.Info:
      return <InfoNotificationIcon />;
    case Status.Error:
      return <ErrorNotificationIcon />;
    case Status.Success:
    default:
      return <SuccessNotificationIcon />;
  }
};

export const Toast = ({
  message,
  status,
  delay = 3000,
  isClosable = true,
}: ToastNotificationProps) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true); // Initialize component as hidden to get nice transition effect.
    if (delay >= 0) {
      const hiderTimeout = setTimeout(() => setShow(false), delay);
      return () => clearTimeout(hiderTimeout);
    }
  }, []);

  return (
    <ToastContainer className={show ? 'show' : 'hide'}>
      <ToastContent>
        <StatusIcon status={status} />{' '}
        <ToastText data-testid={'toast-notification-text'}>{message}</ToastText>
        {isClosable && (
          <CloseButtonContainer onClick={() => setShow(false)}>
            <CloseIcon size="11" />
          </CloseButtonContainer>
        )}
      </ToastContent>
    </ToastContainer>
  );
};

const ErrorNotificationIcon = () => {
  return (
    <ToastIconContainer>
      <NotificationIcon circleColor={'#FEF2F2'} bellColor={'#F4282D'} />
    </ToastIconContainer>
  );
};

const InfoNotificationIcon = () => {
  return (
    <ToastIconContainer>
      <NotificationIcon circleColor={'#F0F7FF'} bellColor={'#0182FF'} />
    </ToastIconContainer>
  );
};

const SuccessNotificationIcon = () => {
  return (
    <ToastIconContainer>
      <NotificationIcon circleColor={'#EFFAF2'} bellColor={'#33C15D'} />
    </ToastIconContainer>
  );
};
