import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import Banner from 'src/deprecated-components/Banner/Banner';
import { ModalError } from 'src/deprecated-components/Modal/styles';
import { CryptoSelect } from '../CryptoSelect';
import { ErrorMessage } from '../PublicAddressForm/ErrorMessage';
import { FormContent, InstructionContainer, SubmitButton } from '../styles';

import { chainIds } from 'src/common/chainIds';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import { FormGroup, Label } from 'src/deprecated-components/Input/styles';
import {
  Status,
  ToastContext,
} from 'src/deprecated-components/ToastNotification';
import { useMetaMask } from 'src/hooks/useMetaMask';
import { GetWallets } from 'src/pages/wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetWalletsForWalletListQuery,
  Integration,
  useAddPublicXPubMutation,
} from 'src/types/graphql-types';
import { AddWalletContext } from '../../context';
import { TextFormField } from '../FormBaseComponents/TextFormField';
import { ViewPermissions } from '../FormBaseComponents/ViewPermissions';
import { Instructions } from '../Instructions';
import { emptyCacheQuery, getWalletInstructions } from '../util';
import {
  ValidationErrors,
  validateCryptoSelector,
  validateWalletAddress,
} from './validate';

interface PublicXPubFormProps {
  integration: Integration;
  cryptoIntegrations: Integration[];
  onCloseModal?: () => void;
}

export const PublicXPubForm = (props: PublicXPubFormProps) => {
  const integration = props.integration;
  const [selectedCrypto, setSelectedCrypto] = useState<Integration>(null);
  const { description, placeholder } = getWalletInstructions(
    selectedCrypto?.slug,
    selectedCrypto?.supportsHdWallet,
  );
  const [submitErrorMessage, setSubmitErrorMessage] =
    useState<AddWalletErrors>();
  const [walletAddress, setWalletAddress] = useState<string>();
  const [errors, setErrors] = useState<ValidationErrors | undefined>();
  const hasFieldError =
    errors &&
    (errors.walletAddress !== undefined || errors.cryptoSelect !== undefined);
  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);

  const selectedCryptoRef = useRef<Integration>();
  selectedCryptoRef.current = selectedCrypto;
  const inputRef = useRef<HTMLInputElement>(null);
  const metamask = useMetaMask();
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);

  const onValidateWalletAddress = useCallback(() => {
    const addressErrors = validateWalletAddress(walletAddress);
    setErrors({ ...errors, ...addressErrors });
  }, [walletAddress, errors]);

  const onValidateCryptoSelector = useCallback(
    (integration) => {
      const selectedCryptoErrors = validateCryptoSelector(integration);
      setErrors({ ...errors, ...selectedCryptoErrors });
    },
    [errors],
  );

  const onWalletAddressChange = useCallback(
    (e) => {
      setWalletAddress(e.target.value);
      onValidateWalletAddress();
    },
    [onValidateWalletAddress],
  );

  const updateSelectedCrypto = useCallback(
    (integration: Integration) => {
      setSelectedCrypto(integration);
      onValidateCryptoSelector(integration);
    },
    [onValidateCryptoSelector],
  );

  const getMetamaskAddress = async () => {
    if (!selectedCryptoRef.current?.slug) return;
    try {
      const chainId = await metamask.request({ method: 'eth_chainId' });
      inputRef.current.focus();

      if (
        chainId !==
        chainIds[selectedCryptoRef.current.slug as keyof typeof chainIds]
      ) {
        await metamask.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId:
                chainIds[
                  selectedCryptoRef.current.slug as keyof typeof chainIds
                ],
            },
          ],
        });
      } else {
        const accounts: Array<string> = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        if (accounts[0]) {
          const newWalletAddress = accounts[0];
          setWalletAddress(newWalletAddress);
          const addressErrors = validateWalletAddress(newWalletAddress);
          setErrors({ ...errors, ...addressErrors });
        }
      }
    } catch (e) {
      // user may not have chain on acct or some other error we don't want preventing the use of this page
    }
  };

  useEffect(() => {
    if (metamask) {
      metamask.on('chainChanged', getMetamaskAddress);
      metamask.on('accountsChanged', getMetamaskAddress);
    }
  }, []);

  useEffect(() => {
    if (metamask && integration?.slug === 'metamask') {
      getMetamaskAddress();
    }
  }, [selectedCrypto]);

  const [mutate, { loading }] = useAddPublicXPubMutation({
    onCompleted: (data) => {
      if (data?.addPublicXpub?.success) {
        if (data?.addPublicXpub?.warning) {
          showNotification({
            message: `If you have previously added ${selectedCrypto.info.symbol} transactions manually or marked related transactions as transfers, you may now have duplicate transactions. Please review your ${selectedCrypto.info.symbol} transactions and if necessary undo those changes.`,
            status: Status.Info,
          });
        }
        showNotification({
          message: data.addPublicXpub.success,
          status: Status.Success,
        });
        onSuccess(data.addPublicXpub.createdWallet);
        props.onCloseModal?.();
      } else {
        if (data?.addPublicXpub?.error) {
          setSubmitErrorMessage(data?.addPublicXpub?.error);
        } else {
          setSubmitErrorMessage(AddWalletErrors.UnknownError);
        }
      }
    },
    update: (cache, { data }) => {
      if (!data.addPublicXpub.success) {
        return;
      }
      const walletsData: GetWalletsForWalletListQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      const newLocalWallets = [
        ...walletsData.localWallets,
        data.addPublicXpub.createdWallet,
      ];

      cache.writeQuery({
        query: GetWallets,
        data: { ...walletsData, localWallets: newLocalWallets },
      });
    },
    onError: () => {
      setSubmitErrorMessage(AddWalletErrors.UnknownError);
    },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onValidateWalletAddress();
      onValidateCryptoSelector(selectedCrypto);
      mutate({
        variables: {
          publicXpubInput: {
            coinSymbol: selectedCrypto.info.symbol,
            cryptoNetwork: selectedCrypto.info.cryptoNetwork,
            xpubKey: walletAddress,
            accountTypeSlug: integration.slug,
          },
        },
      });
      startFasterPollingForSyncCompletion();
    },
    [
      mutate,
      integration,
      walletAddress,
      selectedCrypto,
      onValidateCryptoSelector,
      onValidateWalletAddress,
    ],
  );

  return (
    <>
      <FormContent>
        <form onSubmit={onSubmit}>
          <ViewPermissions />
          {submitErrorMessage && (
            <ModalError>
              <Banner
                type="error"
                onBannerClose={() => setSubmitErrorMessage(null)}
              >
                <ErrorMessage error={submitErrorMessage} />
              </Banner>
            </ModalError>
          )}
          <FormGroup>
            <Label>Blockchain</Label>
            <CryptoSelect
              cryptoIntegrations={props.cryptoIntegrations}
              setSelectedCrypto={updateSelectedCrypto}
              selectedCrypto={selectedCrypto}
              error={errors ? errors.cryptoSelect : null}
            />
          </FormGroup>
          <TextFormField
            name="wallet_address"
            value={walletAddress}
            onChange={onWalletAddressChange}
            description={description}
            placeholder={placeholder}
            errors={
              errors && errors.walletAddress !== undefined
                ? [errors.walletAddress]
                : null
            }
            onBlur={onValidateWalletAddress}
            inputRef={inputRef}
          />
          <SubmitButton
            disabled={
              hasFieldError || loading || !walletAddress || !selectedCrypto
            }
            type="submit"
          >
            {loading ? 'Please wait...' : `Add ${integration.name}`}
          </SubmitButton>
        </form>
      </FormContent>
      <InstructionContainer $hideBackground>
        <Instructions integration={integration} />
      </InstructionContainer>
    </>
  );
};
