import React from 'react';
import { AddWalletErrors } from 'src/types/graphql-types';
import { StyledLink } from './styles';

interface ErrorMessageProps {
  error: AddWalletErrors | string;
}
export const defaultErrorMessage = 'An error has occurred, please try again.';

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  if (error === AddWalletErrors.HasZeroTransactions) {
    return (
      <>
        We could not add your wallet as it has zero transactions.{' '}
        <StyledLink
          target="_blank"
          href="https://support.cointracker.io/hc/en-us/articles/4413071373329-How-do-I-track-my-local-cold-hardware-wallet-Why-is-it-showing-zero-balance-"
        >
          Learn more about this error.
        </StyledLink>
      </>
    );
  } else if (error === AddWalletErrors.WalletAlreadyLinked) {
    return (
      <>This wallet has already been added to CoinTracker. Please see below.</>
    );
  } else if (error === AddWalletErrors.WalletValidationError) {
    return (
      <>
        We could not add your wallet as this address is invalid. Please follow
        the instructions below and try again.
      </>
    );
  } else if (error === AddWalletErrors.InvalidCryptoCurrency) {
    return <>Wallets of this coin are not supported yet</>;
  } else if (
    error === AddWalletErrors.UnknownError ||
    error === AddWalletErrors.ValueError
  ) {
    return <>{defaultErrorMessage}</>;
  } else {
    return <>{error}</>;
  }
};
