import {
  TransactionManager,
  createRandomString,
  encodeState,
} from '@/app/utils';
import { Integration } from '@/types/graphql-types';

/**
 * Integrations to redirect to, auth url:
 */
export const redirectToIntegration = (integration: Integration) => {
  const url = new URL(integration.webOauthAuthorize.url);
  const state = encodeState(createRandomString());
  url.searchParams.set('client_id', integration.webOauthAuthorize.clientId);
  url.searchParams.set('scope', integration.webOauthAuthorize.scopes.join(','));
  url.searchParams.set(
    'redirect_uri',
    integration.webOauthAuthorize.redirectUri,
  );
  url.searchParams.set('state', state);
  url.searchParams.set('response_type', 'code');
  if (integration.webOauthAuthorize.extraParams?.length > 0) {
    integration.webOauthAuthorize.extraParams.forEach(({ key, value }) => {
      url.searchParams.set(key, value);
    });
  }
  const txns = new TransactionManager();
  txns.create(state, {
    appState: {
      integration: integration,
      redirectUri: integration.webOauthAuthorize.redirectUri.toString(),
    },
  });
  window.location.href = url.toString();
};
