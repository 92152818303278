import { ToastProvider } from '@/components/ToastNotification';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Heading, Spinner } from '@radix-ui/themes';
import { StatsigProvider } from '@statsig/react-bindings';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SideBarWithTopBarLayout } from '../../layouts/SideBarWithTopBarLayout';
import AppThemeProvider from '../AppThemeProvider';
import { AuthProvider } from '../auth';
import { UserContext, useGetUser } from '../user';
import Helmet from './Helmet';
import { useAsyncClient } from './StatsigClient';

const AppLoading = () => {
  // Only show loader if content is taking more than 600ms to load
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), 600);
    return () => clearTimeout(timer);
  }, []);

  return (
    <SideBarWithTopBarLayout isAuthenticated={false}>
      <Flex
        direction="column"
        height="100%"
        justify="center"
        align="center"
        gap="4"
      >
        {showLoading && (
          <>
            <Spinner size="3" />
            <Heading size="3">
              We are loading the app. Give us a second...
            </Heading>
          </>
        )}
      </Flex>
    </SideBarWithTopBarLayout>
  );
};

const Shell = () => {
  const { client: statsigClient, isLoading: isStatsigLoading } =
    useAsyncClient();
  const { isLoading } = useAuth0();
  const { user } = useGetUser({ skip: isLoading });
  const showApp = !isLoading && statsigClient;
  const showLoading = isLoading || isStatsigLoading;
  return (
    <UserContext.Provider value={user}>
      <ToastProvider duration={3000}>
        <AppThemeProvider>
          {showLoading && <AppLoading />}
          {showApp && (
            <StatsigProvider client={statsigClient}>
              <Outlet />
            </StatsigProvider>
          )}
        </AppThemeProvider>
      </ToastProvider>
    </UserContext.Provider>
  );
};

export const ShellWithAuthProvider = () => {
  return (
    <AuthProvider>
      <Helmet />
      <Shell />
    </AuthProvider>
  );
};
