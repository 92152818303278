import React from 'react';
import {
  Country,
  Currency,
  GetUserQuery,
  SessionObj,
} from 'src/types/graphql-types';

export type AuthenticatedUser = Omit<GetUserQuery['myProfile'], '__typename'> &
  Omit<GetUserQuery['userInfo'], '__typename'> &
  Omit<GetUserQuery['userProfile'], '__typename'> & {
    isAuthenticated: true;
    hasFetched?: boolean;
    isFetching?: boolean;
    selectedTheme?: string;
    isTaxProViewingClient?: boolean;
  };

export type UnauthenticatedUser = {
  isAuthenticated: false;
  hasFetched?: boolean;
  anonymousId?: string;
  isFetching?: boolean;
  selectedTheme?: string;
};
export type User =
  | (UnauthenticatedUser & Session)
  | (AuthenticatedUser & Session);

export type DisplayUser = {
  isWhitelisted?: boolean;
  hasDefi?: boolean;
  email?: string;
  baseCurrency?: Currency;
  country?: Country;
  publicId?: string;
  registeredAsTaxPro?: boolean;
};

const initialUserData: UnauthenticatedUser = {
  isAuthenticated: false,
  anonymousId: undefined,
};

type Session = {
  session?: SessionObj;
};

export const isAuthenticatedUser = (
  user: User,
): user is AuthenticatedUser & Session => {
  return user.isAuthenticated;
};

export const isUserInCAorGB = (user: User): boolean => {
  return isAuthenticatedUser(user) && ['CA', 'GB'].includes(user.country.code);
};

export const UserContext = React.createContext<User>(initialUserData);
