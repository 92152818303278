import { useCallback, useEffect, useState } from 'react';

export const useThemeDetector = () => {
  // Required browser checks for future SSG/SSR
  // const isBrowser = typeof window !== "undefined"
  // if (!isBrowser) return false
  const getCurrentTheme = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const mediaQueryListener = useCallback((e: MediaQueryListEvent) => {
    setIsDarkTheme(e.matches);
  }, []);

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    if (!darkThemeMq || !darkThemeMq.addEventListener) {
      return;
    }
    darkThemeMq.addEventListener('change', mediaQueryListener);
    return () => darkThemeMq.removeEventListener('change', mediaQueryListener);
  }, [mediaQueryListener]);
  return isDarkTheme;
};
