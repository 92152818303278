import { useStatsigClient } from '@statsig/react-bindings';
import {
  BetweenHorizonalStart,
  BookOpenText,
  BookText,
  Home,
  Layers,
  LucideIcon,
  Settings,
  Table,
  Wallet,
} from 'lucide-react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { Box, Flex, Grid, Text } from '@radix-ui/themes';
import { GlobalJobStatus } from './GlobalJobStatus';
import { ProfileDropdown } from './ProfileDropdown';

interface NavProps {
  isCollapsed: boolean;
  isAuthenticated?: boolean;
}

const ContainerNavItem = styled(Box)<{
  $isActive: boolean;
  $isCollapsed: boolean;
}>`
  display: flex;
  align-items: center;
  ${({ $isCollapsed }) => $isCollapsed && 'justify-content: center;'}
  text-decoration: none;
  border-radius: var(--radius-2);
  color: var(--gray-12);

  ${({ $isActive }) => $isActive && 'background-color: var(--accent-a4);'}
  &:hover {
    background-color: var(--accent-a4);
  }
`;

interface NavItemProps {
  slug: string;
  title: string;
  isCollapsed: boolean;
  Icon: LucideIcon;
  isActivePattern: string;
}
export const NavItem = ({
  slug,
  title,
  isCollapsed,
  Icon,
  isActivePattern,
}: NavItemProps) => {
  const location = useLocation();

  return (
    <ContainerNavItem
      py="5px"
      asChild
      $isActive={Boolean(matchPath(isActivePattern, location.pathname))}
      $isCollapsed={isCollapsed}
    >
      <NavLink to={slug}>
        {isCollapsed && (
          <Flex gap="8px" align="center">
            <Icon width="16" height="16" />
          </Flex>
        )}
        {!isCollapsed && (
          <Flex gap="8px" align="center" px="3">
            <Icon width="16" height="16" />
            <Text size="2" weight="medium">
              {title}
            </Text>
          </Flex>
        )}
      </NavLink>
    </ContainerNavItem>
  );
};

export const SideBar = ({ isCollapsed, isAuthenticated }: NavProps) => {
  const { client } = useStatsigClient();
  const shouldShowHomePage = client?.checkGate('enterprise_home_page');
  return (
    <Flex direction="column" height="100%" justify="between">
      <Flex direction="column" gap="4">
        <Flex direction="column">
          {isCollapsed && (
            <Flex
              height="48px"
              direction="row"
              gap="2"
              align="center"
              justify="center"
              pt="3"
            >
              <BookText width="24" height="24" color="var(--orange-11)" />
            </Flex>
          )}
          {!isCollapsed && (
            <Flex
              height="48px"
              direction="row"
              gap="2"
              align="center"
              pt="3"
              pl="5"
            >
              <BookText width="18" height="18" color="var(--orange-11)" />
              <Text size="4" weight="medium" color="orange">
                CT Enterprise
              </Text>
            </Flex>
          )}
          {isAuthenticated && <GlobalJobStatus isCollapsed={isCollapsed} />}
        </Flex>
        <Grid gap="1" asChild py="3" px="3">
          <nav>
            {shouldShowHomePage && (
              <NavItem
                slug="/home"
                isActivePattern="/home"
                title="Home"
                Icon={Home}
                isCollapsed={isCollapsed}
              />
            )}
            <NavItem
              slug="/accounting"
              isActivePattern="/accounting/*"
              title="Accounting"
              Icon={BookOpenText}
              isCollapsed={isCollapsed}
            />
            <NavItem
              slug="/wallets"
              isActivePattern="/wallets/*"
              title="Wallets"
              Icon={Wallet}
              isCollapsed={isCollapsed}
            />
            <NavItem
              slug="/transactions"
              isActivePattern="/transactions/*"
              title="Transactions"
              Icon={Table}
              isCollapsed={isCollapsed}
            />
            <NavItem
              slug="/journal-entries"
              isActivePattern="/journal-entries/*"
              title="Journal Entries"
              Icon={BetweenHorizonalStart}
              isCollapsed={isCollapsed}
            />
            <NavItem
              slug="/reports"
              isActivePattern="/reports/*"
              title="Reports"
              Icon={Layers}
              isCollapsed={isCollapsed}
            />
          </nav>
        </Grid>
      </Flex>
      <Flex direction="column" py="3" px="3" gap="1">
        <NavItem
          slug="/settings"
          isActivePattern="/settings/*"
          title="Settings"
          Icon={Settings}
          isCollapsed={isCollapsed}
        />
        {isAuthenticated && <ProfileDropdown isCollapsed={isCollapsed} />}
      </Flex>
    </Flex>
  );
};
