import { formatWalletLabel } from '@/common/wallet-address';
import { Modal, ModalBody, ModalHeader } from '@/components/Modal';
import { Status, ToastContext } from '@/components/ToastNotification';
import { useAddPublicAddressMutation } from '@/types/graphql-types';
import {
  Avatar,
  Button,
  Checkbox,
  CheckboxCards,
  Flex,
  Link,
  Strong,
  Text,
} from '@radix-ui/themes';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AddWalletContext } from '../WalletsHeader/AddWalletButton/AddWalletModal/context';
import { useSuggestedWalletsModalStore } from './store';

// TODO: move to backend enum, same as EVM_CHAINS in MultiAddressWalletParser (multi_address_wallet_parser.py)
export const SUPPORTED_EVM_CHAIN_SLUGS = [
  'ethereum',
  'arbitrum-one',
  'avalanche',
  'binance-smart-chain',
  'fantom',
  'optimism',
  'polygon',
  'base',
  'zksync-era',
];

export const SuggestedWalletsModal = () => {
  const { integrations } = useContext(AddWalletContext);
  const {
    address,
    integration: selectedIntegration,
    closeModal,
    isModalOpen,
  } = useSuggestedWalletsModalStore();
  const [selectedEVMChains, setSelectedEVMChains] = useState<string[]>([]);
  const { showNotification } = useContext(ToastContext);
  const [mutateAddPublicAddress] = useAddPublicAddressMutation({
    onCompleted: (data) => {
      if (data?.addPublicAddress?.success) {
        showNotification({
          message: data.addPublicAddress.success,
          status: Status.Success,
          delay: 5000,
        });
      } else {
        if (
          data?.addPublicAddress?.error ||
          data?.addPublicAddress?.multiAddError
        ) {
          showNotification({
            message:
              data?.addPublicAddress?.error ??
              data?.addPublicAddress?.multiAddError,
            status: Status.Error,
            delay: 5000,
          });
        } else if (data?.addPublicAddress?.multiAddWarning) {
          showNotification({
            message: data?.addPublicAddress?.multiAddWarning,
            status: Status.Info,
            delay: 5000,
          });
        } else {
          showNotification({
            message:
              'Something went wrong when adding your additional wallets. Please try again later.',
            status: Status.Error,
            delay: 5000,
          });
        }
      }
      closeModal();
    },
  });

  const getSupportedEvmChains = useCallback(() => {
    return integrations
      .filter((integration) => integration?.info?.isEvmChain)
      .filter(
        (integration) =>
          selectedIntegration != null &&
          selectedIntegration.slug != integration.slug,
      )
      .filter((integration) =>
        SUPPORTED_EVM_CHAIN_SLUGS.includes(integration.slug),
      );
  }, [integrations, selectedIntegration]);
  useEffect(() => {
    setSelectedEVMChains(getSupportedEvmChains().map((chain) => chain.slug));
  }, [integrations, getSupportedEvmChains]);

  const supportedEvmChains = getSupportedEvmChains();

  return (
    <Modal open={isModalOpen} onOpenChange={closeModal} maxWidth="600px">
      <ModalHeader title="Add additional wallets" />
      <ModalBody gap="4">
        <Text size="3">
          {"We've detected "}
          <Strong>{supportedEvmChains.length}</Strong>
          {' more wallets related to '}
          <Strong>{formatWalletLabel(address ?? '')}</Strong>
          {'. Select the ones you want to add.'}
        </Text>
        <Text as="label" size="3">
          <Flex as="span" gap="2">
            <Checkbox
              id="add-evm-wallets"
              name="add-evm-wallets"
              value="add-evm-wallets"
              checked={selectedEVMChains.length === supportedEvmChains.length}
              onCheckedChange={(checked) => {
                if (checked) {
                  setSelectedEVMChains(
                    supportedEvmChains.map((chain) => chain.slug),
                  );
                } else {
                  setSelectedEVMChains([]);
                }
              }}
            />
            Select all chains
          </Flex>
        </Text>
        <CheckboxCards.Root
          columns="2"
          value={selectedEVMChains}
          onValueChange={setSelectedEVMChains}
        >
          {supportedEvmChains
            .filter((c) => c.slug !== selectedIntegration.slug)
            .map((chain) => (
              <CheckboxCards.Item key={chain.slug} value={chain.slug}>
                <Flex direction="row" gap="2" align="center">
                  <Avatar
                    src={chain.imageUrl}
                    fallback="C"
                    size="2"
                    radius="full"
                  />
                  <Text weight="bold">{chain.name}</Text>
                </Flex>
              </CheckboxCards.Item>
            ))}
        </CheckboxCards.Root>
        <Flex direction="column" width="100%" gap="2" align="center">
          <Flex width="100%" asChild>
            <Button
              size="3"
              onClick={async () => {
                const chains = selectedEVMChains.map((chain) => {
                  const chainIntegration = integrations.find(
                    (integration) => integration.slug === chain,
                  );
                  return chainIntegration?.info.cryptoNetwork;
                });
                mutateAddPublicAddress({
                  variables: {
                    publicAddressInput: {
                      coinSymbol: selectedIntegration.info.symbol,
                      cryptoNetwork: selectedIntegration.info.cryptoNetwork,
                      slug: selectedIntegration.slug,
                      walletAddress: address,
                      cryptoNetworksToAdd: chains,
                    },
                  },
                });
              }}
            >
              Add {selectedEVMChains.length} wallets
            </Button>
          </Flex>
          <Link onClick={closeModal} href="">
            Skip
          </Link>
        </Flex>
      </ModalBody>
    </Modal>
  );
};
