import styled from 'styled-components';

export const ButtonLink = styled.a`
  display: block;
  margin-bottom: 32px;
`;

export const WalletConnectButtonContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
`;
