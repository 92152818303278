import { clsx } from 'clsx';
import { GripVertical } from 'lucide-react';
import * as ResizablePrimitive from 'react-resizable-panels';
import { styled } from 'styled-components';

const ResizablePanelGroup: React.FC<
  React.ComponentProps<typeof ResizablePrimitive.PanelGroup>
> = styled(ResizablePrimitive.PanelGroup)`
  display: flex;
  height: 100%;
  width: 100%;

  &[data-panel-group-direction='vertical'] {
    flex-direction: column;
  }
`;

const ResizablePanel = ResizablePrimitive.Panel;

const StyledResizeHandle = styled(ResizablePrimitive.PanelResizeHandle)`
  position: relative;
  display: flex;
  width: 1px;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-4);

  &:after {
    content: '';
    position: absolute;
    inset-y: 0;
    left: 50%;
    width: 1px;
    transform: translateX(-50%);
  }

  &[data-panel-group-direction='vertical'] {
    height: 1px;
    width: 100%;

    &:after {
      left: 0;
      height: 1px;
      width: 100%;
      transform: translateY(-50%) translateX(0);
    }

    &[data-panel-group-direction='vertical'] > div {
      transform: rotate(90deg);
    }
  }
`;

const StyledHandle = styled.div`
  z-index: 10;
  display: flex;
  height: 1rem;
  width: 0.75rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.125rem;
  border: 1px solid var(--gray-4);
  background-color: var(--gray-4);
`;

const ResizableHandle = ({
  withHandle,
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle> & {
  withHandle?: boolean;
}) => (
  <StyledResizeHandle className={clsx(className)} {...props}>
    {withHandle && (
      <StyledHandle>
        <GripVertical height="16" width="16" />
      </StyledHandle>
    )}
  </StyledResizeHandle>
);

export { ResizableHandle, ResizablePanel, ResizablePanelGroup };
