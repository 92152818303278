export function getContrastColor(color: string) {
  const [r, g, b] = hexToRgb(color);
  // Calculate relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Use a threshold to determine whether to use white or black text
  return luminance > 0.5 ? 'black' : 'white';
}

export function hexToRgb(hex: string) {
  // Check if the input is already in RGB or RGBA format
  if (/^rgb/i.test(hex) || /^rgba/i.test(hex)) {
    // If it's already in RGB format, return the array representation
    const rgbValues = hex.match(/\d+/g).map(Number);
    return rgbValues.slice(0, 3); // Return only the first 3 values (RGB) and ignore the alpha channel
  }

  // Remove the leading '#' if it exists
  hex = hex.replace(/^#/, '');

  // Check if the hex code is 3 or 6 characters long
  if (hex.length === 3) {
    // Expand 3-character hex to 6 characters (e.g., "abc" to "aabbcc")
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Parse the hex values for red, green, and blue
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Return the RGB values as an array [r, g, b]
  return [r, g, b];
}
