import { Modal, ModalBody } from '@/components/Modal';
import { styled } from 'styled-components';

export const ModalContent = styled(ModalBody)``;

export const StyledModal = styled(Modal)<{
  $isSelectedIntegration: boolean;
  $isWiderWidth?: boolean;
}>`
  /* The height is required so the modal does not change sizes as the number of search results change in the modal body */
  height: ${({ $isSelectedIntegration }) =>
    $isSelectedIntegration ? 'unset' : '74%'};
  max-width: ${({ $isSelectedIntegration, $isWiderWidth }) =>
    $isSelectedIntegration && !$isWiderWidth ? '488px' : '800px'};
`;

export const WalletContainer = styled.div`
  max-width: 800px;
  height: 738px;
  overflow-y: scroll;
  margin: auto;
  overflow-x: hidden;

  padding: 24px 32px;
  background: ${({ theme }) => theme.grey.white};
  box-shadow: ${({ theme }) => theme.shadow.level1};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.grey.mid};
  width: 100%;
`;

export const WalletContainerHeader = styled.header`
  margin-bottom: 24px;
`;
export const BackButton = styled.button`
  cursor: pointer;
  margin: 0px 12px 0px 0px;
  border: none;
  background: none;
  padding: 0;
`;

export const BackButtonIcon = styled.img`
  height: 16px;
  width: 16px;
`;

export const ModalHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
`;
