import { isEmpty } from 'lodash';
import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import Banner from 'src/deprecated-components/Banner/Banner';
import { ModalError } from 'src/deprecated-components/Modal/styles';
import { CryptoSelect } from '../CryptoSelect';
import { ErrorMessage } from '../PublicAddressForm/ErrorMessage';
import { FormContent, InstructionContainer, SubmitButton } from '../styles';

import { CostBasisContext } from 'src/components/CostBasisProvider';
import { FormGroup, Label } from 'src/deprecated-components/Input/styles';
import {
  Status,
  ToastContext,
} from 'src/deprecated-components/ToastNotification';
import { GetWallets } from 'src/pages/wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetWalletsForWalletListQuery,
  Integration,
  useAddTwoXpubMutation,
} from 'src/types/graphql-types';
import { AddWalletContext } from '../../context';
import { TextFormField } from '../FormBaseComponents/TextFormField';
import { ViewPermissions } from '../FormBaseComponents/ViewPermissions';
import { Instructions } from '../Instructions';
import { emptyCacheQuery } from '../util';
import {
  ValidationErrors,
  validate,
  validateCryptoSelector,
  validateXpub,
} from './validate';

interface TwoXPubFormProps {
  integration: Integration;
  cryptoIntegrations: Integration[];
  onCloseModal?: () => void;
}

export const TwoXPubForm = (props: TwoXPubFormProps) => {
  const integration = props.integration;

  const [selectedCrypto, setSelectedCrypto] = useState<Integration>(null);
  const [receiveAddressXpub, setReceiveAddressXpub] = useState('');
  const [changeAddressXpub, setChangeAddressXpub] = useState('');

  const [submitErrorMessage, setSubmitErrorMessage] =
    useState<AddWalletErrors>();
  const [errors, setErrors] = useState<ValidationErrors>();

  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);

  const onReceiveAddressXpubChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setReceiveAddressXpub(e.target.value);
      setErrors({
        ...errors,
        receiveAddressXpub: validateXpub(e.target.value, changeAddressXpub),
      });
    },
    [errors, changeAddressXpub],
  );

  const onChangeAddressXpubChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setChangeAddressXpub(e.target.value);
      setErrors({
        ...errors,
        changeAddressXpub: validateXpub(receiveAddressXpub, e.target.value),
      });
    },
    [errors, receiveAddressXpub],
  );

  const updateSelectedCrypto = useCallback(
    (integration: Integration) => {
      setSelectedCrypto(integration);
      setErrors({
        ...errors,
        cryptoSelect: validateCryptoSelector(integration),
      });
    },
    [errors],
  );

  const [addTwoXPub, { loading }] = useAddTwoXpubMutation({
    onCompleted: (data) => {
      if (data.addTwoXpub.success) {
        showNotification({
          message: data.addTwoXpub.message,
          status: Status.Success,
        });
        onSuccess(data.addTwoXpub.createdWallet);
        props.onCloseModal?.();
        return;
      }

      if (data.addTwoXpub.error) {
        setSubmitErrorMessage(data.addTwoXpub.error);
      } else {
        setSubmitErrorMessage(AddWalletErrors.UnknownError);
      }
    },
    update: (cache, { data }) => {
      if (!data.addTwoXpub.success) {
        return;
      }
      const walletsData: GetWalletsForWalletListQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      const newLocalWallets = [
        ...walletsData.localWallets,
        data.addTwoXpub.createdWallet,
      ];

      cache.writeQuery({
        query: GetWallets,
        data: { ...walletsData, localWallets: newLocalWallets },
      });
    },
    onError: () => {
      setSubmitErrorMessage(AddWalletErrors.UnknownError);
    },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const errors = validate({
        receiveAddressXpub,
        changeAddressXpub,
        selectedCrypto,
      });
      if (!isEmpty(errors)) {
        setErrors(errors);
        return;
      }

      addTwoXPub({
        variables: {
          twoXpubInput: {
            coinSymbol: selectedCrypto.info.symbol,
            cryptoNetwork: selectedCrypto.info.cryptoNetwork,
            receiveAddressXpub: receiveAddressXpub,
            changeAddressXpub: changeAddressXpub,
            accountTypeSlug: integration.slug,
          },
        },
      });
      startFasterPollingForSyncCompletion();
    },
    [
      addTwoXPub,
      integration,
      receiveAddressXpub,
      selectedCrypto,
      changeAddressXpub,
    ],
  );

  const hasReceiveXpubError = errors && errors.receiveAddressXpub !== undefined;
  const hasChangeXpubError = errors && errors.changeAddressXpub !== undefined;
  const hasCryptoSelectError = errors && errors.cryptoSelect !== undefined;
  const isFormInvalid =
    hasReceiveXpubError || hasChangeXpubError || hasCryptoSelectError;

  return (
    <>
      <FormContent>
        <form onSubmit={onSubmit}>
          <ViewPermissions />
          {submitErrorMessage && (
            <ModalError>
              <Banner
                type="error"
                onBannerClose={() => setSubmitErrorMessage(null)}
              >
                <ErrorMessage error={submitErrorMessage} />
              </Banner>
            </ModalError>
          )}
          <FormGroup>
            <Label>Blockchain</Label>
            <CryptoSelect
              cryptoIntegrations={props.cryptoIntegrations}
              setSelectedCrypto={updateSelectedCrypto}
              selectedCrypto={selectedCrypto}
              error={errors ? errors.cryptoSelect : null}
            />
          </FormGroup>
          <TextFormField
            name="receive_address_xpub"
            description={'HD Wallet address [0]'}
            placeholder={'The xPub/yPub/zPub key for receive addresses'}
            value={receiveAddressXpub}
            onChange={onReceiveAddressXpubChange}
            errors={
              errors && errors.receiveAddressXpub !== undefined
                ? [errors.receiveAddressXpub]
                : null
            }
          />
          <TextFormField
            name="change_address_xpub"
            description={'HD Wallet address [1]'}
            placeholder={'The xPub/yPub/zPub key for change addresses'}
            value={changeAddressXpub}
            onChange={onChangeAddressXpubChange}
            errors={
              errors && errors.changeAddressXpub !== undefined
                ? [errors.changeAddressXpub]
                : null
            }
          />
          <SubmitButton disabled={isFormInvalid || loading} type="submit">
            {loading ? 'Please wait...' : `Add ${integration.name}`}
          </SubmitButton>
        </form>
      </FormContent>
      <InstructionContainer>
        <Instructions integration={integration} />
      </InstructionContainer>
    </>
  );
};
