import styled from 'styled-components';

export const ToastDiv = styled.div<{ $marginTop?: number }>`
  position: fixed;
  right: 32px;
  top: ${({ $marginTop }) => ($marginTop ? `${104 + $marginTop}px` : '104px')};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ToastContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  background: ${({ theme }) => theme.grey.white};
  border: 1px solid ${({ theme }) => theme.grey.mid};
  box-shadow: ${({ theme }) => theme.shadow.level2};
  overflow-y: hidden;
  border-radius: 5px;

  &.show {
    max-height: 150px;
    opacity: 1;
    margin-bottom: 12px;
  }
  &.hide {
    max-height: 0px;
    opacity: 0;
    margin-bottom: 0px;
    border: 0px;
  }
  transition-property: margin-bottom, max-height, opacity, border;
  transition-duration: 0.15s;
  transition-timing-function: linear;
`;

export const ToastContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  width: 360px;
`;

export const ToastText = styled.span`
  margin-left: 12px;
  margin-right: 12px;
  font-style: normal;
  font-weight: normal;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.116667px;
  flex: 1;
  color: ${({ theme }) => theme.text.primary};
`;

export const CloseButtonContainer = styled.span`
  cursor: pointer;

  svg path {
    stroke: ${({ theme }) => theme.grey.strong};
  }
`;

export const ToastIconContainer = styled.span`
  min-width: 32px;
  min-height: 32px;
`;
