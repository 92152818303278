import React, { ReactNode, useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { InlineButton } from '../buttons/regular';
import { CloseIcon, InfoIcon } from '../icons/small-icons';

const BannerDiv = styled.div<{ $rounded?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  padding: 12px 14px;
  width: 100%;
  ${({ $rounded }) => $rounded && 'border-radius: 5px'}
  color: ${({ theme }) => theme.grey.white};
`;

const SuccessBannerDiv = styled(BannerDiv)`
  background-color: ${({ theme }) => theme.secondary.green};
`;
const WarningBannerDiv = styled(BannerDiv)`
  background-color: ${({ theme }) => theme.secondary.orange};
`;
const ErrorBannerDiv = styled(BannerDiv)`
  background-color: ${({ theme }) => theme.red.solid};
`;

const CenteredText = styled.span`
  display: flex;
  color: ${({ theme }) => theme.grey.white};
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4;
  font-weight: 600;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.grey.white};
  }

  > span:first-child {
    margin-right: 12px;
  }
`;

// needed to counterbalance the center/right flex grid
const FlexItem = styled.div`
  flex: 1;
`;

const CloseError = styled(InlineButton)`
  margin-left: auto;
  position: relative;
`;
const StyledSpan = styled.span`
  line-height: 1;
`;

export interface BannerProps {
  children?: ReactNode;
  href?: string;
  type?: 'success' | 'warning' | 'error';
  closable?: boolean;
  withPrefixIcon?: boolean;
  className?: string; // https://styled-components.com/docs/basics#styling-any-component
  onBannerClose?: () => void;
  rounded?: boolean;
}

export const Banner = ({
  children,
  href,
  type,
  className,
  closable = true,
  withPrefixIcon = true,
  rounded = false,
  onBannerClose,
}: BannerProps) => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const onClose = useCallback(() => {
    setOpen(false);
    if (onBannerClose) {
      onBannerClose();
    }
  }, []);
  if (!open) return null;
  let Container = WarningBannerDiv;
  if (type === 'success') {
    Container = SuccessBannerDiv;
  } else if (type === 'error') {
    Container = ErrorBannerDiv;
  }
  return (
    <Container className={className} $rounded={rounded}>
      {closable && <FlexItem />}
      <CenteredText href={href} as={href ? 'a' : 'span'}>
        <StyledSpan>
          {withPrefixIcon && <InfoIcon color={theme.grey.white} size={16} />}
        </StyledSpan>
        <StyledSpan>{children}</StyledSpan>
      </CenteredText>
      {closable && (
        <FlexItem>
          <CloseError onClick={onClose} aria-label="close-banner-button">
            <CloseIcon color={theme.grey.white} />
          </CloseError>
        </FlexItem>
      )}
    </Container>
  );
};

export default Banner;
