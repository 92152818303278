import { isEmpty } from 'lodash';
import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import Banner from 'src/deprecated-components/Banner/Banner';
import Input from 'src/deprecated-components/Input';
import { FormGroup, Label } from 'src/deprecated-components/Input/styles';
import {
  Status,
  ToastContext,
} from 'src/deprecated-components/ToastNotification';
import {
  ExchangeMutationErrors,
  GetWalletsForWalletListQuery,
  Integration,
  useAddExchangeWithCustomerIdMutation,
  useUpdateExchangeWithCustomerIdMutation,
} from 'src/types/graphql-types';

import { FormError } from '../styles';
import { SubmitButton } from './styles';

import { AddWalletContext } from '../../context';

import { GetWallets } from 'src/pages/wallets/queries/getWallets.graphql';
import { emptyCacheQuery } from '../util';
import { isStringValid, validate, ValidationErrors } from './validate';

interface CustomerIdFormProps {
  integration: Integration;
  onCloseModal?: () => void;
  isEditMode?: boolean;
  exchangeId?: string;
}

export const GENERIC_ERROR_MESSAGE =
  'There was a problem while trying to add your exchange. Try again or contact support';
export const CustomerIdForm = (props: CustomerIdFormProps) => {
  const integration = props.integration;

  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [errorText, setErrorText] = useState('');
  const [errors, setErrors] = useState<ValidationErrors>({});

  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);
  const [addExchange, { loading }] = useAddExchangeWithCustomerIdMutation({
    onCompleted: (data) => {
      if (data.addExchangeWithCustomerId.success) {
        showNotification({
          status: Status.Success,
          message: `Added new exchange`,
        });
        onSuccess(data.addExchangeWithCustomerId.createdExchange);
        props.onCloseModal?.();
        return;
      }

      if (
        data.addExchangeWithCustomerId.error ===
        ExchangeMutationErrors.AccountAlreadyLinked
      ) {
        setErrorText('Account is already linked');
      } else if (
        data.addExchangeWithCustomerId.error ===
        ExchangeMutationErrors.InvalidAccount
      ) {
        setErrorText('Account is invalid');
      } else if (
        data.addExchangeWithCustomerId.error ===
        ExchangeMutationErrors.InvalidApiKey
      ) {
        setErrorText('API key is invalid');
      } else {
        setErrorText(GENERIC_ERROR_MESSAGE);
      }
    },
    update: (cache, { data }) => {
      if (!data.addExchangeWithCustomerId.success) {
        return;
      }
      const walletsData: GetWalletsForWalletListQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      const newExchanges = [
        ...walletsData.exchanges,
        data.addExchangeWithCustomerId.createdExchange,
      ];

      cache.writeQuery({
        query: GetWallets,
        data: { ...walletsData, exchanges: newExchanges },
      });
    },
    onError: () => {
      setErrorText(GENERIC_ERROR_MESSAGE);
    },
  });

  const [updateExchange, { loading: updateLoading }] =
    useUpdateExchangeWithCustomerIdMutation({
      onCompleted: (data) => {
        if (data.updateExchangeWithCustomerId.success) {
          showNotification({
            status: Status.Success,
            message: `Updated exchange details`,
          });
          onSuccess(data.updateExchangeWithCustomerId.updatedExchange);
          props.onCloseModal?.();
          return;
        }

        if (
          data.updateExchangeWithCustomerId.error ===
          ExchangeMutationErrors.AccountAlreadyLinked
        ) {
          setErrorText('Account is already linked');
        } else if (
          data.updateExchangeWithCustomerId.error ===
          ExchangeMutationErrors.InvalidAccount
        ) {
          setErrorText('Account is invalid');
        } else if (
          data.updateExchangeWithCustomerId.error ===
          ExchangeMutationErrors.InvalidApiKey
        ) {
          setErrorText('API key is invalid');
        } else {
          setErrorText(GENERIC_ERROR_MESSAGE);
        }
      },
      update: (cache, { data }) => {
        if (!data.updateExchangeWithCustomerId.success) {
          return;
        }
        const walletsData: GetWalletsForWalletListQuery =
          cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
        const newExchanges = walletsData.exchanges.map((exchange) => {
          if (
            exchange.id === data.updateExchangeWithCustomerId.updatedExchange.id
          ) {
            return data.updateExchangeWithCustomerId.updatedExchange;
          }
          return exchange;
        });

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, exchanges: newExchanges },
        });
      },
      onError: () => {
        setErrorText(GENERIC_ERROR_MESSAGE);
      },
    });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const apiKey = formData.get('apiKey') as string;
      const apiSecret = formData.get('apiSecret') as string;
      const customerId = formData.get('customerId') as string;

      const errors = validate({ apiSecret, apiKey, customerId });
      if (!isEmpty(errors)) {
        setErrors(errors);
        return;
      }

      if (props.isEditMode && props.exchangeId) {
        updateExchange({
          variables: {
            updateExchangeInput: {
              apiKey,
              apiSecret,
              customerId,
              publicId: props.exchangeId,
            },
          },
        });
      } else {
        addExchange({
          variables: {
            addExchangeInput: {
              apiKey,
              apiSecret,
              customerId,
              accountTypeSlug: integration.slug,
            },
          },
        });
      }
    },
    [
      integration,
      addExchange,
      updateExchange,
      props.isEditMode,
      props.exchangeId,
    ],
  );

  const hasApiKeyError = errors.apiKeyMissing;
  const hasApiKeySecretError = errors.apiSecretMissing;
  const hasCustomerIdError = errors.customerIdMissing;

  const isFormInvalid =
    !!errorText || hasApiKeyError || hasApiKeySecretError || hasCustomerIdError;

  const onApiKeyChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setApiKey(e.target.value);
      setErrors({ ...errors, apiKeyMissing: !isStringValid(e.target.value) });
    },
    [errors],
  );

  const onApiKeySecretChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setApiSecret(e.target.value);
      setErrors({
        ...errors,
        apiSecretMissing: !isStringValid(e.target.value),
      });
    },
    [errors],
  );

  const onCustomerIdChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCustomerId(e.target.value);
      setErrors({
        ...errors,
        customerIdMissing: !isStringValid(e.target.value),
      });
    },
    [errors],
  );

  const isLoading = loading || updateLoading;

  return (
    <form onSubmit={onSubmit}>
      <FormGroup>
        <Label as="label" htmlFor="customerId">
          Customer ID
        </Label>
        <Input
          name="customerId"
          id="customerId"
          hasError={hasCustomerIdError}
          onChange={onCustomerIdChange}
          value={customerId}
          hint={hasCustomerIdError ? 'Field is required' : ''}
          autoFocus={true}
        />
      </FormGroup>
      <FormGroup>
        <Label as="label" htmlFor="apiKey">
          API Key
        </Label>
        <Input
          name="apiKey"
          id="apiKey"
          hasError={hasApiKeyError}
          onChange={onApiKeyChange}
          value={apiKey}
          hint={hasApiKeyError ? 'Field is required' : ''}
        />
      </FormGroup>
      <FormGroup>
        <Label as="label" htmlFor="apiSecret">
          API Secret
        </Label>
        <Input
          name="apiSecret"
          id="apiSecret"
          hasError={hasApiKeySecretError}
          onChange={onApiKeySecretChange}
          value={apiSecret}
          hint={hasApiKeySecretError ? 'Field is required' : ''}
        />
      </FormGroup>

      <SubmitButton type="submit" disabled={isFormInvalid || isLoading}>
        {isLoading ? 'Please wait...' : `Add ${integration.name}`}
      </SubmitButton>
      {errorText && (
        <FormError>
          <Banner type="error" onBannerClose={() => setErrorText('')} rounded>
            {errorText}
          </Banner>
        </FormError>
      )}
    </form>
  );
};
