import { css } from 'styled-components';
import { ThemeProps } from '../colors/theme';

export const variantStyles = (theme: ThemeProps, variant = 'primary') =>
  ({
    primary: css`
      color: ${theme.text.primary};
    `,
    secondary: css`
      color: ${theme.text.secondary};
    `,
    tertiary: css`
      color: ${theme.text.tertiary};
    `,
    blue: css`
      color: ${theme.text.blue};
    `,
    white: css`
      color: ${theme.grey.white};
    `,
    red: css`
      color: ${theme.secondary.red};
    `,
    grey: css`
      color: ${theme.grey.dark};
    `,
    lightGrey: css`
      color: ${theme.grey.light};
    `,
    greyStrong: css`
      color: ${theme.grey.strong};

      &:hover,
      &:active,
      &:focus {
        color: ${theme.grey.strong};
        text-decoration: none;
      }
    `,
    green: css`
      color: ${theme.secondary.green};
    `,
    yellow: css`
      color: ${theme.secondary.yellow};
    `,
    inherit: css`
      color: inherit;
    `,
  })[variant];
