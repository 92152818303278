import { Flex, Text, TextField } from '@radix-ui/themes';
import React from 'react';
import { css, styled } from 'styled-components';
interface TextFormFieldProps {
  description: string;
  placeholder?: string;
  name: string;
  defaultValue?: string;
  errors?: string[];
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
  optional?: boolean;
}

const StyledTextField = styled(TextField.Root)<{ $hasError: boolean }>`
  ${({ $hasError = false }) =>
    $hasError &&
    css`
      &,
      &:hover,
      &:active,
      &:focus-within {
        border: 1px solid var(--red-10);
      }
    `}
`;

export const TextFormField = (props: TextFormFieldProps) => {
  return (
    <Flex direction="column" gap="2" mb="4">
      <Text size="2">
        {props.description}
        {props.optional && (
          <Text as="span" color="gray">
            {' '}
            (optional)
          </Text>
        )}
      </Text>
      <StyledTextField
        size="3"
        autoFocus={props.autoFocus}
        name={props.name}
        id={props.name}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        $hasError={props.errors && props.errors.length > 0}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        ref={props.inputRef || null}
        disabled={props.disabled}
      ></StyledTextField>
      {props.errors && props.errors.length > 0 && (
        <Text size="1" color="red">
          {props.errors[0]}
        </Text>
      )}
    </Flex>
  );
};
