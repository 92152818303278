import { IntegrationFormType } from 'src/types/graphql-types';

export enum IntegrationCategories {
  blockchain = 'Blockchain',
  exchange = 'Exchange',
  wallet = 'Wallet',
}

export enum IntegrationSyncMethod {
  AutoSync = 'Automatic sync',
  CustomFile = 'Custom file',
  CoinTrackerCSV = 'CoinTracker CSV',
  AutoSyncAndFile = 'Automatic sync & custom file',
}

interface IntegrationFAQ {
  question: string;
  answer: string;
}

export interface IntegrationInfo {
  hide_instructions?: boolean;
  faqs?: IntegrationFAQ[];
  crypto_network?: string;
  circle_icon_url?: string;
  symbol?: string;
  supported_crypto_networks?: string[];
}

export interface Integration {
  slug?: string;
  name?: string;
  image_url?: string;
  sync_method?: IntegrationSyncMethod;
  has_file_importer?: boolean;
  is_disabled?: boolean;
  category?: IntegrationCategories;
  is_featured?: boolean;
  rank?: number;
  instructions?: string;
  website_url?: string;
  description?: string;
  info?: IntegrationInfo;
  supports_hd_wallet?: boolean;
  form_id?: IntegrationFormType;
}
