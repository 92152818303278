import { isEmpty } from 'lodash';

export type ValidationErrors = {
  apiKeyMissing?: boolean;
  apiSecretMissing?: boolean;
  passphraseMissing?: boolean;
};

interface validateProps {
  apiKey: string;
  apiSecret: string;
  passphrase: string;
}

export const isStringValid = (value: string) => !!value.trim();

export const validate = ({ apiKey, apiSecret, passphrase }: validateProps) => {
  const errors: ValidationErrors = {};
  if (!isStringValid(apiKey)) {
    errors.apiKeyMissing = true;
  }

  if (!isStringValid(apiSecret)) {
    errors.apiSecretMissing = true;
  }

  if (!isStringValid(passphrase)) {
    errors.passphraseMissing = true;
  }

  if (isEmpty(errors)) {
    return undefined;
  }
  return errors;
};
