import { useAuth0 } from '@auth0/auth0-react';
import { StatsigClient } from '@statsig/js-client';
import { useMemo, useState } from 'react';
import { isEmployee } from '../user';
import { useGetAppInfo } from './appInfoContext';

export const useAsyncClient = (): {
  isLoading: boolean;
  client: StatsigClient;
} => {
  const { isLoading: isLoadingAuth } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const { isLoading: isAppInfoLoading, appInfo } = useGetAppInfo({
    skip: isLoadingAuth,
  });

  const client = useMemo(() => {
    if (isAppInfoLoading || isLoadingAuth) return;

    if (!appInfo) {
      setIsLoading(false);
      console.log(
        "Error fetching app info. Can't initialize Statsig client. Loading an empty instance!",
      );
      return new StatsigClient(import.meta.env.PUBLIC_STATSIG_CLIENT_KEY, {});
    }

    const instance = new StatsigClient(
      import.meta.env.PUBLIC_STATSIG_CLIENT_KEY,
      {
        userID: appInfo.userId,
        customIDs: {
          anonymousID: appInfo.anonymousId,
        },
        custom: {
          organizationId: appInfo.orgId?.toString(),
          is_employee: isEmployee(appInfo.email ?? ''),
          isAnonymous: !appInfo.email,
        },
      },
    );

    instance
      .initializeAsync()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

    return instance;
  }, [appInfo, isLoadingAuth, isAppInfoLoading]);

  return { client, isLoading };
};
