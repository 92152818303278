import * as RadixTabs from '@radix-ui/react-tabs';
import React from 'react';
import { StyledList, StyledListItem } from './styles';

// documentation https://www.radix-ui.com/docs/primitives/components/tabs
export const Root = (props: RadixTabs.TabsProps) => {
  return <RadixTabs.Root {...props} />;
};
export const List = (props: RadixTabs.TabsListProps) => {
  return <StyledList {...props} />;
};

export const Trigger = (props: RadixTabs.TabsTriggerProps) => {
  return <StyledListItem {...props} />;
};

export const Content = (props: RadixTabs.TabsContentProps) => {
  return <RadixTabs.Content {...props} />;
};
