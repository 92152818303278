import { logout } from '@/app/auth';
import { Box, DropdownMenu, Flex, Text } from '@radix-ui/themes';
import { Building2 } from 'lucide-react';
import { styled } from 'styled-components';
import { useGetAppInfo } from '../app/root/appInfoContext';

const ContainerNavItem = styled(Flex)`
  text-decoration: none;
  border-radius: var(--radius-2);
  color: var(--gray-12);
  box-shadow: inset 0 0 0 1px var(--gray-6);

  &:hover {
    background-color: var(--accent-a4);
    box-shadow: none;
  }
`;

const StyledEmailText = styled(Text)`
  color: var(--gray-12);
  cursor: text;
`;

const StyledOrgNameText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProfileDropdown = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const { appInfo } = useGetAppInfo();
  const { orgName, email } = appInfo ?? {};
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Box>
          {!isCollapsed && (
            <ContainerNavItem
              justify="between"
              align="center"
              width="100%"
              px="3"
              py="5px"
            >
              <Flex
                direction="row"
                gap="2"
                justify="start"
                align="center"
                maxWidth="95%"
              >
                {/* This Flex is needed for correct positioning of the icon */}
                <Flex width="16px" height="16px">
                  <Building2 size="16" />
                </Flex>
                <StyledOrgNameText size="2" weight="medium">
                  {orgName}
                </StyledOrgNameText>
              </Flex>
              <DropdownMenu.TriggerIcon style={{ justifySelf: 'end' }} />
            </ContainerNavItem>
          )}
          {isCollapsed && (
            <ContainerNavItem justify="center" align="center" px="3px" py="5px">
              <Building2 size="16" />
            </ContainerNavItem>
          )}
        </Box>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Label>
          <StyledEmailText size="2">{email}</StyledEmailText>
        </DropdownMenu.Label>
        <DropdownMenu.Separator />
        <DropdownMenu.Item
          color="red"
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            })
          }
        >
          Log out
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
