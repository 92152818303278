import {
  ANONYMOUS_ID,
  CREATED_AT,
  IS_AUTHENTICATED,
  IS_EMPLOYEE,
  PUBLIC_ID,
  TAX_PROS_RETURN_PUBLIC_ID,
} from 'src/common/session_keys';

/**
 * Parses a cookie into a JSON object
 *
 * Example:
 * "pkg=math; equation=E%3Dmc%5E2" => { pkg: 'math', equation: 'E=mc^2' }
 */
export const parseCookie = (str: string) => {
  if (!str || str.length === 0) {
    return null;
  }

  return str
    .split(';')
    .map((v) => v.split('='))
    .reduce(
      (acc, [key, value]) => {
        acc[decodeURIComponent(key.trim())] = decodeURIComponent(value.trim());
        return acc;
      },
      {} as { [key: string]: string },
    );
};

/**
 * Delete a field in the cookies
 * @param key - field to delete
 */
export const deleteCookie = (key: string) => {
  const cookie = parseCookie(document.cookie);
  if (cookie?.[key]) {
    document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
};

export const deleteAllDataCookies = () => {
  deleteCookie(IS_AUTHENTICATED);
  deleteCookie(IS_EMPLOYEE);
  deleteCookie(CREATED_AT);
  deleteCookie(TAX_PROS_RETURN_PUBLIC_ID);
  deleteCookie(PUBLIC_ID);
  deleteCookie(ANONYMOUS_ID);
};
