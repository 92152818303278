import { isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useGetAppInfoQuery } from '../../types/graphql-types';
import * as ClientStorage from '../utils/cookiesStorage';
const COOKIE_KEY = 'enterprise.org';

interface AppInfoCookies {
  orgId: number;
  orgName: string;
  userId: string;
  anonymousId: string;
  email: string;
}

export const clearAppInfo = () => {
  const appInfoCachedValue = ClientStorage.get(COOKIE_KEY) as AppInfoCookies;
  if (appInfoCachedValue) {
    ClientStorage.remove(COOKIE_KEY);
  }
};

/**
 * Return the app info from the cookies by default, if not there get from the API
 * Always call the API to get the latest app info and update cookies.
 */
export const useGetAppInfo = ({ skip } = { skip: false }) => {
  const appInfoCachedValue = ClientStorage.get(COOKIE_KEY) as AppInfoCookies;
  const [appInfo, setAppInfo] = useState<AppInfoCookies | undefined>(
    appInfoCachedValue,
  );

  const {
    data,
    loading: loadingAppInfo,
    error,
    refetch,
  } = useGetAppInfoQuery({
    errorPolicy: 'all',
    skip,
  });
  const initialLoading = appInfoCachedValue?.email ? false : loadingAppInfo;
  const [isLoading, setIsLoading] = useState<boolean>(initialLoading);

  useEffect(() => {
    if (data || error) {
      setIsLoading(false);
      if (!data?.userInfo) {
        console.error('Error fetching app info. Clearing app info cookies.');
        clearAppInfo();
        return setAppInfo(undefined);
      }

      const appInfoCachedValue = ClientStorage.get(
        COOKIE_KEY,
      ) as AppInfoCookies;
      const appInfo = {
        orgId: data?.organization?.id,
        orgName: data?.organization?.name,
        userId: data?.userInfo?.publicId,
        anonymousId: data?.userInfo?.anonymousId,
        email: data?.userProfile?.email,
      };
      // Only re-render if the app info has changed
      if (!isEqual(appInfoCachedValue, appInfo)) {
        setAppInfo(appInfo);
      }
      ClientStorage.save(COOKIE_KEY, appInfo, { daysUntilExpire: 7 });
    }
  }, [data, error, loadingAppInfo]);

  if (skip) {
    return {
      isLoading: false,
      appInfo: undefined,
      refetch,
    };
  }
  return {
    isLoading,
    appInfo,
    refetch,
  };
};
