import { Tabs } from '@radix-ui/themes';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface FileAPISwitcherProps {
  children: ReactNode[];
  hasCustomFileImporter?: boolean;
}

export enum APITabs {
  AutomaticSync = 'automaticSync',
  CSVImport = 'CSVImport',
}

export default function FileAPISwitcher({
  children,
  hasCustomFileImporter,
}: FileAPISwitcherProps) {
  const csvTabTitle = hasCustomFileImporter ? 'Custom file' : 'CoinTracker CSV';
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<APITabs>(
    APITabs.AutomaticSync,
  );

  useEffect(() => {
    if (searchParams.get('csv')) {
      setSelectedTab(APITabs.CSVImport);
    }
  }, [setSelectedTab, searchParams]);

  const onTabChange = (val: APITabs) => {
    if (val === APITabs.AutomaticSync) {
      searchParams.delete('csv');
      setSearchParams(searchParams);
    }
    setSelectedTab(val);
  };
  return (
    <Tabs.Root value={selectedTab} onValueChange={onTabChange}>
      <Tabs.List>
        <Tabs.Trigger value={APITabs.AutomaticSync}>
          Automatic sync
        </Tabs.Trigger>
        <Tabs.Trigger value={APITabs.CSVImport}>{csvTabTitle}</Tabs.Trigger>
      </Tabs.List>

      {children}
    </Tabs.Root>
  );
}
