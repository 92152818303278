import * as ToastPrimitive from '@radix-ui/react-toast';
import { Theme } from '@radix-ui/themes';
import { Notifications } from './Notifications';
import { ToastViewport } from './ToastNotification.styles';

export type ToastProviderProps = ToastPrimitive.ToastProviderProps;
export const ToastProvider = ({ children, ...props }: ToastProviderProps) => {
  return (
    <ToastPrimitive.Provider {...props}>
      <Notifications>{children}</Notifications>
      <ToastViewport asChild>
        <Theme appearance="dark" />
      </ToastViewport>
    </ToastPrimitive.Provider>
  );
};
