import { useCallback, useState } from 'react';
import {
  AddWalletErrors,
  GetMultiAddressJobStatusQuery,
  MultiAddressImportJobStatusError,
  useAddMultiAddressMutation,
  useGetMultiAddressJobStatusLazyQuery,
} from 'src/types/graphql-types';

interface useCreateMultiAddressWalletProps {
  onCompleted: (data: GetMultiAddressJobStatusQuery) => void;
  onError: (error: string) => void;
}

interface createMultiAddressWalletProps {
  publicKeys: string;
  accountTypeSlug: string;
}

export default function useCreateMultiAddressWallet({
  onCompleted = () => {},
  onError = () => {},
}: useCreateMultiAddressWalletProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [createMultiAddressWallet] = useAddMultiAddressMutation();

  const [getMultiAddressJobStatus, { startPolling, stopPolling }] =
    useGetMultiAddressJobStatusLazyQuery({
      onCompleted: (data) => {
        if (!data.multiAddressImportJobStatus.isStatusFinal) {
          return;
        }
        stopPolling();
        setIsLoading(false);
        if (data.multiAddressImportJobStatus.success) {
          onCompleted(data);
        } else {
          if (
            data.multiAddressImportJobStatus.error ===
              MultiAddressImportJobStatusError.UnknownJobId ||
            data.multiAddressImportJobStatus.error ===
              MultiAddressImportJobStatusError.FailedToImportMultiAddressWallet
          ) {
            setError(AddWalletErrors.UnknownError);
            onError(AddWalletErrors.UnknownError);
          } else {
            setError(data.multiAddressImportJobStatus.errorMessage);
            onError(data.multiAddressImportJobStatus.errorMessage);
          }
        }
      },
    });

  const createMultiAddressWalletFunction = useCallback(
    async ({ publicKeys, accountTypeSlug }: createMultiAddressWalletProps) => {
      try {
        const response = await createMultiAddressWallet({
          variables: {
            multiAddressInput: {
              publicKeys,
              accountTypeSlug,
            },
          },
        });
        if (response.data.addMultiAddress.success) {
          getMultiAddressJobStatus({
            variables: {
              jobId: response.data.addMultiAddress.jobId,
            },
          });
          startPolling(1000);
        } else {
          setError(AddWalletErrors.UnknownError);
          onError(AddWalletErrors.UnknownError);
        }
      } catch (e) {
        setError(AddWalletErrors.UnknownError);
        onError(AddWalletErrors.UnknownError);
      }
    },
    [
      createMultiAddressWallet,
      onError,
      setError,
      startPolling,
      getMultiAddressJobStatus,
    ],
  );

  return {
    createMultiAddressWallet: createMultiAddressWalletFunction,
    loading: isLoading,
    error,
  };
}
