import { Integration } from '@/types/graphql-types';
import { Button, Card, Flex, Heading, Link, Text } from '@radix-ui/themes';
import { Download, ExternalLink, Info } from 'lucide-react';
import { IntegrationSyncMethod } from 'src/common/Integration';
import { InstructionSidebar } from '../../../Instructions/styles';
import { parseInstructions } from '../../../Instructions/util';
export interface GenericInstructionProps {
  integration: Integration;
}
export const GenericInstructions = ({
  integration,
}: GenericInstructionProps) => {
  if (integration?.instructions) {
    const { fileInstructions } = parseInstructions(
      integration.instructions,
      integration.syncMethod as IntegrationSyncMethod,
    );
    if (fileInstructions) {
      return (
        <Card>
          <InstructionSidebar>
            <Flex direction="row" gap="4" align="center">
              <Info size="16px" color="var(--accent-11)" />
              <Heading size="3">
                Steps to add your {integration.name} transactions:
              </Heading>
            </Flex>

            <Text
              as="div"
              color="gray"
              size="2"
              dangerouslySetInnerHTML={{ __html: fileInstructions }}
            />
          </InstructionSidebar>
        </Card>
      );
    }
  }
  return (
    <Card>
      <InstructionSidebar>
        <Flex direction="column" gap="4">
          <Flex direction="row" gap="4" align="center">
            <Info size="16px" color="var(--accent-11)" />
            <Heading size="3">Preparing your CoinTracker CSV</Heading>
          </Flex>
          <Text size="2" asChild>
            <ol>
              <li>Export transactions file from your exchange or wallet</li>
              <li>Re-format it using our CoinTracker CSV template and guide</li>
            </ol>
          </Text>
          <Flex direction="row" gap="3">
            <Link
              href="https://support.cointracker.io/hc/en-us/article_attachments/19757270031505"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="outline">
                CSV template
                <Download width="12px" height="12px" />
              </Button>
            </Link>
            <Link
              href="https://support.cointracker.io/hc/en-us/articles/4413071299729-Converting-transaction-history-CSVs-to-the-CoinTracker-CSV-format"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="outline">
                Guide
                <ExternalLink width="12px" height="12px" />
              </Button>
            </Link>
          </Flex>
        </Flex>
      </InstructionSidebar>
    </Card>

    // <InstructionContainer>
    //   <InstructionHeader>
    //     <InfoIcon color="#0182FF" />
    //     <H5>Preparing your CoinTracker CSV</H5>
    //   </InstructionHeader>
    //   <InstructionText $variant="secondary">
    //     <StyledList>
    //       <li>Export transactions file from your exchange or wallet</li>
    //       <li>Re-format it using our CoinTracker CSV template and guide</li>
    //     </StyledList>
    //   </InstructionText>
    //   <InstructionLinks>
    //     <a
    //       href="https://support.cointracker.io/hc/en-us/article_attachments/19757270031505"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <DownloadButton size="small" variant="secondary">
    //         CSV template
    //         <ArrowDownOpen size={12} />
    //       </DownloadButton>
    //     </a>
    //     <a
    //       href="https://support.cointracker.io/hc/en-us/articles/4413071299729-Converting-transaction-history-CSVs-to-the-CoinTracker-CSV-format"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <DownloadButton size="small" variant="secondary">
    //         CoinTracker CSV article
    //         <SmallLinkIcon />
    //       </DownloadButton>
    //     </a>
    //   </InstructionLinks>
    // </InstructionContainer>
  );
};
