import { css, styled } from 'styled-components';

export const FileBoxContainer = styled.div<{ $state: string }>`
  border-radius: var(--radius-4);
  border: 1px dashed var(--accent-8);
  background: var(--color-panel-solid);
  &:hover {
    border: 1px dashed var(--accent-10);
    cursor: pointer;
  }
  ${({ $state }) =>
    $state === 'error' &&
    css`
      border: 1px solid var(--red-9);
      color: var(--red-9);
      svg {
        path {
          fill: ${({ theme }) => theme.red.solid};
        }
      }
    `}
  ${({ $state }) =>
    $state === 'success' &&
    css`
      border: 1px solid var(--green-9);
    `}
`;

export const StyledFileInput = styled.input`
  width: 0px;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    color: var(--accent-11);
    display: inline;
    margin: 0;
    cursor: pointer;
  }

  & + label:hover {
    text-decoration: underline;
  }
`;
